import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { HashRouter , BrowserRouter } from "react-router-dom";
import { SkfUiProvider, ToastProvider } from "@skf-internal/ui-components-react";

// const container = document.getElementById("root")!;
// const root = createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  let x = event.payload;
  
  if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});


const moved = document.getElementById("moved"); 
if (moved !== undefined) {
  moved.style.display = "none"; 
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
         <SkfUiProvider>
            <ToastProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
            </ToastProvider>
          </SkfUiProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
export default msalInstance; 