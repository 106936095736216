import {
  Breadcrumbs,
  Button,
  EmptyState,
  Flexbox,
  Grid,
  GridItem,
  Link,
  Search,
  Spacer,
  Switch,
  Table,
  TableRow,
  Tag,
} from "@skf-internal/ui-components-react";
import { useEffect, useState, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../sso/ssoSlice";
import {
  selectSearchedUser,
  ISearchUser,
  searchUserByEmailAsync,
  resetSearch,
  selectStatus,
} from "./userSlice";
import { debounce  } from "lodash";

import { useNavigate } from "react-router-dom";
import PlaceholderUserSearch from "../common/PlaceholderUserSearch";

import { useAdvanceUserSearch } from "../common/useComplexUserSearch";
import { getFlag, getCountryName } from "../../Utils/getflag";
import UserDetailsModal from "./UserDetailsModal";
import { displayDate } from "../common/Common";

export interface IUsersProps {}

export default function Users(props: IUsersProps) {
  const users = useAppSelector(selectSearchedUser);
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const initial: TableRow[] = [];
  const initialStr: string = "Loading";
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const [isUserManagement, setUserManagement] = useState(true);
  const [userRows, setUserRows] = useState(initial);
  const [searchString, setSearchString] = useState("");
  const [advanceSearchString, setAdvanceSearchString] = useState("");
  const [opneModal , setOpenModal]= useState(false);
  const [modalSelectUser, setModalSelectUser]= useState("");

  const handleModalClose = ()=> {
    setOpenModal(false);
    setModalSelectUser("");
  }

  const DEBOUNCE_DELAY = 750;
  const [groupRows, setGroupRows] = useState(initial);
  const [canEditUsers, setCanEditUsers] = useState(false);
  let canRemoveUsers = false;
  const navigate = useNavigate();
  const gotoRoute = (route: any) => {
    navigate(route);
  };
  const searchStatus = useAppSelector(selectStatus);
  const [hasSearched, setHasSearched] = useState(0);

  const { searchUser = [], status } = useAdvanceUserSearch(advanceSearchString);
  let advanceSearchAccess = false;
  auth?.idTokenClaims?.roles?.find((role: string) => {
    if (
      role === "TESSERACT_SuperAdmin" ||
      role === "TESSERACT_UserManagement"
    ) {
      advanceSearchAccess = true;
    }
  });

  const [checkboxStatus, setCheckboxStatus] = useState(advanceSearchAccess);
  const placeHolderText = advanceSearchAccess
    ? "Search by first name, last name, company, country and/or e-mail"
    : "Search for e-mail";

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((query: string) => {
        let emails: string[] = [];
        emails = query.split(",");
        for (let index = 0; index < emails.length; index++) {
          emails[index] = emails[index].trim();
        }
        dispatch(
          searchUserByEmailAsync({
            emails: emails,
            ids: [],
          })
        );
      }, 0),
    []
  );

  const handleUserInputChange = (value: string) => {
    setSearchString(value);
    if (advanceSearchAccess && checkboxStatus) {
      setAdvanceSearchString(value);
    }

    debouncedChangeHandler(value);
    setHasSearched(searchString.length);
  };
  useEffect(() => {
    setCanEditUsers(isSuperAdmin);

    if (searchUser.length > 0) {
      setUserRows(
        searchUser.map((item: ISearchUser) => ({
          cells: [
            {
              children: (
                <>
                  {item.markedForDeletion == true && (
                    <img
                      src="/images/minusincircle2.svg"
                      height="16"
                      width="16"
                      title="Marked for deletion"
                      className="deleted"
                    />
                  )}
                  {item.givenName}
                </>
              ),
            },
            {
              children: item.surName,
            },
            {
              children: item.email,
            },
            {
              children: (
                <div
                  className="cellCountry"
                  title={getCountryName(item.countryIso)}
                >
                  {item.countryIso?.length > 1 && (
                    <img
                      loading="lazy"
                      height="14"
                      srcSet={`https://flagcdn.com/w40/${getFlag(
                        item.countryIso
                      )}.png`}
                      src={`https://flagcdn.com/w40/${getFlag(
                        item.countryIso
                      )}.png`}
                      alt={getCountryName(item.countryIso)}
                      title={getFlag(getCountryName(item.countryIso))}
                      className="flag"
                    />
                  )}
                  <span>{getCountryName(item.countryIso)} </span>
                </div>
              ),
            },
            {
              children: item?.companyName?.length > 0 ? item.companyName : "",
            },
            {
              children: (
                <>
                  {item.globalRoles?.length > 0
                    ? item?.globalRoles?.map((value, index) => {
                        if (index > 0) {
                          return (
                            <>
                              <br />
                              <Tag feType="outlined">{value}</Tag>
                            </>
                          );
                        } else {
                          return <Tag feType="outlined">{value + ""}</Tag>;
                        }
                      })
                    : "--"}
                </>
              ),
            },
            {
              children: <span title ={!!item.lastLogonAt ? new Date(item.lastLogonAt).toISOString() : ""}>{!!item.lastLogonAt ? displayDate(item.lastLogonAt) : ""}</span>,
            },
            {
              children: <span title ={!!item.createdAt ? new Date(item.createdAt).toISOString() : ""}>{!!item.createdAt ? displayDate(item.createdAt) : ""}</span>,
            },

            {
              children: (
                <>
                  <span className="tbUsers">
                    <span className="userlink">
                      <Link
                        as="a"
                        href="/"
                        onClick={() => {
                          setOpenModal(true )
                          setModalSelectUser(item.id);
                          // gotoRoute(`/users/${item.id}`)
                        } }
                        feIcon={{
                          feIcon: "doubleChevronRight",
                          position: "right",
                        }}
                      >
                        Details
                      </Link>
                    </span>
                  </span>
                </>
              ),
            },
          ],
        }))
      );
    } else {
      setUserRows([]);
    }
  }, [searchUser]);

  const sortableHead: TableRow[] = [
    {
      cells: [
        { children: "First name", scope: "col", sortable: {} },
        { children: "Last name", scope: "col", sortable: {} },
        { children: "E-mail", scope: "col", sortable: {} },
        {
          children: "Country",
          scope: "col",
          sortable: {
            sortBy: (props: any) => {
              return props?.title;
            },
          },
        },
        {
          children: "Company",
          scope: "col",
          sortable: { sortBy: (props: ISearchUser) => props?.companyName },
        },
        {
          children: "Role",
          scope: "col",
          sortable: {
            sortBy: (props: any) => {
              return props?.children[0]?.props?.children;
            },
          },
        },
        {
          children: "Last login",
          scope: "col",
          sortable: { sortBy: (props: any) => props?.title },
        },
        { children: "Creation date", scope: "col", sortable: { sortBy: (props: any) => props?.title } },

        { children: "More", scope: "col", width: "200px" },
      ],
    },
  ];

  return (
    <>
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={[
          {
            label: "Users",
          },
        ]}
        feSize="sm"
        feType="static"
        className="breadcrumbs"
      />
      <Spacer />
      {isSuperAdmin == false && isUserManagement == false && (
        <p>You dont have access</p>
      )}
      {(isSuperAdmin == true || isUserManagement == true) && (
        <Grid className="gridHead">
          <GridItem
            feColspan={{
              desktop: 12,
              mobile: 12,
              tablet: 12,
            }}
          >
            <div className="contentArea">
              <Grid>
                <GridItem
                  feColspan={{
                    desktop: 10,
                    mobile: 6,
                    tablet: 6,
                  }}
                >
                  <Flexbox
                    feGap="md"
                    feFlexDirection="row"
                    feJustifyContent="stretch"
                    feAlignItems="stretch"
                    style={{ alignItems: "center" }}
                  >
                    <div style={{ display: "flex", flex: 3 }}>
                      <Search
                        className="userSearch"
                        feLabel=""
                        feResetButton={{
                          "aria-label": "Rensa",
                          onClick: () => setSearchString(""),
                        }}
                        placeholder={placeHolderText}
                        onChange={(_, value) => {
                          setSearchString(value);
                          if (advanceSearchAccess && checkboxStatus) {
                            setAdvanceSearchString(value);
                          }
                        }}
                        onKeyDown={(event: any) => {
                          if (event.key === "Enter") {
                            handleUserInputChange(searchString);
                          }
                        }}
                        value={searchString}
                      />{" "}
                      {!advanceSearchAccess && (
                        <Button
                          onClick={() => handleUserInputChange(searchString)}
                          className="userSearchButton"
                        >
                          Search
                        </Button>
                      )}
                    </div>
                  </Flexbox>
                </GridItem>
                <GridItem
                  feColspan={{
                    desktop: 12,
                    mobile: 12,
                    tablet: 12,
                  }}
                >
                  <div>
                    <Spacer />
                    <GridItem
                      feColspan={{
                        desktop: 12,
                        mobile: 12,
                        tablet: 12,
                      }}
                    >
                      <div
                        style={{
                          height: "calc(100vh - 244px)",
                          overflow: "auto",
                        }}
                      >
                        {searchStatus === "loading" && <p>Searching...</p>}
                        {searchStatus === "failed" && (
                          <p>Search failed. Please logout and logon again</p>
                        )}
                        {searchStatus === "idle" &&
                          (userRows.length > 0 ? (
                            <>
                              <Table
                                feBody={userRows}
                                feHead={sortableHead}
                                feScrollable
                                feCompact
                              />
                            </>
                          ) : hasSearched > 0 ? (
                            <>
                              <EmptyState
                                feHeading={{
                                  children: "Ooops no user found 😞",
                                }}
                                feText={
                                  advanceSearchAccess
                                    ? "Try with different fields"
                                    : "Please note that you must include the full e-mail address. The search does not work for a partial e-mail."
                                }
                              >
                                Descriptive title message
                              </EmptyState>
                            </>
                          ) : (
                            <PlaceholderUserSearch
                              svgText={
                                "Search for user by include a full e-mail 📧 address."
                              }
                            />
                          ))}
                      </div>
                    </GridItem>
                  </div>
                </GridItem>
              </Grid>
            </div>
          </GridItem>
        </Grid>
      )}
      <UserDetailsModal userId={modalSelectUser} handleModalClose={handleModalClose} isOpen ={opneModal}/>
    </>
  );
}
