import {
  Dialog,
  Spacer,
  Tag,
  Text,
  TextField,
  TextAreaField,
  Flexbox,
  Button,
  useToast,
} from "@skf-internal/ui-components-react";
import { useState } from "react";
import { detachRoleFromUser } from "../users/userAPI";
import { detachAppRoleFromUser } from "./applicationDetailsAPI";
import { IRoleItem, IUserItem } from "./applicationDetailsSlice";

export interface IApplicationRoleTagProps {
  applicationId: string, 
  role: IRoleItem;
  auth: any;
  user: IUserItem;
  callback: any;
  isSuperAdmin: boolean;
}

export default function ApplicationRoleTag(props: IApplicationRoleTagProps) {
  const { applicationId, role, auth, user, callback, isSuperAdmin } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [ticket, setTicket] = useState("");
  const [declineHint, setDecineHint] = useState("");
  const [declineError, setDeclineError] = useState<"error" | undefined>(
    undefined
  );
  const errorHint = "SuperAdmins must referece to ServiceNow ticket";
  const { addToast } = useToast();
  const cremoveRoleFromUser = async () => {

    // let response = await callback();
     // return 0;
     let reasonText = reason;
     if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
       reasonText += " Refering SNOW Ticket: " + ticket;
     }
   
     try {
       let response =  await detachRoleFromUser(user.id, role.roleId, {
          adminId: auth?.idTokenClaims?.sub,
          reason: reasonText,
        })
       
     } catch (error) {
       console.log(error);
     } finally {
       callback();
       setIsOpen(false);
     }
   
   };
 
  const removeRolefromUser = async () => {
     try {
  
        const response = await detachAppRoleFromUser(
          user.id,
            applicationId + "",
          role.roleId + "",
          { adminId: auth?.idTokenClaims?.sub, reason: reason }
        );
        if (response.status === 200) {
          addToast({
            children: "Role removed successfully",
            feSeverity: "success",
          });
        } else {
          addToast({
            children:
              "Something went wrong. Please contact system administration",
            feSeverity: "error",
          });
        }
  
        //Here I need to implement the toast message.
      } catch (error) {
        console.log(error);
        addToast({
          children:
            "Something went wrong. Please contact system administration",
          feSeverity: "error",
        });
      } finally {
        callback();
        setIsOpen(false);
      }
    
  };
  if (true) { //(props.role.isEditable || props.isSuperAdmin) {
    return (
      <> <Tag
      feType="outlined"
      feIcon="edit"
      feClosable={{
        title: "Remove role from user",
        onClick: () => setIsOpen(true),
      }}
    >
      {role.roleName}
    </Tag>
          
       
       
        <Dialog
          feInterruptive
          feTitle="Remove role from user"
          open={isOpen}
          cssWidth={"35rem"}
          cssHeight={"23.3rem"}
        >
          <Text>
            You are currently removing the role <b>{role.roleName}</b> from user{" "}
            <b>{user.userName}</b> which will restrict access to certain
            features.
          </Text>
          <Spacer feSpacing="md" />
          <TextAreaField
            style={{
              height: "60px",
              width: "470px",
              minWidth: "470px",
              maxWidth: "470px",
            }}
            feLabel="Reason (for internal use)"
            onChange={(_, value) => {
              setReason(value);
            }}
          />
          <Spacer feSpacing="md"></Spacer>
          {auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin") &&
            false && (
              <>
                <TextField
                  feLabel="Reference"
                  feRequiredText={errorHint}
                  feHint={declineHint}
                  feSeverity={declineError}
                  onChange={(_, value) => {
                    setTicket(value);
                  }}
                />
                <Spacer feSpacing="md"></Spacer>
              </>
            )}
          <Spacer feSpacing="xs"></Spacer>
          <Flexbox
            feGap="md"
            feJustifyContent="flex-end"
            feAlignItems="flex-end"
          >
            <Button
              feType="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={() => removeRolefromUser()}>Confirm</Button>
          </Flexbox>
        </Dialog>
        <Spacer feSpacing="xxs" feHorizontal className="TagSpacer" />
      </>
    );
  } else {
    return (
      <>
        <Tag feType="filled">{props.role.roleName}</Tag>
        <Spacer feSpacing="xxs" feHorizontal className="TagSpacer" />
      </>
    );
  }
}


