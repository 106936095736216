import {
  Button,
  Dialog,
  Flexbox,
  Text,
  Spacer,
} from "@skf-internal/ui-components-react";
import { useState, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { attachUserToCollection } from "./globalRolesAdminAPI";
import { IGlobalRoleCollection } from "./globalRolesAdminSlice";
import { ISearchUser, resetSearch } from "../group-users/groupUserSlice";

import UserSearchBox from "../common/userSearchBox";

export interface IAddAdminProps {
  collection: IGlobalRoleCollection | undefined;
  reloadCallback: any;
}

export default function AddAdmin(props: IAddAdminProps) {
  const { collection, reloadCallback } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useAppDispatch();
  // const roles = useAppSelector(selectGlobalRoles);
  const [displaySearchedUser, setDisplaySearchedUser] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [selectedRole, setSelectedRole] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [query, setQuery] = useState("");
  const [searchUser, setSearchUser] = useState<ISearchUser[]>([]);

  const defaultProps = {
    options: searchUser || [],
    getOptionLabel: (option: ISearchUser) => {
      return option.email;
    },
  };
  const create = () => {
    if (searchUser != null && searchUser.length > 0) {
      attachUserToCollection({
        userProfileId: searchUser[0].id,
        roleCollectionId: collection != null ? collection.roleCollectionId : -1,
      })
        .then((res) => {
          setInfoMessage("Successfully created!");
          dispatch(resetSearch([]));
          setEmail("");
          setDisplaySearchedUser(false);
          setChecked(false);
          setIsOpen(false);
          setInfoMessage("");
          reloadCallback();
        })
        .catch((error) => {
          if (error.response) {
            setInfoMessage(error.response.data);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    } else {
      setInfoMessage("select user first");
    }
  };

  useEffect(() => {
    return () => {
      setQuery("");
    };
  }, []);

  return (
    <div>
      <>
        <Button
          className="addAdmin"
          onClick={() => setIsOpen(true)}
          disabled={collection == null}
        >
          Add user
        </Button>
      </>
      <Dialog
        {...props}
        feInterruptive
        feTitle="Add user"
        open={isOpen}
        cssWidth={"40rem"}
        cssHeight={"25rem"}
      >
        <div className="globalroleadminadd">
          <p>
            You are adding a new user to the global role admin group "
            {collection?.roleCollectionName}".
          </p>
          {infoMessage !== "" && (
            <>
              <h6>{infoMessage}</h6>
              <Spacer />
            </>
          )}
          <Text>Search User</Text>
          <UserSearchBox setSelectedUser={setSearchUser} />
          <Spacer />
          <Flexbox
            feGap="md"
            feJustifyContent="flex-end"
            className="modalButtons"
          >
            <Button feType="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => create()}
              disabled={
                searchUser == null ||
                searchUser.length != 1 ||
                status === "loading"
              }
            >
              Confirm
            </Button>
          </Flexbox>
        </div>
      </Dialog>
    </div>
  );
}
