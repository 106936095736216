import {
  Button,
  DatePicker,
  Divider,
  Flexbox,
  Icon,
  IconButton,
  Link,
  Select,
  SelectItem,
  Spacer,
  Switch,
  Text,
  TextField,
} from "@skf-internal/ui-components-react";
import { SetStateAction, useEffect, useState } from "react";
import DateFilter, { IDateFilterValue } from "../common/DateFilter";
import { ICountryRecord, IDomainRecord, IRolesUserFilter } from "../common/RoleUsersFilterTags";

export interface IGlobalRolesUserFilterProps {
  filterData: IRolesUserFilter;
  updateOnApply: any;
  setIncludeInternal: any;
}

export default function GlobalRolesUserFilter(
  props: IGlobalRolesUserFilterProps
) {
  const { filterData, updateOnApply, setIncludeInternal } = props;
  const [filterActive, setFilterActive] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [includeInternalUserTemp, setIncludeInternalUserTemp] = useState(
    filterData?.includeInternalUser
  );
  const [filteredDomainsTemp, setFilteredDomainsTemp] = useState(
    filterData?.filteredDomains
  );
  const [filteredCountriesTemp, setFilteredCountriesTemp] = useState(
    filterData?.filteredCountries
  );
  const emptyLastLoginFilterValue: IDateFilterValue = {
    type: "",
    fromDate: "",
    toDate: "",
  };
  const emptyCreationDateFilterValue: IDateFilterValue = {
    type: "",
    fromDate: "",
    toDate: "",
  };
  const [lastLoginFilter, setLastLoginFilter] = useState(
    filterData.lastLoginDateFilter
  );
  const [createdDateFilter, setCreatedDateFilter] = useState(
    filterData.creatonDateFilter
  );

  const applyFilter = () => {
    setIncludeInternal(includeInternalUserTemp);
    setFilterActive(filterData.includeInternalUser ? "filter" : "");
    filterData.lastLoginDateFilter = lastLoginFilter;
    filterData.creatonDateFilter = createdDateFilter;
    filterData.filteredDomains = filteredDomainsTemp;
    filterData.filteredCountries = filteredCountriesTemp;
    filterData.includeInternalUser = includeInternalUserTemp; 

    updateOnApply();

    checkIfFilterEnabled();
    setIsOpen(false);
  };

  const domainsRows = () => {
    console.log("Loading domains " +filterData?.availableDomains.length);
    return filterData?.availableDomains?.map((item: IDomainRecord) => {
      return {
        label: item?.domain + " (" + item.count + ")",
        value: item?.domain,
      } as SelectItem<string>;
    });
  };
  const countryRows = () => {
    console.log("Loading countries " +filterData?.availableCountries.length);
    return filterData?.availableCountries?.map((item: ICountryRecord) => {
      let label = item?.countryName??"Not set"; 
      return {
        label: label + " (" + item.count + ")",
        value: item?.countryIso??"",
      } as SelectItem<string>;
    });
  };
  useEffect(() => {
    checkIfFilterEnabled();
  }, [filterData.includeInternalUser, filterData]);
  useEffect(() => {
    setIncludeInternalUserTemp(filterData.includeInternalUser);
  }, [isOpen]);

  const checkIfFilterEnabled = () => {
    setFilterActive(
      filterData.includeInternalUser ||
        (filterData.creatonDateFilter?.type != null &&
          filterData.creatonDateFilter?.type != "") ||
        (filterData.lastLoginDateFilter?.type != null &&
          filterData.lastLoginDateFilter?.type != "") ||
        (filterData.filteredDomains != null &&
          filterData.filteredDomains.length > 0) ||
          (filterData.filteredCountries != null &&
            filterData.filteredCountries.length > 0)
        ? "filter"
        : ""
    );
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          padding: "0.6rem 0.8rem 0.4rem 0.8rem",
          textAlign: "center",
          marginRight: "2px",
          marginTop: "8px",
          height: "40px",
          border: "1px solid green"
        }}
        onClick={() => {
          console.log('filter icon clicked')
          setIsOpen(true) }}
        className={filterActive}
      >
        <Icon feIcon="filter" feSize="lg" />
      </div>
      {isOpen && (
        <div id="roleUserFilter">
          <div style={{ height: "calc(100% - 60px)" }}>
            {" "}
            <div className="close" onClick={() => setIsOpen(false)}>
              <Icon feIcon="close" />
            </div>
            {
              <Link
                as="a"
                href="/"
                className="clearfilter"
                onClick={() => {
                  setIncludeInternalUserTemp(false);
                  setLastLoginFilter(emptyLastLoginFilterValue);
                  setCreatedDateFilter(emptyCreationDateFilterValue);
                  setFilteredDomainsTemp([]);
                  setFilteredCountriesTemp([]);
                }}
              >
                Clear filter
              </Link>
            }
            <Spacer feSpacing="xs"/>
            <Divider />
            <div className="title">Filter by</div>
            <div>
              <Switch
                feLabel="Show @skf.com users"
                onChange={(value: any) =>
                  setIncludeInternalUserTemp(!includeInternalUserTemp)
                }
                checked={includeInternalUserTemp}
              />
            </div>
            <Spacer feSpacing="lg" />
            <DateFilter
              title="Last login date"
              useNever={true}
              filterValue={lastLoginFilter}
            />
            <Spacer />
            <DateFilter
              title="Creation date"
              useNever={true}
              filterValue={createdDateFilter}
            />
            <Spacer />
            <div className="dateFilterTitle">Domain</div>
            <Select
              feHideSelectionTags
              feItems={domainsRows()}
              feSize="sm"
              feLabel=""
              feRows={7}
              disabled={!domainsRows()?.length}
            
              feMultiSelectionText="$1 domains selected"
              fePlaceholder="Select domains you want to filter by"
              multiple
              onChange={(value: any) => setFilteredDomainsTemp(value)}
              value={filteredDomainsTemp}
            />{" "}
            {filteredDomainsTemp != null && filteredDomainsTemp.length > 0 && (
              <IconButton
                aria-label="close"
                title="Clear filter"
                as="button"
                feIcon="closeSmall"
                className="clearDateFilter"
                onClick={() => setFilteredDomainsTemp([])}
              />
            )}
             <Spacer feSpacing="lg"/>
            
            <div className="dateFilterTitle">Country</div>
              <Select
              feHideSelectionTags
              feItems={countryRows()}
              feSize="sm"
              feLabel=""
              disabled={!countryRows()?.length}
              feRows={7}
              feMultiSelectionText="$1 countries selected"
              fePlaceholder="Select contries you want to filter by"
              multiple
              onChange={(value: any) => setFilteredCountriesTemp(value)}
              value={filteredCountriesTemp}
            />{" "}
            {filteredCountriesTemp != null && filteredCountriesTemp.length > 0 && (
              <IconButton
                aria-label="close"
                title="Clear filter"
                as="button"
                feIcon="closeSmall"
                className="clearDateFilter"
                onClick={() => setFilteredCountriesTemp([])}
              />
            )}
          </div>

          <Button
            style={{ width: "100%", maxWidth: "100%" }}
            onClick={applyFilter}
          >
            Apply
          </Button>
        </div>
      )}
    </>
  );
}
