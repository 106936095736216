import {
  Breadcrumbs,
  Divider,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spacer,
  Tag,
  Tabs,
  Flexbox,
  Button,
  Loader,
  useToast,
} from "@skf-internal/ui-components-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../sso/ssoSlice";
import UserRoles from "./UserRoles";
import {
  selectFetchedUser,
  ISearchUser,
  searchUserByIDAsync,
  selectStatus,
} from "./userSlice";
import UserTickets from "./UserTickets";
import { displayDate, textToColor } from "../common/Common";
import { changeEmail } from "./userAPI";
import UserReactivation from "./UserReactivation";
import UserTOTP from "./UserTOTP";
import { getCountryName, getFlag } from "../../Utils/getflag";

export interface RouteParams {
  userId: string | "";
}
export interface IUserDetailsProps {
  userId: string;
}

export default function UserDetails({ userId = "" }) {
  const auth = useAppSelector(selectAuth);
  const [activeTab, setActiveTab] = useState(0);
  const users = useAppSelector(selectFetchedUser);

  const dispatch = useAppDispatch();
  const ids: string[] = [];
  const emptyUser = {} as ISearchUser;
  const [user, setUser] = useState(emptyUser);
  const [marketingConsent, setMarketingConsent] = useState("");
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const [isUserManagement, setUserManagement] = useState(true);
  const [isUserMarkedForDeleted, setUserMarkedForDeleted] = useState(false);
  const [isEmailEditable, setIsEmailEditable] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [saveBtnLoad, setSaveBtnLoad] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const loadingStatus = useAppSelector(selectStatus);
  const { toasts, addToast, removeToast } = useToast();

  ids.push(userId as string);

  const navigate = useNavigate();

  const onTabClick = (_: any, index: React.SetStateAction<number>) => {
    setActiveTab(index);
  };
  useEffect(() => {
    if (auth?.idTokenClaims) {
      dispatch(searchUserByIDAsync({ emails: [], ids: ids }));
      if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
        setSuperAdmin(true);
      }
      if (auth?.idTokenClaims?.roles?.includes("TESSERACT_UserManagement")) {
        setUserManagement(true);
      }
    }
  }, []);

  useEffect(() => {
    if (users != null && users.length > 0) {
      setUser(users[0]);
      setUserMarkedForDeleted(users[0].markedForDeletion);
      if (users[0].marketingConsent != null) {
        setMarketingConsent(users[0].marketingConsent.replaceAll(",", " "));
      }
    }
  }, [users]);

  const handleEmailIdChange = async () => {
    if (isSuperAdmin && isEmailEditable) {
      if (user.email === newEmail) {
        // alert('please enter a different email')
        setSuccessMessage("Please enter a different email");
        addToast({
          children: "Please enter a different email",
          feSeverity: "info",
        });
      } else {
        // change email functionality

        try {
          setSaveBtnLoad(true);
          const data = await changeEmail(user.id, newEmail);

          if (data.status === 204) {
            addToast({
              children: "Email Changed successfully",
              feSeverity: "success",
            });
          }
        } catch (error) {
          console.log("something went wront", error);
          addToast({
            children:
              "Something went wrong, please contact system administrators",
            feSeverity: "error",
          });
        } finally {
          setSaveBtnLoad(false);
          setIsEmailEditable(false);
          dispatch(
            searchUserByIDAsync({ emails: [user.email], ids: [user.id] })
          );
        }
      }
    }
  };

  const reloadUser = () => {
    dispatch(searchUserByIDAsync({ emails: [user.email], ids: [user.id] }));
  };

  const isEmailvalid = () => {
    if (user.email === newEmail) {
      setOpenToast(true);
      setSuccessMessage("Please enter a different email");
    }
  };

  function splitUserType(usertypes: string) {
    return usertypes?.split(",").map((value) => {
      let val = value;
      if (value === "AuthDist") {
        val = "Authorised Distributor";
      } else if (value === "Lubdist") {
        val = "Lubrication Systems Distributor";
      } else if (value === "Certified") {
        val = "Certified Partner";
      } else {
        return;
      }
      return (
        <Tag feColor={textToColor(val)} feType="outlined">
          {val}
        </Tag>
      );
    });
  }

  function gridRowWithFlag(title: string, value: string) {
    const showEmail = title === "Email";
    return (
      <>
        <GridItem
          feColspan={{
            desktop: 4,
            mobile: 4,
            tablet: 4,
          }}
        >
          <div>{title}</div>
        </GridItem>
        <GridItem
          feColspan={{
            desktop: 8,
            mobile: 8,
            tablet: 8,
          }}
        >
          <div
            style={{
              overflow: "hidden",
              // marginRight: "28px",
              textOverflow: "ellipsis",
              // display:"flex"
            }}
            title={value}
          >
            <div className="userdetailCountry" title={getCountryName(value)}>
              {value != null && (
                <img
                  loading="lazy"
                  height="14"
                  srcSet={`https://flagcdn.com/w40/${getFlag(value)}.png`}
                  src={`https://flagcdn.com/w40/${getFlag(value)}.png`}
                  alt={getCountryName(value)}
                  title={getCountryName(value)}
                  className="flag"
                />
              )}
              <span>{getCountryName(value)}</span>
            </div>
          </div>
        </GridItem>{" "}
      </>
    );
  }

  function gridRow(title: string, value: string) {
    const showEmail = title === "Email";
    return (
      <>
        <GridItem
          feColspan={{
            desktop: 4,
            mobile: 4,
            tablet: 4,
          }}
        >
          <div>{title}</div>
        </GridItem>
        <GridItem
          feColspan={{
            desktop: 8,
            mobile: 8,
            tablet: 8,
          }}
        >
          <div
            style={{
              overflow: "hidden",
              // marginRight: "28px",
              textOverflow: "ellipsis",
              // display:"flex"
            }}
            title={value}
          >
            {isEmailEditable && showEmail ? (
              <input
                onBlur={isEmailvalid}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                type={"email"}
              />
            ) : (
              value
            )}
            {/* {value} */}
            {showEmail && isSuperAdmin && (
              <div
                onClick={() => {
                  setNewEmail(user.email);
                  setIsEmailEditable((prevState) => !prevState);
                }}
                style={{ cursor: "pointer", display: "inline-block" }}
              >
                {isEmailEditable ? (
                  <Icon
                    feIcon="close"
                    style={{
                      display: "inline-block",
                      height: "16px",
                      width: "16px",
                      marginLeft: "1rem",
                      cursor: "pointer",
                      color: "#0F58D6",
                    }}
                  />
                ) : (
                  <Icon
                    feIcon="edit"
                    style={{
                      display: "inline-block",
                      height: "16px",
                      width: "16x",
                      marginLeft: "1rem",
                      cursor: "pointer",
                      color: "#0F58D6",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </GridItem>{" "}
      </>
    );
  }
  function gridRowUserType(title: string, value: string) {
    return (
      <>
        <GridItem
          feColspan={{
            desktop: 4,
            mobile: 4,
            tablet: 4,
          }}
        >
          <div>{title}</div>
        </GridItem>
        <GridItem
          feColspan={{
            desktop: 8,
            mobile: 8,
            tablet: 8,
          }}
        >
          <div
            style={{
              overflow: "hidden",
              marginRight: "28px",
              textOverflow: "ellipsis",
            }}
            title={value}
          >
            {splitUserType(value)}
          </div>
        </GridItem>{" "}
      </>
    );
  }

  return (
    <>
      {isSuperAdmin == false && isUserManagement == false && (
        <p>You dont have access</p>
      )}
      {(isSuperAdmin == true || isUserManagement == true) && (
        <Flexbox className="grid" feGap="lg">
          <div
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              overflowY: "auto",
              overflowX: "hidden",
              padding: "1rem",
              textAlign: "left",
              fontSize: "14px",
              minWidth: "400px",
              width: "35%",
              marginBottom: "8px",
            }}
          >
            {loadingStatus == "loading" && (
              <div className="userDetailLoader">
                {" "}
                <Loader />{" "}
              </div>
            )}
            {loadingStatus != "loading" && (
              <>
                <Heading
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "18px",
                  }}
                  as="h4"
                >
                  <section>
                    {user.givenName} {user.surName}{" "}
                    {isUserMarkedForDeleted && (
                      <img
                        src="/images/minusincircle2.svg"
                        className="deleted"
                        title="Marked for deletion"
                      />
                    )}
                  </section>
                  {isUserMarkedForDeleted && isSuperAdmin && (
                    <UserReactivation user={user} callback={reloadUser} />
                  )}
                </Heading>

                <Spacer feSpacing="xxs" />
                <span>{user.userName}</span>
                <Divider />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Heading
                    as="h4"
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    Contact information
                  </Heading>
                  <section>
                    {isSuperAdmin && isEmailEditable && (
                      <Button
                        disabled={saveBtnLoad}
                        onClick={handleEmailIdChange}
                        feSize="sm"
                        feType="secondary"
                      >
                        Save
                      </Button>
                    )}
                  </section>
                </div>

                <span>
                  <Grid
                    style={{
                      gap: "10px 32px",
                      position: "relative",
                      top: "8px",
                    }}
                  >
                    {gridRow("First name", user.givenName)}
                    {gridRow("Last name", user.surName)}
                    {gridRow("Email", user.email)}

                    {gridRow("Phone", user.phoneNumber)}
                    {gridRow("Company", user.companyName)}
                    {gridRow("Language", user.preferredLanguage)}
                  </Grid>
                </span>
                <Spacer feSpacing="sm" />
                <Divider />
                <Heading
                  as="h4"
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Address
                </Heading>
                <span>
                  <Grid
                    style={{
                      gap: "10px 32px",
                      position: "relative",
                      top: "8px",
                    }}
                  >
                    {gridRow("Address", user.streetAddress)}
                    {gridRow("City", user.city)}
                    {gridRowWithFlag("Location", user.countryIso)}
                    {gridRow("Zip code", user.postalCode)}
                  </Grid>
                </span>
                <Spacer feSpacing="sm" />
                <Divider />
                <Heading
                  as="h4"
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Marketing
                </Heading>
                <span>
                  <Grid
                    style={{
                      gap: "10px 32px",
                      position: "relative",
                      top: "8px",
                    }}
                  >
                    {gridRow("Industry", user.industry)}
                    {gridRowUserType("SKF Distributor type", user.userSubType)}
                  </Grid>
                </span>
                <Spacer feSpacing="sm" />
                <Divider />
                <Heading
                  as="h4"
                  style={{
                    fontSize: "16px",
                  }}
                >
                  Technical
                </Heading>
                <span>
                  <Grid
                    style={{
                      gap: "10px 32px",
                      position: "relative",
                      top: "8px",
                    }}
                  >
                    {gridRow("Id", user.id)}
                    {gridRow("Created", displayDate(user.createdAt))}
                    {gridRow("Last updated", displayDate(user.updatedAt))}
                    {gridRow("Last login", displayDate(user.lastLogonAt))}
                    <UserTOTP userId={user.id} userName={user.userName} />
                    <>
                      {user.markedForDeletion &&
                        gridRow(
                          "Marked for deletion",
                          displayDate(user.deletedAt)
                        )}
                    </>
                  </Grid>
                </span>
                <Spacer feSpacing="xxs" />
              </>
            )}
          </div>
          <div
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              padding: "1rem",
              textAlign: "left",
              height: "calc(100% - 8px)",
              width: "calc(100% - 435px)",
            }}
          >
            <Tabs
              feSelected={activeTab}
              onClick={onTabClick}
              className="userdetail_tab"
              feItems={[
                {
                  children: (
                    <div>
                      {activeTab === 0 && <UserRoles user={user}></UserRoles>}
                    </div>
                  ),
                  id: "t05rbms0cxcktmh3",
                  label: "User assigned roles",
                },
                {
                  children: (
                    <div>
                      {" "}
                      {activeTab === 1 && (
                        <UserTickets userId={user.id}></UserTickets>
                      )}
                    </div>
                  ),
                  id: "UserTickets",
                  label: "Requests",
                },
              ]}
              feType="expanded"
              feStretch={false}
            />
          </div>
        </Flexbox>
      )}
    </>
  );
}
