import axios from "axios";
import { fetchAccessToken } from "../common/Common";
import { IFetchCountries, IFetchDomains, IUserBasedOnRoles } from "./applicationDetailsSlice";

export async function fetchRoles(appId: string, userId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admins/${userId}/applications/${appId}/roles`
  );
}

export async function fetchGroups(appId: string, userId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admins/${userId}/applications/${appId}/groups`
  );
}

export async function fetcApplicationRoleUsersPost(
  fetchUser: IUserBasedOnRoles
) {
  let page = fetchUser.queryPage;
  let adminId = fetchUser.adminId;
  let roleId = fetchUser.roleId;
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  if (page < 1) {
    page = 1;
  }

  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/admins/${fetchUser.adminId}/applications/${fetchUser.applicationId}/role/${roleId}/users`,
    fetchUser
  );
}

export async function fetchAdmins(appId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/applications/${appId}/admins`
  );
}
export async function createRole(
  appId: string,
  userId: string,
  data: { roleName: string; roleDescription?: string }
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admins/${userId}/applications/${appId}/roles`,
    data
  );
}

export async function createGroup(
  appId: string,
  userId: string,
  data: { groupName: string; groupDescription?: string }
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admins/${userId}/applications/${appId}/groups`,
    data
  );
}

export async function createApplicationRoleTicket(payload: {
  userProfileId: string;
  applicationId: string;
  roleName: string;
  adminId: string;
  reason: string;
}) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/application/roles/tickets`,
    payload
  );
}

export async function detachAppRoleFromUser(
  userId: string,
  applicationId: string,
  roleId: string,
  payload: { adminId: string; reason: string }
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/application/${applicationId}/users/${userId}/roles/${roleId}`,
    payload
  );
}

export async function addAdminToApplication(
  applicationId: string,
  userProfileId: string
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/upr/application-admin`,
    { userId: userProfileId, applicationId: applicationId }
  );
}
export async function fetchApplicationRoleUserDomains(
  fetchDomain: IFetchDomains
) {
  let page = fetchDomain.queryPage;
  let adminId = fetchDomain.adminId;
  let roleId = fetchDomain.roleId;
  let applicationId = fetchDomain.applicationId;

  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  if (page < 1) {
    page = 1;
  }

  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/admin/${adminId}/application/${applicationId}/role/${roleId}/domains?doFilter=false`,
    fetchDomain
  );
}
  export async function fetchApplicationRoleUserCountries(
    fetchCountries: IFetchCountries
  ) {
    let page = fetchCountries.queryPage;
    let adminId = fetchCountries.adminId;
    let roleId = fetchCountries.roleId;
    let applicationId = fetchCountries.applicationId;
  
    let accessToken = await fetchAccessToken();
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    if (page < 1) {
      page = 1;
    }
  
    return axios.post(
      `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/admin/${adminId}/application/${applicationId}/role/${roleId}/countries?doFilter=false`,
      fetchCountries
    );
  }

