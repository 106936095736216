import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getOathMethods } from "./graphAPI";

export interface ITokenResponse {
  access_token: String;
}
export interface IOathMethod {
  id: String;
  secretKey: String;
}

export interface IOathMethodRequest {
  userId: string;
}
export interface IGraphState {
  oathMethod: any[] | undefined;
  oathMethodStatus: "idle" | "loading" | "failed";
}

const initialState: IGraphState = {
  oathMethod: undefined,
  oathMethodStatus: "idle",
};

export const getOathMethodsAsync = createAsyncThunk(
  "getOathMethods",
  async (request: IOathMethodRequest) => {
    //let tokenResponse: any = await fetchToken(request.domain);
    let userId = request.userId;
    
    const response: any = await getOathMethods(userId);
      return response;
  
    
  }
);

export const graphSlice = createSlice({
  name: "graph",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSearch: (state, action: PayloadAction<any>) => {
      state.oathMethod = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOathMethodsAsync.pending, (state) => {
        state.oathMethodStatus = "loading";
      })
      .addCase(getOathMethodsAsync.fulfilled, (state, action) => {
        state.oathMethodStatus = "idle";
        state.oathMethod = action.payload.data;
      })
      .addCase(getOathMethodsAsync.rejected, (state) => {
        state.oathMethodStatus = "failed";
      });
  },
});

export const { resetSearch } = graphSlice.actions;

export const oathMethodStatus = (state: RootState) =>
  state.graphs.oathMethodStatus;
export const oathMethod = (state: RootState) => state.graphs.oathMethod;

export default graphSlice.reducer;
