import axios from "axios";
import { fetchAccessToken } from "../common/Common";
import { IFetchCountries, IFetchDomains, IFetchUsers } from "./globalRoleUsersSlice";

//axios.defaults.headers.common["x-functions-key"] =
//  process.env.REACT_APP_AUTH_TOKEN || "";

export async function fetchGlobalRoleUsers(
  roleId: string,
  adminId: string,
  page: number,
  itemsPerPage: number,
  sortingField: string,
  sortingOrder: string,
  quickFilter: string,
  excludeEmailDomain: string
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  if (page < 1) {
    page = 1;
  }
  var sort = "";
  if (sortingOrder != "" && sortingField != "") {
    if (sortingOrder.toLowerCase() === "ascending") {
      sortingOrder = "asc";
    } else {
      sortingOrder = "desc";
    }
    sort = `&sortField=${sortingField}&order=${sortingOrder}`;
  }
  var filter = "";
  if (quickFilter != null && quickFilter != "") {
    filter = `&quickFilter=${quickFilter}`;
  }
  var exclude = "";
  if (excludeEmailDomain != null && excludeEmailDomain != "") {
    exclude = `&excludeEmailDomain=${excludeEmailDomain}`;
  }
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/admin/${adminId}/globalroles/${roleId}?page=${page}&itemsPerPage=${itemsPerPage}${sort}${filter}${exclude}`
  );
}
export async function fetchGlobalRoleUsersPost(fetchUser: IFetchUsers) {
  let page = fetchUser.queryPage;
  let adminId = fetchUser.adminId;
  let roleId = fetchUser.roleId;
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  if (page < 1) {
    page = 1;
  }

  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/admin/${adminId}/globalroles/${roleId}`,
    fetchUser
  );
}
export async function fetchGlobalRoleUserDomains(fetchDomain: IFetchDomains) {
  let page = fetchDomain.queryPage;
  let adminId = fetchDomain.adminId;
  let roleId = fetchDomain.roleId;

  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  if (page < 1) {
    page = 1;
  }

  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/admin/${adminId}/globalroles/${roleId}/domains?doFilter=false`,
    fetchDomain
  );
}
export async function fetchGlobalRoleUserCountries(fetchCountries: IFetchCountries) {
  let page = fetchCountries.queryPage;
  let adminId = fetchCountries.adminId;
  let roleId = fetchCountries.roleId;

  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  if (page < 1) {
    page = 1;
  }

  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/admin/${adminId}/globalroles/${roleId}/countries?doFilter=false`,
    fetchCountries
  );
}
