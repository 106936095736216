import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../sso/ssoSlice";

import {
  Button,
  Card,
  Flexbox,
  GridItem,
  Heading,
  Image,
  Spacer,
  Loader,
  EmptyState,
  Divider,
  Breadcrumbs,
  Grid,
} from "@skf-internal/ui-components-react";
import { applicationsAsync, selectApps, appsStatus } from "./applicationsSlice";
import { Outlet } from "react-router";

export interface IApplicationsProps {}
export interface IApplication {
  applicationId: string;
  applicationName: string;
}

export function Applications(props: IApplicationsProps) {
  const apps = useAppSelector(selectApps);
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const status = useAppSelector(appsStatus);

  useEffect(() => {
    if (auth?.idTokenClaims) {
      dispatch(applicationsAsync({ userId: auth.idTokenClaims.sub }));
    }
  }, []);

  const getBgImg = () => {
    const items = Array.from({ length: 10 }, (_, i) => i + 1);
    return items[Math.floor(Math.random() * items.length)];
  };

  return (
    <span className="app-overview-body ">
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={[
          {
            label: "Application",
            href: "/app",
            onClick: (e) => {
              e.stopPropagation();
            },
          },
        ]}
        feSize="sm"
        feType="interactive"
        className="breadcrumbs"
      />

      <Spacer></Spacer>
      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 6,
          }}
        >
          <div className="card-container-padding">
            <Heading as="h4" feStyledAs="h4">
              My apps{" "}
            </Heading>
            <Spacer />

            {status === "loading" ? (
              <div className="loader-container-style">
                {" "}
                <Loader />
              </div>
            ) : apps.length > 0 ? (
              <div style={{overflow: "auto"  }}>
                <Flexbox
                  feJustifyContent={"flex-start"}
                  feFlexWrap="wrap"
                  feFlexDirection="row"
                  feGap="lg"
                  className="AppCards"
                >
                  {apps.map((item: IApplication, index) => {
                    return (
                      <div key={item.applicationId} className="card-animation">
                        <Card
                          className="application-card"
                          key={item.applicationId}
                        >
                          <Image
                            alt={item.applicationName}
                            src={
                              process.env.PUBLIC_URL +
                              "/images/" +
                              getBgImg() +
                              ".svg"
                            }
                          />
                          <Heading as="h4">{item.applicationName}</Heading>
                          <Divider />
                          <Link to={`/app/${item.applicationId}`}>
                            <Button feSize="sm">Open</Button>
                          </Link>
                        </Card>
                        {index < apps.length && <Spacer />}
                      </div>
                    );
                  })}
                </Flexbox>
              </div>
            ) : (
              <EmptyState
                feHeading={{
                  children: "No Application found",
                }}
                feText="You are not administrator of any application."
              >
                Descriptive title message
              </EmptyState>
            )}
          </div>
        </GridItem>
      </Grid>
      <Outlet />
    </span>
  );
}
