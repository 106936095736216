import {
  Flexbox,
  Grid,
  GridItem,
  Loader,
  Select,
  Table,
  TableRow,
  Tag,
} from "@skf-internal/ui-components-react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../sso/ssoSlice";
import {
  IFetchRolesTickets,
  listApplicationTicketsByUserAsync,
  listGlobalRolesTicketsByUserAsync,
  selectApplicationRolesTicket,
  selectApplicationRolesTicketStatus,
  selectGlobalRolesTicket,
  selectGlobalRolesTicketStatus,
} from "./userSlice";
import { displayDate, textToColor } from "../common/Common";
import ReasonPopup from "../common/ReasonPopup";
import UserTicketApproverGroupLink from "./UserTicketApproverGroupLink";
import { IApprovalReqItem } from "../approvals/approvalsSlice";

export interface IUserTicketsProps {
  userId: string;
}

export default function UserTickets(props: IUserTicketsProps) {
  const auth = useAppSelector(selectAuth);
  const [activeTab, setActiveTab] = useState(1);
  const globalRolesTickets = useAppSelector(selectGlobalRolesTicket);
  const globalRolesTicketsStatus = useAppSelector(
    selectGlobalRolesTicketStatus
  );
  const applicationRolesTickets = useAppSelector(selectApplicationRolesTicket);
  const applicationRolesTicketsStatus = useAppSelector(
    selectApplicationRolesTicketStatus
  );
  const dispatch = useAppDispatch();
  const initial: TableRow[] = [];
  const [rolesRows, setRolesRows] = useState(initial);
  const [page, setPage] = useState(1);
  let reloadToggler = false;
  let tickets: [] | IApprovalReqItem[] = [];
  const [show, setShow] = useState("all");

  useEffect(() => {
    if (page == 1) {
      loadTickets();
    }
    setPage(1);
  }, [props.userId]);

  useEffect(() => {
    console.log("Creating rows");
    if (
      globalRolesTicketsStatus == "idle" &&
      applicationRolesTicketsStatus == "idle"
    ) {
      tickets = (globalRolesTickets as any[]).concat(applicationRolesTickets);
      console.log(
        "Creating rows" +
          globalRolesTickets.length +
          " + " +
          applicationRolesTickets.length +
          "=" +
          tickets.length
      );
      createRolesRows();
    }
  }, [globalRolesTickets, applicationRolesTickets]);

  useEffect(() => {
    console.log("Creating rows");
    if (show == "all") {
      tickets = (globalRolesTickets as any[]).concat(applicationRolesTickets);
    } else if (show == "global") {
      tickets = globalRolesTickets;
    } else {
      tickets = applicationRolesTickets;
    }
    createRolesRows();
  }, [show]);

  function loadTickets() {
    if (props.userId != undefined && auth?.idTokenClaims) {
      let fetchRolesTicket: IFetchRolesTickets = {
        userId: props.userId,
        page: page,
      };
      dispatch(listGlobalRolesTicketsByUserAsync(fetchRolesTicket));
      dispatch(listApplicationTicketsByUserAsync(fetchRolesTicket));
    }
  }
 
  function createTag(value: any) {
    if (value == null || value == "") {
      return;
    }

    return (
      <>
        <Tag feType="outlined">{value}</Tag>
      </>
    );
  }

  function createStatusTag(value: any, reason: any) {
    let color: "blue" | "green" | "orange" | "purple" | "red" | "yellow";

    color = "green";
    if (value != "Pending") {
      reason = reason;
      if (reason == undefined) {
        reason = "";
      }
    }

    if (value == "Denied") {
      color = "red";
    }
    if (value == "Removed") {
      color = "purple";
    }
    if (value == "Pending") {
      color = "orange";
      reason = "";
    }

    return (
      <>
        <Tag feType="filled" feColor={color}>
          {value}
        </Tag>
        {reason != undefined && reason != "" && (
          <>
            &nbsp;
            <ReasonPopup reason={reason} />
          </>
        )}
      </>
    );
  }
  function displayResolvedByOrApproverGroup(item: IApprovalReqItem) {
    if (item.status != "Pending") {
      if (item.approverUsername != null) {
        return item.approverUsername;
      } else {
        return item.approverUserName;
      }
    } else {
      return <UserTicketApproverGroupLink groups={item.groupName} />;
    }
  }
  const createRolesRows = () => {
    if (tickets != undefined)
      if (tickets != undefined && tickets.length > 0) {
        let slice: IApprovalReqItem[] = tickets;

        setRolesRows(
          slice.map((item: IApprovalReqItem, index) => ({
            cells: [
              {
                children: displayDate(item.requestedDate),
              },
              {
                children: createTag(item.applicationName),
              },
              {
                children: createTag(item.requestedRole),
              },
              {
                children: createStatusTag(item.status, item.reason),
              },

              {
                children: displayDate(item.resolvedDate),
              },
              {
                children: displayResolvedByOrApproverGroup(item),
              },
            ],
          }))
        );
      } else {
        setRolesRows([]);
      }
  };

  const sortableHead: TableRow[] = [
    {
      cells: [
        {
          children: "Requested date",
          scope: "col",
          sortable: {},
        },
        { children: "Application", scope: "col" },
        { children: "Requested role", scope: "col" },
        {
          children: "Status",
          scope: "col",
        },
        {
          children: "Resolved date",
          scope: "col",
          sortable: {},
        },
        { children: "Resolved by", scope: "col", sortable: {} },
      ],
    },
  ];

  return (
    <>
      <div>
        <Select
          className="show_userdetail"
          defaultValue="all"
          style={{ width: "250px" }}
          feItems={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Global roles",
              value: "global",
            },
            {
              label: "Application roles",
              value: "application",
            },
          ]}
          feLabel="Show:"
          multiple={false}
          onChange={setShow}
          value={show}
        />
        <Grid>
          <GridItem
            feColspan={{
              desktop: 12,
              mobile: 12,
              tablet: 12,
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(255, 255, 255)",
                padding: "0.1rem",
                textAlign: "left",
                height: "calc(100vh - 251px)",
                overflow: "auto",
              }}
            >
              {" "}
              {globalRolesTicketsStatus == "loading" && (
                <div className="userDetailLoader">
                  {" "}
                  <Loader />{" "}
                </div>
              )}
              {globalRolesTicketsStatus == "idle" && rolesRows.length > 0 ? (
                <>
                  <Table
                    feBody={rolesRows}
                    feHead={sortableHead}
                    feScrollable={true}
                    className="TicketTable"
                    feCompact
                  />
                </>
              ) : (
                globalRolesTicketsStatus == "idle" && (
                  <p>No global roles found...</p>
                )
              )}
            </div>
          </GridItem>
        </Grid>
      </div>
    </>
  );
}
