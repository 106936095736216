import {
  Loader
} from "@skf-internal/ui-components-react";
import { SignInButton } from "../sso/SignInButton";
import { useAppSelector } from "../../app/hooks";
import { tesseractRolesStatus , selectAllowed} from "../sso/ssoSlice";

export interface ILoginProps {
  onLogin: Function;
}

export default function Login(props: ILoginProps) {
  const tessRolesStatus = useAppSelector(tesseractRolesStatus);
  const tesseractAllowed = useAppSelector(selectAllowed);
  return (
    <>

    {tessRolesStatus !== "notinitiated" &&

      <div id="container" className="unauthenticated">
        <div id="hero">
          <div className="second-image-cropper">
            <div className="blue">
              <img src="/images/1.Bg_blue.png"></img>
            </div>
          </div>
          <div className="image-cropper">
            <img src="/images/worker.png"></img>
          </div>
        </div> 
        <div id="form">
            <div id="tesseractlogo">
              <img src="/images/tesseract2.png"></img>
            </div>
        {tessRolesStatus === "failed" && (
         
            <div id="welcome">
                Failed. Please retry.
            </div>
          
        )}
        {tesseractAllowed === "checkongoing" && (
             <div id="welcome">
              <Loader feSize="md" />
            </div>
        )}
        {tesseractAllowed === "loggedout"  && (
            <div id="welcome">
              <h1>Welcome to Tesseract</h1>
              <p>
                You need to login to access our users <br />
                management system.
              </p>
              <SignInButton onLogin={props.onLogin} />
            </div>
        )}
        {(tesseractAllowed === "blocked_notskf" || tesseractAllowed === "blocked_norole") && (
         
            <div id="welcome">
              <h1>Tesseract</h1>
              <p>You are not allowed to use this application.</p>
            </div>
        )}
          </div>
      </div> }
    </>
  );
}


