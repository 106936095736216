import {
  Button,
  Checkbox,
  Dialog,
  Flexbox,
  Link,
  Spacer,
  TextAreaField,
} from "@skf-internal/ui-components-react";
import { useState, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { createGlobalRoleTicket } from "./globalRolesAPI";

import { ISearchUser, resetSearch } from "../group-users/groupUserSlice";
import UserSearchBox from "../common/userSearchBox";

export interface IAttachGlobalRoleProps {
  auth: any;
  role: string;
}

export default function AttachGlobalRole(props: IAttachGlobalRoleProps) {
  const { auth, role } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useAppDispatch();
  const [infoMessage, setInfoMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [reason, setReason] = useState("");
  const [declineHint, setDecineHint] = useState("");
  const [declineError, setDeclineError] = useState<"error" | undefined>(
    undefined
  );
  const [query, setQuery] = useState("");
  const [searchUser, setSearchUser] = useState<ISearchUser[]>([]);

  const defaultProps = {
    options: searchUser || [],
    getOptionLabel: (option: ISearchUser) => {
      return option.email;
    },
  };

  const setHint = (value: string) => {
    if (value != undefined && value != "") {
      setDecineHint(value.length + " / 500 max");
      if (value.length > 500) {
        setDeclineError("error");
      } else {
        setDeclineError(undefined);
      }
    } else {
      setDecineHint("500 max");
    }
    setDisable(declineError != undefined);
  };

  const create = () => {
    if (searchUser != null && searchUser.length > 0) {
      createGlobalRoleTicket({
        userProfileId: searchUser[0].id,
        globalRole: role,
        adminId: auth.idTokenClaims.sub,
        reason: reason,
      })
        .then((res) => {
          setInfoMessage("Successfully created!");
          dispatch(resetSearch([]));
          setEmail("");
          setTimeout(() => {
            setIsOpen(false);
            setInfoMessage("");
          }, 1000);
        })
        .catch((error) => {
          if (error.response) {
            setInfoMessage(error.response.data);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            setTimeout(() => {
              setInfoMessage("");
            }, 2500);
          }
        });
    } else {
      setInfoMessage("select user first");
    }
  };

  useEffect(() => {
    setHint("");
    return () => {
      setQuery("");
    };
  }, []);

  return (
    <div>
      <>
        <span className="tbUsers">
          <span className="userlink">
            <Link
              as="a"
              href="/"
              onClick={() => setIsOpen(true)}
              feIcon={{
                feIcon: "plus",
                position: "left",
              }}
            >
              Assign role to user
            </Link>
          </span>
        </span>
      </>
      <Dialog
        {...props}
        feInterruptive
        feTitle="Assign role to user"
        open={isOpen}
        cssWidth={"54rem"}
        cssHeight={"36rem"}
      >
        <div className="assignDialog">
          <span>
            You are currently assigning the role <b>{role}</b> to a user.
            <br />
          </span>
          {infoMessage !== "" && (
            <>
              <h6>{infoMessage}</h6>
              <Spacer />
            </>
          )}
          <p>
            <UserSearchBox setSelectedUser={setSearchUser} />
          </p>

          <p>
            If you have approval rights for the selected user’s role, it will be
            automatically assigned. Otherwise, an approval workflow will be
            initiated, and the designated approver will receive a notification
            regarding the new request.{" "}
          </p>

          <Checkbox
            disabled
            defaultChecked
            feLabel="The user will be notified about the new role."
            feSize="sm"
          />
          <Spacer feSpacing="md" />
          <TextAreaField
            feLabel="Personal message (optional)"
            feHint={declineHint}
            placeholder="Add a personal message to the user for your approval decision."
            feSeverity={declineError}
            onChange={(_, value) => {
              setReason(value);
              setHint(value);
            }}
            value={reason}
            className="AddRoleToUserTextField"
          />
          <Spacer feSpacing="md" />
          <Flexbox
            feGap="md"
            feJustifyContent="flex-end"
            className="modalButtons"
          >
            <Button feType="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => create()}
              disabled={
                disable ||
                searchUser == null ||
                searchUser.length != 1 ||
                status === "loading"
              }
            >
              Confirm
            </Button>
          </Flexbox>
        </div>
      </Dialog>
    </div>
  );
}
