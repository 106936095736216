import {
  Breadcrumbs,
  Button,
  Flexbox,
  Grid,
  GridItem,
  Icon,
  Pagination,
  Search,
  Select,
  Spacer,
  Table,
  TableRow,
} from "@skf-internal/ui-components-react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useDebounce } from "../common/useComplexUserSearch";
import { selectAuth, isSuperAdmin } from "../sso/ssoSlice";
import AttachGlobalRole from "./AttachGlobalRole";
import {
  allGlobalRolesAsync,
  selectGlobalRoles,
  IFetchRoles,
  IGlobalRoleData,
  selectStatus,
} from "./globalRolesSlice";
import ViewGlobalRoleLink from "./ViewGlobalRoleUsersLink";
import { useNavigate } from "react-router-dom";

export interface IGlobalRolesProps {}

export default function GlobalRoles(props: IGlobalRolesProps) {
  const roles = useAppSelector(selectGlobalRoles);
  const isLoggedInAsSuperAdmin = useAppSelector(isSuperAdmin);
  const pageSize = 10;
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const [show, setShow] = useState("myroles");
  const [page, setPage] = useState(1);
  const initial: TableRow[] = [];
  const [rolesRows, setRolesRows] = useState(initial);
  const [quickFilter, setQuickFilter] = useState("");
  const status = useAppSelector(selectStatus);
  const navigate = useNavigate();

  const handleUserInputChange = (value: string) => {
    setQuickFilter(value);
  };

  const debounceFilter = () => {
    //filter logic will go here and a new dispatch function is going to be added

    if (quickFilter.length > 2 || quickFilter === "") {
      dispatch(
        allGlobalRolesAsync({
          userId: auth.idTokenClaims.sub,
          quickFilter,
          level: show,
          page: page,
        })
      );
    }
  };

  const debouncedOnChange = useDebounce(debounceFilter, 1000);

  useEffect(() => {
    debouncedOnChange();
  }, [quickFilter]);

  useEffect(() => {
    loadRoles();
  }, [show, page]);

  const loadRoles = () => {
    if (auth?.idTokenClaims) {
      let fetchRoles: IFetchRoles = {
        userId: auth.idTokenClaims.sub,
        level: show,
        page: page,
        quickFilter: "",
      };
      dispatch(allGlobalRolesAsync(fetchRoles));
    }
  };

  useEffect(() => {
    createRolesRows();
  }, [roles]);

  const createRolesRows = () => {
    if (roles != undefined)
      if (roles.data != undefined && roles.data.length > 0) {
        let slice: IGlobalRoleData[] = roles.data;

        setRolesRows(
          slice.map((item: IGlobalRoleData, index) => ({
            cells: [
              {
                children: item.roleName,
                height: "48px",
              },
              {
                children:
                  item?.roleDisplayName !== null &&
                  item?.roleDisplayName?.length > 1
                    ? item.roleDisplayName
                    : "  ",
              },
              {
                children:
                  item?.roleDescription !== null &&
                  item?.roleDescription?.length > 1
                    ? item.roleDescription
                    : "  ",
              },

              {
                children: (
                  <ViewGlobalRoleLink
                    auth={auth}
                    roleId={item.roleId}
                    roleName={item.roleName}
                  />
                ),
              },
              {
                children: <AttachGlobalRole auth={auth} role={item.roleName} />,
              },
            ],
          }))
        );
      } else {
        setRolesRows([]);
      }
  };

  const sortableHead: TableRow[] = [
    {
      cells: [
        { children: "Role name", scope: "col", sortable: {}, width: "450px" },
        { children: "DisplayName", scope: "col", sortable: {} },
        { children: "Description", scope: "col", sortable: {} },
        { children: "", scope: "col", width: "140px" },
        { children: "", scope: "col", width: "160px" },
      ],
    },
  ];
  return (
    <>
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={[
          {
            label: "Global roles",
          },
        ]}
        feSize="sm"
        feType="static"
        className="breadcrumbs"
      />
      <Spacer feSpacing="md" />
      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div className="contentArea">
            <GridItem
              feColspan={{
                desktop: 12,
                mobile: 12,
                tablet: 12,
              }}
            >
              <div>
                <div>
                  <Flexbox feGap="md" feJustifyContent="space-between">
                    <div
                      style={{
                        width: "60rem",
                        height: "50px",
                        margin: "0.8rem 0rem",
                      }}
                    >
                      <Search
                        className="userSearch"
                        feLabel=""
                        feResetButton={{
                          "aria-label": "Rensa",
                          onClick: () => setQuickFilter(""),
                        }}
                        placeholder={"Search for role name or description"}
                        onChange={(_, value) => {
                          setQuickFilter(value);
                        }}
                        onKeyDown={(event: any) => {
                          if (event.key === "Enter") {
                            handleUserInputChange(quickFilter);
                          }
                        }}
                        value={quickFilter}
                        disabled={status !== "idle"}
                      />
                    </div>
                    {isLoggedInAsSuperAdmin && (
                      <Button
                        feType="secondary"
                        onClick={function Ga() {
                          navigate("/global-role-admin");
                        }}
                        className="GlobalRoleAdminButton"
                      >
                        Global Role Administration
                      </Button>
                    )}
                  </Flexbox>
                </div>
                {rolesRows.length > 0 ? (
                  <div style={{ height: "calc(100vh - 17rem)" }}>
                    <Table
                      feBody={rolesRows}
                      feHead={sortableHead}
                      feCompact
                      feScrollable
                    />
                    {roles.total > roles.page_size && (
                      <Flexbox feJustifyContent="flex-end">
                        <Pagination
                          feControls={{
                            next: {
                              "aria-label": "Next page",
                            },
                            prev: {
                              "aria-label": "Previous page",
                            },
                          }}
                          feItemsPerPage={roles.page_size}
                          feOnPageChange={(newPage: number) => {
                            setPage(newPage);
                          }}
                          fePage={roles.page}
                          feTotal={roles.total}
                        />
                      </Flexbox>
                    )}
                  </div>
                ) : (
                  <p>No roles found...</p>
                )}
              </div>
            </GridItem>
          </div>
        </GridItem>
      </Grid>
    </>
  );
}
