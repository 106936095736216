import axios from "axios";
import { fetchAccessToken } from "../common/Common";

export interface IPayload{
  requestId:string;
  approverUserId:string;
  status: 'Approved' | 'Denied';
}

//axios.defaults.headers.common["x-functions-key"] =
//  process.env.REACT_APP_AUTH_TOKEN || "";

export async function fetchApplicationApprovalRequests(userId: string, status?: string) {
  let accessToken = await fetchAccessToken();
  
  let query =""; 
  if (status && status != ""){
    query="?status=" +status; 
  }
  axios.defaults.headers.common["Authorization"] ="Bearer " +accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admins/${userId}/requests${query}`
  );
}

export async function handleApplicationApprovalRequests(ticketId: any, payload: any) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] ="Bearer " +accessToken;
  return axios.put(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/upr/approval-ticket/${ticketId}`,
    payload
  );
}

export async function fetchGlobalRoleApprovalRequests(userId: string, status?: string) {
  let accessToken = await fetchAccessToken();
  let query =""; 
  if (status && status != ""){
    query="?status=" +status; 
  }
  axios.defaults.headers.common["Authorization"] ="Bearer " +accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admins/${userId}/globalroles/tickets${query}`
  );
}

export async function handleGlobalRoleApprovalRequests(payload: IPayload) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] ="Bearer " +accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/globalroles/approve`,
    payload
  );
}
