import axios from "axios";
import { fetchAccessToken } from "../common/Common";

export async function fetchCollectionsForAdmin(adminId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admin/${adminId}/rolecollections/`
  );
}

export async function fetchCollectionFromRole(roleId: number) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/rolecollection?roleId=${roleId}`
  );
}

export async function detachUserFromCollection(
  userProfileId: string,
  roleCollectionId: number
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.delete(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/rolecollection/users?roleCollectionId=${roleCollectionId}&userProfileId=${userProfileId}`
  );
}
export async function attachUserToCollection(payload: {
  userProfileId: string;
  roleCollectionId: number;
}) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/rolecollection/users`,
    payload
  );
}
