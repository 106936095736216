import {
  Button,
  Dialog,
  Flexbox,
  Spacer,
  Tag,
  Text,
  TextAreaField,
  useToast,

} from "@skf-internal/ui-components-react";
import { getCountryName, getFlag } from "../../Utils/getflag";
import { IUserItem } from "./applicationDetailsSlice";

import ShowDate from "../common/CustomDateTime";
import ViewUserLink from "./ViewUserLink";
import ApplicationRoleTag from "./ApplicationRoleTag";

export const createUsersRows = (
  stateUsers: IUserItem[],
  auth: any,
  roleId: number,
  lastItemRef: any,
  reloadCallback: any,
  isSuperAdmin: boolean, 
  appId: string
) => {

  if (stateUsers?.length > 0) {
    let slice: IUserItem[] = stateUsers;
    let formatRow = slice.map((item: IUserItem, index) => {
      if (slice.length === index + 1) {
        return {
          cells: [
            {
              children: (
                <span key={item.id} title={item.givenName}>
                  {item.givenName}
                </span>
              ),
              height: "48px",
            },
            {
              children: <span title={item.surname}>{item.surname}</span>,
            },
            {
              children: <span title={item.email}>{item.email}</span>,
            },
            {
              children: (
                <div className="cellCountry" title={getCountryName(item.countryIso)}>
                {item.countryIso?.length > 1 && (
                  <img
                    loading="lazy"
                    height="14"
                    srcSet={`https://flagcdn.com/w40/${getFlag(
                      item.countryIso
                    )}.png`}
                    src={`https://flagcdn.com/w40/${getFlag(item.countryIso)}.png`}
                    alt={getCountryName(item.countryIso)}
                    title={getCountryName(item.countryIso)}
                    className="flag"
                  />
                )}
                <span>{getCountryName(item.countryIso)}</span>
              </div>
              ),
            },
            {
              children: (
                <>
                  <span
                    style={{ display: "inline-flex", textWrap: "wrap" }}
                    ref={lastItemRef}
                    title={item.company}
                  >
                   {item.company}
                  </span>
                </>
              ),
            },
            {
              children: (
                <>
                  {splitRolesToTags(
                    item,
                    auth,
                    roleId,
                    reloadCallback,
                    isSuperAdmin, 
                    appId
                  )}
                </>
              ),
              className: "TagCell",
            },
            {
              children: (
                <>
                  {item?.lastLogon ? (
                    <ShowDate dataTimeString={item.lastLogon} />
                  ) : (
                    ""
                  )}
                </>
              ),
              className: "dateCell",
            },
            {
              children: (
                <>
                  {item?.createdAt ? (
                    <ShowDate dataTimeString={item.createdAt} />
                  ) : (
                    ""
                  )}
                </>
              ),
              className: "dateCell",
            },
            {
              children: <ViewUserLink user={item} />,
              className: "dateCell",
            },
          ],
        };
      } else {
        return {
          cells: [
            {
              children: (
                <span key={item.id} title={item.givenName}>
                  {item.givenName}
                </span>
              ),
              height: "48px",
            },
            {
              children: <span title={item.surname}>{item.surname}</span>,
            },
            {
              children: <span title={item.email}>{item.email}</span>,
            },
            {
              children: (
                <div className="cellCountry" title={getCountryName(item.countryIso)}>
                {item.countryIso?.length > 1 && (
                  <img
                    loading="lazy"
                    height="14"
                    srcSet={`https://flagcdn.com/w40/${getFlag(
                      item.countryIso
                    )}.png`}
                    src={`https://flagcdn.com/w40/${getFlag(item.countryIso)}.png`}
                    alt={getCountryName(item.countryIso)}
                    title={getCountryName(item.countryIso)}
                    className="flag"
                  />
                )}
                <span>{getCountryName(item.countryIso)}</span>
              </div>
              ),
            },
            {
              children: (
                <>
                  <span
                    style={{ display: "inline-flex", textWrap: "wrap" }}
                    title={item.company}
                  >
                    {item.company}
                  </span>
                </>
              ),
            },
            {
              children: (
                <>
                  {splitRolesToTags(
                    item,
                    auth,
                    roleId,
                    reloadCallback,
                    isSuperAdmin, 
                    appId
                  )}
                </>
              ),
              className: "TagCell",
            },
            {
              children: (
                <>
                  {item?.lastLogon ? (
                    <ShowDate dataTimeString={item.lastLogon} />
                  ) : (
                    ""
                  )}
                </>
              ),
              className: "dateCell",
            },
            {
              children: (
                <>
                  {item?.createdAt ? (
                    <ShowDate dataTimeString={item.createdAt} />
                  ) : (
                    ""
                  )}

                </>
              ),
              className: "dateCell",
            },
            {
              children: <ViewUserLink user={item} />,
            },
          ],
        };
      }
    });
    return formatRow;
  } else {
    return [];
  }
};
function splitRolesToTags(user: IUserItem ,
  auth: any,
  roleId: Number, 
  reloadCallback: any,
  isSuperAdmin: boolean, 
  appId: string
) {
  let roles: any[] = user.applicationRoles;
  let i: number = 0;
  let res: any;
  let role: any;
  let count: number = 0;
  let roleIdNbr = Number(roleId);
  let rolesToolTip = roles
    .map((role) => {
      return role.roleName;
    })
    .join(",\n ");
    role = roles.filter((p) => p.roleId == roleId)[0]; 

  return (
    <>
      <div style={{ whiteSpace: "nowrap" }}>
      {roles.filter((p) => p.roleId == roleId).length > 0 && (
        <>
          <ApplicationRoleTag applicationId={appId} role={role} user={user} auth={auth} callback={reloadCallback} isSuperAdmin={isSuperAdmin} />
        </>
        )}
        {roles.length > 1 && (
          <span
            className="additionalRoles"
            aria-label={"User has this roles:\n\n" + rolesToolTip}
          >
            <span className="counter"> +{roles.length - 1}</span>
          </span>
        )}
      </div>
    </>
  );
}
