import {
  DatePicker,
  Heading,
  IconButton,
  RadioGroup,
  Select,
  SelectItem,
  Spacer,
} from "@skf-internal/ui-components-react";
import { useEffect, useState } from "react";

export interface IDateFilterValue {
  type: string;
  fromDate: string;
  toDate: string;
}

export interface IDateFilterProps {
  title: string;
  useNever: boolean;
  filterValue: IDateFilterValue;
}

export default function DateFilter(props: IDateFilterProps) {
  const { title, useNever, filterValue } = props;
  const [filterType, setFilterType] = useState("all");
  const [dateValue, setDateValue] = useState<any>(new Date());
  const [doubleDateValue, setDoubleDateValue] = useState<any>([
    new Date(),
    new Date(),
  ]);
  const dropDownItems = () => {
    let items: SelectItem[] = [];

    if (useNever) {
      items.push({ label: "No data", value: "Never" });
    }
    items.push({ label: "Before", value: "Before" });
    items.push({ label: "After", value: "After" });
    items.push({ label: "Between", value: "Between" });

    return items;
  };

  useEffect(() => {
    setValue();
  }, [filterValue]);
  useEffect(() => {
    if (filterType === "After" || filterType === "Before") {
      setDate(dateValue);
    } else if (filterType === "Between") {
      setDate(doubleDateValue);
    }
  }, [filterType]);

  const setValue = () => {
    if (filterValue != null) {
      setFilterType(filterValue.type);
      if (filterValue.type === "After") {
        setDateValue(new Date(filterValue.fromDate));
      } else if (filterValue.type === "Before") {
        setDateValue(new Date(filterValue.toDate));
      } else if (filterValue.type === "Between") {
        setDoubleDateValue([
          new Date(filterValue.fromDate),
          new Date(filterValue.toDate),
        ]);
      }
    } else {
      setFilterType("");
    }
  };

  function setType(value: any) {
    let i = 1;
    setFilterType(value);
    filterValue.type = value;
  }

  function setDate(value: any) {
    if (filterType == "After") {
      setDateValue(value);
      filterValue.fromDate = value?.toISOString();
      filterValue.toDate = "";
    } else if (filterType == "Before") {
      setDateValue(value);
      filterValue.fromDate = "";
      filterValue.toDate = value?.toISOString();
    } else if (filterType == "Between") {
      setDoubleDateValue(value);
      filterValue.toDate = value[1]?.toISOString();
      filterValue.fromDate = value[0]?.toISOString();
    }
  }

  return (
    <div className="FilterDatePicker">
      <div className="dateFilterTitle">{title}</div>
      <span>
        <Select
          feItems={dropDownItems()}
          feLabel=""
          name="filterType"
          onChange={(value: any) => setType(value)}
          multiple={false}
          fePlaceholder="Select filter type"
          feSize="sm"
          value={filterType}
        />
        {filterType != null && filterType != "" && (
          <IconButton
            aria-label="close"
            title="Clear filter"
            as="button"
            feIcon="closeSmall"
            className="clearDateFilter"
            onClick={() => setType("")}
          />
        )}
      </span>
      <Spacer feSpacing="xs" />
      <span>
        {(filterType === "Before" || filterType === "After") && (
          <DatePicker
            feLabel=""
            feSize="sm"
            onChange={(value: any) => setDate(value)}
            value={dateValue}
          />
        )}
        {filterType === "Between" && (
          <span className="between">
            <DatePicker
              feLabel=""
              feRange
              feSize="sm"
              onChange={(value: any) => setDate(value)}
              value={doubleDateValue}
            />
          </span>
        )}
      </span>
    </div>
  );
}
