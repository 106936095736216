import { useEffect, useState } from "react";
import { IGlobalRoleData } from "../global-roles/globalRolesSlice";
import { Table, TableRow } from "@skf-internal/ui-components-react";

export interface IGlobalRoleAdminRolesProps {
  roles: IGlobalRoleData[];
}
export default function GlobalRoleAdminRoles(
  props: IGlobalRoleAdminRolesProps
) {
  const { roles } = props;
  const [rolesRows, setRolesRows] = useState<TableRow[]>([]);

  const sortableHead: TableRow[] = [
    {
      cells: [
        { children: "Role name", scope: "col", sortable: {}, width: "450px" },
        { children: "DisplayName", scope: "col", sortable: {} },
        { children: "Description", scope: "col", sortable: {} },
      ],
    },
  ];
  const createRolesRows = () => {
    if (roles != undefined)
      if (roles != undefined && roles.length > 0) {
        let slice: IGlobalRoleData[] = roles;

        setRolesRows(
          slice.map((item: IGlobalRoleData, index) => ({
            cells: [
              {
                children: item.roleName,
                height: "48px",
              },

              {
                children:
                  item?.roleDisplayName !== null &&
                  item?.roleDisplayName?.length > 1
                    ? item.roleDisplayName
                    : "  ",
              },
              {
                children:
                  item?.roleDescription !== null &&
                  item?.roleDescription?.length > 1
                    ? item.roleDescription
                    : "  ",
              },
            ],
          }))
        );
      } else {
        setRolesRows([]);
      }
  };
  useEffect(() => {
    createRolesRows();
  }, [roles]);

  return (
    <>
      <Table feBody={rolesRows} feHead={sortableHead} feCompact feScrollable />
    </>
  );
}
