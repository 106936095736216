import {
  Breadcrumbs,
  BreadcrumbsItem,
  Grid,
  GridItem,
  Loader,
  Spacer,
  Table,
  Tabs,
  Time,
} from "@skf-internal/ui-components-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Roles from "./Roles";
import { selectAuth } from "../sso/ssoSlice";

import {
  applicationAsync,
  appsStatus,
  selectApp,
} from "../applications/applicationsSlice";
import Admins from "./Admins";
import { IApplication } from "../applications/Applications";

export interface RouteParams {
  appId: string | "";
}
export interface IApplicationDetailsProps {}

export default function ApplicationDetails(props: IApplicationDetailsProps) {
  const { appId = "" } = useParams();
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectRole, setSelectRole] = useState<number>(0);
  const app = useAppSelector(selectApp);
  const appStatus = useAppSelector(appsStatus);
  const [currentApp, setCurrentApp] = useState<IApplication>();
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsItem[]>([]);

  const navigate = useNavigate();
  const gotoRoute = (route: string) => {
    navigate(route);
  };
  const onTabClick = (_: any, index: React.SetStateAction<number>) => {
    //if (!(selectRole == 0 && index == 1)) {
    setActiveTab(index);
    setBreadc(index.valueOf() as number);
    //}
  };
  useEffect(() => {
    if (auth?.idTokenClaims && !loading) {
      setLoading(true);
      console.log("Loading app" + app?.id + " " + appStatus + " ");
      dispatch(applicationAsync(appId));
    }
  }, []);

  useEffect(() => {
    if (app?.applicationId == appId) {
      setCurrentApp(app);
      // app.applicationId = app.id;
      console.log("Current App " + app.id + " " + app.applicationId);
      setBreadc(0);
      setLoading(false);
    }
  }, [app]);

  const handleSelectUser = (roleId: number) => {
    if (roleId === 0) {
      setSelectRole(roleId);
      setActiveTab(0);
    } else {
      setSelectRole(roleId);
      setActiveTab(1);
    }
  };

  const setBreadc = (tab: number) => {
    let bc = [
      {
        label: "Application",
        href: "/app",
        onClick: (e, route) => gotoRoute(route),
      },
      {
        label: `${app?.applicationName}`,
        href: "/app/" + app?.applicationId,
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    ] as BreadcrumbsItem[];

    setBreadcrumbs(bc);
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <Spacer feSpacing="md" />
          <Breadcrumbs
            feItems={breadcrumbs as BreadcrumbsItem[]}
            feSize="sm"
            feType="interactive"
            className="breadcrumbs"
          />
          <Spacer />
          <Grid className="gridHead">
            <GridItem
              feColspan={{
                desktop: 12,
                mobile: 12,
                tablet: 12,
              }}
            >
              <div className="contentArea">
                <Tabs
                  feType="expanded"
                  feSelected={activeTab}
                  onClick={onTabClick}
                  feItems={[
                    {
                      children: (
                        <div>
                          <Grid>
                            <React.Fragment key=".0">
                              <GridItem
                                feColspan={{
                                  desktop: 10,
                                  mobile: 10,
                                  tablet: 10,
                                }}
                              ></GridItem>
                              <GridItem
                                feColspan={{
                                  desktop: 2,
                                  mobile: 2,
                                  tablet: 2,
                                }}
                              ></GridItem>
                              <GridItem
                                feColspan={{
                                  desktop: 12,
                                  mobile: 12,
                                  tablet: 12,
                                }}
                              >
                                {activeTab === 0 && (
                                  <Roles
                                    setRole={handleSelectUser}
                                    auth={auth}
                                    appId={appId || ""}
                                  />
                                )}
                              </GridItem>
                            </React.Fragment>
                          </Grid>
                        </div>
                      ),
                      id: "949u8zevufo6skv9",
                      label: "Application roles",
                    },
                    {
                      children: (
                        <div>
                          {activeTab === 1 && currentApp != null && (
                            <Admins
                              setRole={handleSelectUser}
                              auth={auth}
                              app={currentApp}
                            />
                          )}
                        </div>
                      ),
                      id: "t05rbms0cxcktmh3",
                      label: "Administrators",
                    },
                  ]}
                />
              </div>
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
}
