import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@skf-internal/ui-components-react";

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
 export const SignInButton: React.FC<any> = (props: { onLogin: any; children: any }) => {
  const { instance } = useMsal();

  return (
    <Button
      onClick={() => props.onLogin(instance)}
    >
      Log in
    </Button>
  );
}
