import {
  Button,
  Dialog,
  Flexbox,
  Spacer,
} from "@skf-internal/ui-components-react";

import { useState, useEffect } from "react";
import {
  /*createGlobalRole, createGlobalRoleTicket,*/ attachUserToGroup,
} from "./groupUsersAPI";
import { ISearchUser } from "./groupUserSlice";
import { useNavigate } from "react-router-dom";

import UserSearchBox from "../common/userSearchBox";

export interface IAddUsersProps {
  auth: any;
  groupId: any;
  canAddUser: boolean;
}

export default function AddUsers(props: IAddUsersProps) {
  const { auth, groupId, canAddUser } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [showField, setShowField] = useState(true);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const [searchUser, setSearchUser] = useState<ISearchUser[]>([]);

  const defaultProps = {
    options: searchUser || [],
    getOptionLabel: (option: ISearchUser) => {
      return option.email;
    },
  };

  const create = () => {
    if (searchUser != null && searchUser.length > 0) {
      let result: string = "";
      searchUser.map((user: ISearchUser) => {
        attachUserToGroup({
          userProfileId: user.id,
          group: groupId,
        })
          .then((res) => {
            setInfoMessage("User(s) successfully created!");
            result +=
              "User " + user.email + " connected to group " + groupId + " \n\r";
          })
          .catch((error) => {
            if (error.response) {
              setInfoMessage(error.response.data);
              result +=
                "User " +
                user.email +
                " NOT connected to group " +
                groupId +
                error.response.data +
                " \n\r";
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              setTimeout(() => {
                setInfoMessage("");
              }, 2500);
            }
          });
      });
      setTimeout(() => {
        setIsOpen(false);
        setInfoMessage("");
        navigate(0);
      }, 1000);
    } else {
      setInfoMessage("select user first");
      console.log("select user first");
    }
  };

  useEffect(() => {
    return () => {
      setQuery("");
    };
  }, []);

  return (
    <div>
      <Button onClick={() => setIsOpen(true)} disabled={!canAddUser}>
        Add member
      </Button>
      <Dialog
        {...props}
        feInterruptive
        feTitle="Add group member"
        open={isOpen}
        cssWidth={"40rem"}
        cssHeight={"25rem"}
      >
        <div className="globalroleadminadd">
          <p>
            You are currently adding a member to the group <b>{groupId}</b>
          </p>
          {infoMessage !== "" && (
            <>
              <h6>{infoMessage}</h6>
              <Spacer />
            </>
          )}
          <UserSearchBox setSelectedUser={setSearchUser} />
          <Spacer />
          <Flexbox
            feGap="md"
            feJustifyContent="flex-end"
            className="modalButtons"
          >
            <Button feType="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => create()}
              disabled={
                searchUser == null ||
                searchUser.length != 1 ||
                status === "loading"
              }
            >
              Confirm
            </Button>
          </Flexbox>
        </div>
      </Dialog>
    </div>
  );
}
