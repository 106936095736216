import React from 'react';
import { IPublicClientApplication } from "@azure/msal-browser";
import { useDispatch } from 'react-redux';
import { resetTesseractAuth } from "./ssoSlice"; // Justera sökvägen enligt din projektstruktur

interface LogoutProps {
  instance: IPublicClientApplication;
}

const LogoutComponent: React.FC<LogoutProps> = ({ instance }) => {
  const dispatch = useDispatch();

    instance
      .logoutPopup()
      .then((resp) => {
        sessionStorage.removeItem("tesseractRoles"); 
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        dispatch(resetTesseractAuth("loggedout"));  
      });
 
  return (
    <></>
  );
};

export default LogoutComponent;