import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchApplication, fetchApplications } from "./applicationsAPI";

export interface IApplication {
  applicationId: string;
  id:string;
  applicationName: string;
}

export interface IFetchApplication {
  userId: string; 
}

export interface ApplicationsState {
  apps: IApplication[];
  app: IApplication; 
  status: "idle" | "loading" | "failed";
}

const initialState: ApplicationsState = {
  apps: [],
  app: {} as IApplication,
  status: "idle",
};


export const applicationAsync = createAsyncThunk(
  "fetchApplication",
  async (appId: string) => {
    const response = await fetchApplication(appId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const applicationsAsync = createAsyncThunk(
  "fetchApplications",
  async (fetch: IFetchApplication) => {
    const response = await fetchApplications(fetch.userId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.apps = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applicationsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(applicationsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.apps = action.payload;
      })
      .addCase(applicationsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(applicationAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(applicationAsync.fulfilled, (state, action) => {
        state.status = "idle";
        action.payload.applicationId =action.payload.id;
        state.app = action.payload;
      })
      .addCase(applicationAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setAuth } = applicationsSlice.actions;

export const selectApps = (state: RootState) => state.applications.apps;

export const selectApp = (state: RootState) => state.applications.app;

export default applicationsSlice.reducer;

export const appsStatus = (state: RootState) => state.applications.status;
