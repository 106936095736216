import {
  Button,
  Dialog,
  Flexbox,
  GridItem,
  Icon,
  Loader,
  Spacer,
  Switch,
  Text,
} from "@skf-internal/ui-components-react";
import { useEffect, useState } from "react";
import {
  getOathMethodsAsync,
  oathMethodStatus,
  oathMethod,
} from "./graphSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { deleteOathToken } from "./graphAPI";

export interface IUserTOTPProps {
  userId: string;
  userName: string;
}

export default function UserTOTP(props: IUserTOTPProps) {
  const { userId, userName } = props;
  const [tOTPStatus, setTOTPStatus] = useState(false);
  const [tOTPStatusLoad, setTOTPStatusLoad] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const totp = useAppSelector(oathMethod);
  const totpStatus = useAppSelector(oathMethodStatus); 
  
  useEffect(() => {
    if (props.userId != null && props.userId != "") {
      setTOTPStatusLoad(false);
      dispatch(
        getOathMethodsAsync({
          userId: props.userId
       
        })
      );
    }
  }, [props.userId]);

  useEffect(() => {
    if (totpStatus == "idle" && totp != null) {
      setTOTPStatusLoad(true);
     
      if (totp.length > 0 ) {
        setTOTPStatus(true);
      } else {
        setTOTPStatus(false);
      }
    }
  }, [totp]);

  const removeTotpFromUser = () => {
    setTOTPStatusLoad(false);
    if(totp != null){
      deleteOathToken(userId, totp[0].id)
        .then((res) => {
          setTimeout(() => {
            setOpen(false);
          }, 1000);
          setTOTPStatus(false);
          setTOTPStatusLoad(true);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    }
  };

  return (
    <>
      <GridItem
        feColspan={{
          desktop: 4,
          mobile: 4,
          tablet: 4,
        }}
      >
        <div  title="TOTP = Time-based One Time Password">TOTP <Icon feIcon="infoCircleOutlined"></Icon>
          </div>
      </GridItem>
      <GridItem
        feColspan={{
          desktop: 8,
          mobile: 8,
          tablet: 8,
        }}
      >
        <div
          style={{
            overflow: "hidden",
            marginRight: "28px",
            textOverflow: "ellipsis",
          }}
          title={tOTPStatus ? "TOTP Enabled. To remove TOTP setting, disable TOTP." : "TOTP disabled."}
        >{!tOTPStatusLoad && <><Loader feSize="sm" /></>}
          {tOTPStatusLoad && (
            <>
              <Switch
                feLabel={tOTPStatus ? "Enabled" : "Disabled"}
                checked={tOTPStatus}
                disabled={!tOTPStatus}
                onChange={() => {
                  setOpen(true);
                }}
                feSize="sm"
              />
              <Dialog
                {...props}
                feInterruptive
                feTitle="Remove TOTP from user"
                open={isOpen}
              >
                <Text>
                  Remove <b>TOTP</b>
                  <br />
                  from user <b>{props.userName}</b>
                </Text>
                <Spacer feSpacing="xxl"></Spacer>
                <Flexbox feGap="md" feJustifyContent="flex-end">
                  <Button feType="secondary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button onClick={() => removeTotpFromUser()}>Remove</Button>
                </Flexbox>
              </Dialog>
            </>
          )}
        </div>
      </GridItem>{" "}
    </>
  );
}
