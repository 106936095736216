import {
  IconButton,
  Popup,
  Text,
  usePopup,
} from "@skf-internal/ui-components-react";
import { useRef } from "react";

export interface IReasonPopupProps {
  reason: string;
}

export default function ReasonPopup(props: IReasonPopupProps) {
  const [hoverRef, isHovered] = usePopup();

  const popoverRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <IconButton
        aria-label="Information"
        as="button"
        feIcon="infoCircleOutlined"
        feSize="lg"
        ref={hoverRef}
      />
      <Popup isHovered={isHovered} triggerElement={hoverRef.current}>
        <Text>Message:</Text>
        <Text>{props.reason}</Text>
      </Popup>
    </>
  );
}
