import React from "react";
import { Tag } from "@skf-internal/ui-components-react";
import { IDateFilterValue } from "./DateFilter";

interface IDateFilterTagProps {
  title: string;
  filterValue: IDateFilterValue | undefined;
}

const formatDate = (date: string): string => date.substring(0, 10);

const DateFilterTag: React.FC<IDateFilterTagProps> = ({
  title,
  filterValue,
}) => {
  if (!filterValue) {
    return null;
  }

  let result: string = title + ": ";

  switch (filterValue.type) {
    case "Never":
      result += "No data";
      break;
    case "Before":
      result += ` < ${formatDate(filterValue.toDate)}`;
      break;
    case "After":
      result += ` > ${formatDate(filterValue.fromDate)}`;
      break;
    case "Between":
      result += `${formatDate(filterValue.fromDate)} - ${formatDate(
        filterValue.toDate
      )}`;
      break;
    default:
      return null;
  }

  return <Tag feType="outlined">{result}</Tag>;
};

export default DateFilterTag;
