import {
  Button,
  Dialog,
  Flexbox,
  Link,
  Spacer,
  TextAreaField,
  TextField,
} from "@skf-internal/ui-components-react";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateGlobalGroup } from "./globalGroupsAPI";
import {
  IGlobalGroup,
  IGlobalGroupData,
  selectGlobalGroups,
} from "./globalGroupSlice";
import { useNavigate } from "react-router-dom";

export interface IEditGroupProps {
  auth: any;
  groupId: string;
  groupDescription: string;
}

export default function EditGroup(props: IEditGroupProps) {
  const { auth, groupId, groupDescription } = props;
  const groups: IGlobalGroup = useAppSelector(selectGlobalGroups);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [descHint, setDescHint] = useState("50 max");
  const [descError, setDescError] = useState<"error" | undefined>();

  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [isSuperAdmin, setSuperAdmin] = useState(false);

  const activateCreateButton = () => {
    setDisable(!(description != undefined && !descError));
  };

  const calculateDesc = (text: string) => {
    setDescError(undefined);
    if (text != undefined && text != "") {
      setDescHint(text.length + " / 50 max");
      if (text.length > 50) {
        setDescError("error");
      }
    } else {
      setDescHint("50 max");
    }
    setDescription(text);
    activateCreateButton();
  };

  useEffect(() => {
    activateCreateButton();
    calculateDesc(description);
  }, [description]);

  useEffect(() => {
    if (auth?.idTokenClaims?.roles) {
      if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
        setSuperAdmin(true);
      }
    }
  }, []);

  useEffect(() => {
    if (groups.data != undefined) {
      let filteredGroups: IGlobalGroupData[] = groups.data.filter(
        (globalGroup: IGlobalGroupData) => {
          if (globalGroup.globalGroupName == groupId) {
            return globalGroup;
          }
        }
      );
      if (filteredGroups != undefined && filteredGroups.length > 0) {
        setGroupName(filteredGroups[0].globalGroupName);
        setDescription(filteredGroups[0].globalGroupDescription);
      }
    }
  }, [groups]);

  const create = () => {
    let nGroup = updateGlobalGroup({
      GroupName: groupName,
      GroupDescription: description,
    }).then((res) => {
      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
      navigate(0);
    });
  };

  useEffect(() => {
    setDescError(undefined);
    setDescHint("50 max");
    setGroupName(groupId);
    setDescription(groupDescription);

    activateCreateButton();
  }, [isOpen]);

  const isButtonDisabled = () => {
    let res: boolean | undefined;
    res = false;
    if (disable) {
      res = true;
    }
    return res;
  };

  return (
    <div>
      {" "}
      {isSuperAdmin && (
        <Link
          as="button"
          feIcon={{
            feIcon: "edit",
            position: "left",
          }}
          className="tdEditPen"
          type="button"
          onClick={() => setIsOpen(true)}
        >
          {" "}
        </Link>
      )}
      <Dialog {...props} feInterruptive feTitle="Update group" open={isOpen}>
        <TextField feLabel="Group name" disabled value={groupId} />
        <Spacer />
        <Spacer feSpacing="sm" />
        <TextAreaField
          feLabel="Description"
          feHint={descHint}
          required
          feSeverity={descError}
          onChange={(event) => {
            calculateDesc(event.target.value);
          }}
          value={description}
        />
        <Flexbox feGap="md" feJustifyContent="flex-end">
          <Button feType="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => create()} disabled={isButtonDisabled()}>
            Confirm
          </Button>
        </Flexbox>
      </Dialog>
    </div>
  );
}
