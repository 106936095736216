import {
    Button,
    Loader,
    Tabs,
    Tag,
    useToast,
  } from "@skf-internal/ui-components-react";
  import { useEffect, useState } from "react";
  import { useAppDispatch, useAppSelector } from "../../app/hooks";
  import { selectAuth } from "../sso/ssoSlice";
  import {
    approvalsApplicationPendingAsync,
    approvalsApplicationResolvedAsync,
    approvalsGlobalRolePendingAsync,
    IApprovalReqItem,
    selectApplicationPendingRequests,
    selectApplicationResolvedRequests,
    selectGlobalRolesPendingRequests,
    statusAppTicket,
    statusGlobalTicket
  } from "./approvalsSlice";
  
  import { handleApplicationApprovalRequests } from "./approvalsAPI";
  import { displayDate, textToColor } from "../common/Common";
  import ReasonPopup from "../common/ReasonPopup";
  import AppRoleApprovalDialog from "./ApprovalDialog";
  import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import ApprovalDialog from "./ApprovalDialog";
  
  export interface IPendingRequestsProps {}
  export interface IRequestRow {
    requestId: string;
    email: string;
    firstName: string;
    lastName: string;
  }
  export interface IPayload {
    requestId: string;
    approverUserId: string;
    status: "Approved" | "Denied";
    reason: string;
  }
  
  export default function PendingRequests(
    props: IPendingRequestsProps
  ) {
    const auth = useAppSelector(selectAuth);
    const loadingstatusApp = useAppSelector(statusAppTicket);
    const loadingstatusGlobal = useAppSelector(statusGlobalTicket);
    const applicationApprovalsPending = useAppSelector(
      selectApplicationPendingRequests
    );
    const globalApprovalsPending = useAppSelector(
      selectGlobalRolesPendingRequests
    );

    const dispatch = useAppDispatch();
    const [tickets, setTickets] = useState<IApprovalReqItem[]>([]);
    let  pendingAppTickets: IApprovalReqItem[] = [];
    let pendingGlobalTickets: IApprovalReqItem[] = [];
    const [isOpen, setIsOpen] = useState(false);
 
    const {  addToast, } = useToast();
    const [ticket, setTicket] = useState<any>(); 
    const [dialogueType, setDialogueType] = useState(""); 

  
    useEffect(() => {
      loadTickets(); 
    }, []);
  
    function loadTickets() {
      setTickets([]); 
      console.log("Reload table"); 
      dispatch(
        approvalsApplicationPendingAsync({
          userId: auth.idTokenClaims.sub,
        })
      );
      dispatch(
        approvalsGlobalRolePendingAsync({
          userId: auth.idTokenClaims.sub,
        })
      );
    }

    //Use this solution until API fixed.
    useEffect(() => {
      if (loadingstatusApp === "idle" && loadingstatusGlobal === "idle") {
        pendingAppTickets = applicationApprovalsPending;
        pendingGlobalTickets = globalApprovalsPending;
        concatLists(); 
      }
    }, [applicationApprovalsPending, globalApprovalsPending]);

    
    function concatLists(){
        let list = pendingGlobalTickets.concat(pendingAppTickets); 
        list.sort((a : IApprovalReqItem, b: IApprovalReqItem) => new Date(a.requestedDate).getTime() - new Date(b.requestedDate).getTime()); 
        setTickets(list); 
    }

    const columnsPending: GridColDef[] = [
      {
        field: "fullName",
        headerName: "Full name",
        description: "Full name of requester",
        sortable: true,
        width: 140,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.firstName || ""} ${params.row.lastName || ""}`,
        renderCell: (params) => {
          return (
            <>
              <span
                title={`${params.row.firstName || ""} ${
                  params.row.lastName || ""
                }`}
              >{`${params.row.firstName || ""} ${
                params.row.lastName || ""
              }`}</span>
            </>
          );
        },
      },
      {
        field: "email",
        headerName: "E-mail",
        width: 230,
        renderCell: (params) => {
          return (
            <>
              <span title={`${params.row.email || ""}`}>{`${
                params.row.email || ""
              } `}</span>
            </>
          );
        },
      },
      {
        field: "country",
        headerName: "Country",
        width: 80,
        renderCell: (params) => {
          let color = textToColor(params.row.requestedRole);
          return (
            <>
              <span title={params.row.countryName}>{params.row.country}</span>
            </>
          );
        },
      },
      {
        field: "company",
        headerName: "Company",
        width: 170,
        renderCell: (params) => {
          return (
            <>
              <span title={params.row.company || "Company"}>
                {params.row.company}
              </span>
            </>
          );
        },
      },
      {
        field: "application",
        headerName: "Application name",
        width: 150,
        renderCell: (params) => {
          return (
            <>
              <span title={`${params.row.applicationName || ""}`}>{`${
                params.row.applicationName || ""
              } `}</span>
            </>
          );
        },
      },
      {
        field: "requestedRole",
        headerName: "Requested role",
        width: 230,
        renderCell: (params) => {
          return (
            <>
              <Tag feType="outlined" title={params.row.requestedRole}>
                {params.row.requestedRole}
              </Tag>
            </>
          );
        },
      },
      {
        field: "requestedDate",
        headerName: "Request date",
        width: 195,
        renderCell: (params) => {
          const formatted = displayDate(params.row.requestedDate);
          return (
            <>
              <span title={formatted}>{formatted}</span>
            </>
          );
        },
      },
      
      {
        field: "action",
        headerName: "More action",
        width: 250,
        sortable: false,
        renderCell: (params) => {
          const onApprove = (e: any) => {
            e.stopPropagation();
            setTicket(params.row); 
            setDialogueType("Approve"); 
            setIsOpen(true);
            // handleApproval("Approved", params.row);
          };
          const onDecline = (e: any) => {
            e.stopPropagation();
            setTicket(params.row); 
            setDialogueType("Decline"); 
            setIsOpen(true);
           // handleApproval("Denied", params.row);
          };
  
          return (
            <>
              <Button feSize="sm" value="Approve" onClick={onApprove}>
                Approve
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                feType="secondary"
                value="Denied"
                feSize="sm"
                onClick={onDecline}
              >
                Decline
              </Button>
            </>
          );
        },
      },
    ];
  
 
  
    return (
      <><div style={{ height: "calc(100vh - 275px)", width: "100%" }} className="datagrid">
                  <DataGrid
                    rows={tickets}
                    columns={columnsPending}
                    sx={{ display: (loadingstatusApp === "idle" && loadingstatusGlobal === "idle") ? "flex" : "none",fontFamily: "SKFSans, Arial, sans-serif", fontSize: "12px"  }}
                    autoPageSize={true}
                    getRowId={(row) => row.requestId}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "requestedDate", sort: "desc" }],
                      },
                    }}
                    paginationMode="client"
                  />
                  <div className="loader">
                    <Loader hidden={(loadingstatusApp === "idle" && loadingstatusGlobal === "idle")} />
                  </div>
          </div>
          <ApprovalDialog 
            approverUserId={auth.idTokenClaims.sub} 
            ticket={ticket} 
            dialogueType={dialogueType} 
            isOpen={isOpen} 
            closeCallback={() => {setIsOpen(false);}} 
            reloadCallback={loadTickets} />
        </>
    );
  }
  