import {
  Button,
  Dialog,
  Flexbox,
  Loader,
  Text,
  Spacer,
  Tag,
  TextAreaField,
  TextField,
} from "@skf-internal/ui-components-react";
import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { attachUserToGroup } from "../group-users/groupUsersAPI";
import { createGlobalGroup } from "./globalGroupsAPI";

import {
  ISearchUser,
  resetSearch,
  searchUserByEmailAsync,
  selectSearchedUser,
  selectStatus,
} from "../group-users/groupUserSlice";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

export interface IAddGroupProps {
  auth: any;
}

export default function AddGroup(props: IAddGroupProps) {
  const { auth } = props;
  const status = useAppSelector(selectStatus);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isDebounced, setIsDebounced] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [showField, setShowField] = useState(true);
  const [descHint, setDescHint] = useState("50 max");
  const [descError, setDescError] = useState<"error" | undefined>();
  const [nameError, setNameError] = useState<"error" | undefined>();
  const [nameHint, setNameHint] = useState("50 max");

  const DEBOUNCE_DELAY = 750;
  const searchUser: ISearchUser[] = useAppSelector(selectSearchedUser);
  const [newGroup, setNewGroup] = useState({
    globalGroupName: "",
    globalGroupDescription: "",
  });
  const [displaySearchedUser, setDisplaySearchedUser] = useState("");

  const [infoMessage, setInfoMessage] = useState("");

  const [selectedUsers, setSelectedUsers] = useState<ISearchUser[]>([]);
  const [searchhint, setSearchhint] = useState("");
  const [foundUser, setFoundUser] = useState<ISearchUser>(Object);
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [isSuperAdmin, setSuperAdmin] = useState(false);

  const activateCreateButton = () => {
    setDisable(
      !(
        groupName.length > 0 &&
        !nameError &&
        description.length > 0 &&
        !descError
      )
    );
  };

  useEffect(() => {
    if (auth?.idTokenClaims?.roles) {
      if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
        setSuperAdmin(true);
      }
    }
  }, []);
  useEffect(() => {
    /* if (groupName.indexOf(" ") > -1){
          setNameHint("You cant use whitespace in group name");
          setNameError("error"); 
      } else {
        setNameHint(""); 
        setNameError(undefined); 
      }*/
    activateCreateButton();
  }, [groupName]);

  useEffect(() => {
    activateCreateButton();
  }, [description]);

  const selectUser = (user: ISearchUser) => {
    setSelectedUsers((selectedUsers) => [...selectedUsers, user]);

    setTimeout(() => {
      setDisplaySearchedUser("");
      setEmail("");
      setInfoMessage("");
      setShowField(false);
    }, 250);
  };
  const removeUserFromSelection = (user: ISearchUser) => {
    const temp = [...selectedUsers];
    const index = temp.indexOf(user, 0);

    if (index > -1) {
      temp.splice(index, 1);
      setSelectedUsers(temp);
    }
    if (temp.length == 0) {
      setShowField(true);
    }
  };

  const create = () => {
    let nGroup = createGlobalGroup({
      GroupName: groupName,
      GroupDescription: description,
    }).then((res) => {
      createUsers(res.data.groupName);
    });
  };
  const createUsers = (groupId: string) => {
    if (groupId != undefined && groupId.length > 0) {
      if (selectedUsers.length > 0) {
        let result: string = "";
        selectedUsers.map((user: ISearchUser) => {
          attachUserToGroup({
            userProfileId: user.id,
            group: groupId,
          })
            .then((res) => {
              setInfoMessage("User(s) successfully created!");
              result +=
                "User " +
                user.email +
                " connected to group " +
                newGroup.globalGroupName +
                " \n\r";
            })
            .catch((error) => {
              if (error.response) {
                setInfoMessage(error.response.data);
                result +=
                  "User " +
                  user.email +
                  " NOT connected to group " +
                  newGroup.globalGroupName +
                  error.response.data +
                  " \n\r";
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                setTimeout(() => {
                  setInfoMessage("");
                }, 2500);
              }
            });
        });
      }
    }
    setTimeout(() => {
      setIsOpen(false);
      setInfoMessage("");
    }, 1000);
    navigate(0);
  };

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((query: string) => {
        dispatch(searchUserByEmailAsync({ email: query }));
      }, DEBOUNCE_DELAY),
    []
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  useEffect(() => {
    setDisplaySearchedUser("");
    setEmail("");
    setSelectedUsers([]);
    setInfoMessage("");
    setShowField(true);
    setDescError(undefined);
    setGroupName("");
    setDescription("");
    activateCreateButton();
  }, [isOpen]);

  useEffect(() => {
    if (searchUser[0]) {
      setSearchhint("");
      const user = `${searchUser[0].givenName} ${searchUser[0].surName}, ${searchUser[0].companyName}`;
      /*setDisplaySearchedUser(user);
        setFoundUser(searchUser[0]);
        setInfoMessage("");*/
      selectUser(searchUser[0]);
      activateCreateButton();
    } else {
      setSearchhint("No user found with that email");
    }
  }, [searchUser]);

  const handleUserInputChange = (value: string) => {
    setSearchhint("");
    setEmail(value);
    debouncedChangeHandler(value);
    setIsDebounced(true);
  };

  const isButtonDisabled = () => {
    let res: boolean | undefined;
    res = false;
    if (disable) {
      res = true;
    }
    return res;
  };

  return (
    <div>
      <Button onClick={() => setIsOpen(true)} disabled={!isSuperAdmin}>
        New group
      </Button>
      <Dialog {...props} feInterruptive feTitle="Create group" open={isOpen}>
        <TextField
          feLabel="Group name"
          required
          onChange={(_, value) => {
            setGroupName(value);
            activateCreateButton();
          }}
          feHint={nameHint}
          feSeverity={nameError}
        />
        <Spacer />
        {infoMessage !== "" && (
          <>
            <h6>{infoMessage}</h6>
            <Spacer />
          </>
        )}
        <div className="addUsersToCreateGroup">
          <Text>Attach users</Text>
          {showField && (
            <TextField
              feLabel=""
              onChange={(_: any, value: any) => {
                handleUserInputChange(value);
              }}
              feHint={searchhint}
              value={email}
            />
          )}

          <>
            {status === "loading" ? (
              <Loader />
            ) : (
              <Text>
                {/* {JSON.stringify(serachUser)} */}
                {displaySearchedUser !== "" && (
                  <Text onClick={() => selectUser(foundUser)}>
                    {displaySearchedUser}
                  </Text>
                )}
              </Text>
            )}
          </>

          {selectedUsers.length > 0 && (
            <>
              <Spacer feSpacing="xs" />
              <div id="tagfield" onClick={() => setShowField(true)}>
                {" "}
                {selectedUsers.map((user: ISearchUser) => {
                  return (
                    <Tag
                      feClosable={{
                        onClick: () => removeUserFromSelection(user),
                      }}
                    >
                      {user.email}
                    </Tag>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <Spacer feSpacing="sm" />
        <TextAreaField
          feLabel="Description"
          feHint={descHint}
          required
          feSeverity={descError}
          onChange={(event: any) => {
            let text = event.target.value;
            setDescError(undefined);
            if (text !== undefined) {
              setDescHint(text.length + " / 50 max");
              if (text.length > 50) {
                setDescError("error");
              }
            } else {
              setDescHint("50 max");
            }
            setDescription(text);
            activateCreateButton();
          }}
        />
        <Flexbox feGap="md" feJustifyContent="flex-end">
          <Button feType="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => create()} disabled={isButtonDisabled()}>
            Confirm
          </Button>
        </Flexbox>
      </Dialog>
    </div>
  );
}
