import axios from "axios";
import { fetchAccessToken } from "../common/Common";

//axios.defaults.headers.common["x-functions-key"] =
//  process.env.REACT_APP_AUTH_TOKEN || "";

export async function fetchGroupUsers(groupId: string, adminId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/admin/${adminId}/groups?groupName=${groupId}`
  );
}

export async function searchUserByEmail(email: string) {
  let accessToken = await fetchAccessToken();
  const payload = {
    type: "EMAIL",
    data: [email],
  };
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/skfsso/findusers`,
    payload
  );
}
export async function attachUserToGroup(payload: {
  userProfileId: string;
  group: string;
}) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/upr/appsuite/groups`,
    payload
  );
}

export async function deattachUserFromGroup(payload: {
  userProfileId: string;
  group: string;
}) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/upr/appsuite/groups/detach`,
    payload
  );
}
