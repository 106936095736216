import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableRow } from "@skf-internal/ui-components-react";
import { RootState } from "../../app/store";
import {
  fetchGlobalRoles,
  fetchAllGlobalRoles,
  fetchCollectionFromRole,
} from "./globalRolesAPI";
import { IUserData } from "./globalRoleUsersSlice";

export interface IGlobalRole {
  data: IGlobalRoleData[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}

export interface IGlobalRoleData {
  roleId: number;
  roleName: string;
  roleDisplayName: string;
  totalUsers: number;
  roleDescription: string;
  isAdmin: boolean;
}
export interface IGlobalRoleCollections {
  roleCollections: IGlobalRoleCollection[];
}
export interface IGlobalRoleCollection {
  roleCollectionId: number;
  roleCollectionName: string;
  roles: IGlobalRoleData[];
  userProfiles: ISearchUser[];
}

export interface GlobalRoleState {
  roles: IGlobalRole;
  rolelist: IGlobalRoleData[];
  searchedUser: ISearchUser[] | [];
  roleCollections: IGlobalRoleCollection[];
  status: "idle" | "loading" | "failed";
}

export interface ISearchUser {
  id: string;
  givenName: string;
  surName: string;
  userName: string;
  companyName: string;
  city: string;
  email: string;
}

export interface IFetchRoles {
  userId: string;
  level: string;
  page: number;
  quickFilter: string;
}
export interface IFetchRoleCollection {
  roleId: number;
}
const initialState: GlobalRoleState = {
  roles: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  rolelist: [],
  roleCollections: [],
  searchedUser: [],
  status: "idle",
};

export const globalRolesAsync = createAsyncThunk(
  "fetchGlobalRoles",
  async (fetchRoles: IFetchRoles) => {
    const response = await fetchGlobalRoles(fetchRoles.userId);
    return response.data;
  }
);
export const allGlobalRolesAsync = createAsyncThunk(
  "fetchAllGlobalRoles",
  async (fetchRoles: IFetchRoles) => {
    const response = await fetchAllGlobalRoles(
      fetchRoles.userId,
      fetchRoles.level,
      fetchRoles.page,
      fetchRoles.quickFilter
    );
    return response.data;
  }
);
export const globalRoleCollectionAsync = createAsyncThunk(
  "fetchGlobalRoleCollection",
  async (collection: IFetchRoleCollection) => {
    const response = await fetchCollectionFromRole(collection.roleId);
    return response.data;
  }
);
export const globalRolesSlice = createSlice({
  name: "globalRoles",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.roles = action.payload;
    },
    resetSearch: (state, action: PayloadAction<any>) => {
      state.searchedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(globalRolesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(globalRolesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.rolelist = action.payload.roles.map((item: IGlobalRoleData) => {
          return {
            cells: [
              {
                children: item.roleName,
              },
              {
                children: item.totalUsers,
              },
              {
                children: item.roleDescription,
              },
            ],
          };
        });
      })
      .addCase(globalRolesAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(allGlobalRolesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allGlobalRolesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.roles = action.payload;
      })
      .addCase(allGlobalRolesAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(globalRoleCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(globalRoleCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.roleCollections = action.payload.roleCollections;
      })
      .addCase(globalRoleCollectionAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setAuth, resetSearch } = globalRolesSlice.actions;

export const selectStatus = (state: RootState) => state.globalRoles.status;
export const selectGlobalRoles = (state: RootState) => state.globalRoles.roles;
export const selectRoleList = (state: RootState) => state.globalRoles.rolelist;
export const selectRoleCollection = (state: RootState) =>
  state.globalRoles.roleCollections;

export const selectSearchedUser = (state: RootState) =>
  state.globalRoles.searchedUser;

export default globalRolesSlice.reducer;
