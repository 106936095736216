import axios from "axios";

//axios.defaults.headers.common["x-functions-key"] =
//  process.env.REACT_APP_AUTH_TOKEN || "";

export function fetchAllCountries() {
  axios.defaults.headers.common["x-functions-key"] =`${process.env.REACT_APP_CTS_KEY}`;
  
 
  return axios.get(
    `${process.env.REACT_APP_CTS_ENDPOINT}/api/v1/countrymanagement/countries`
  );
  

}