import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  fetchApplicationApprovalRequests,
  fetchGlobalRoleApprovalRequests,
} from "./approvalsAPI";

export interface IApprovals {
  applicationPending: [];
  applicationResolved: [];
  globalRolePending: [];
  globalRoleResolved: [];
  statusAppTicket: "idle" | "loading" | "failed";
  statusGlobalTicket: "idle" | "loading" | "failed";
}
export interface IApprovalReqItem {
  requestId: string;
  applicationId: string;
  applicationName: string; 
  approverId: string;
  approverUserName: string;
  approverUsername: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  company: string;
  address: string;
  requestedDate: string;
  requestedRole: string;
  resolvedDate:string;
  status: string;
  reason: any;
  groupName: string;
}
interface IFetchApplicationApproval {
  userId: string;
}

interface IFetchGlobalRoleApproval {
  userId: string;
}

const initialState: IApprovals = {
  applicationPending: [],
  applicationResolved: [],
  globalRolePending: [],
  globalRoleResolved: [],
  statusAppTicket: "idle",
  statusGlobalTicket: "idle"
};

export const approvalsApplicationPendingAsync = createAsyncThunk(
  "fetchApprovalsPendingApplication",
  async (param: IFetchApplicationApproval) => {
    const response = await fetchApplicationApprovalRequests(param.userId, "Pending");
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const approvalsApplicationResolvedAsync = createAsyncThunk(
  "fetchApprovalsResolvedApplication",
  async (param: IFetchApplicationApproval) => {
    const response = await fetchApplicationApprovalRequests(param.userId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const approvalsGlobalRolePendingAsync = createAsyncThunk(
  "fetchApprovalsPendingGlobalRole",
  async (param: IFetchGlobalRoleApproval) => {
    const response = await fetchGlobalRoleApprovalRequests(param.userId, "Pending");
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const approvalsGlobalRoleResolvedAsync = createAsyncThunk(
  "fetchApprovalsResolvedGlobalRole",
  async (param: IFetchGlobalRoleApproval) => {
    const response = await fetchGlobalRoleApprovalRequests(param.userId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const approvalsSlice = createSlice({
  name: "approvals",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(approvalsApplicationPendingAsync.pending, (state) => {
        state.statusAppTicket = "loading";
      })
      .addCase(approvalsApplicationPendingAsync.fulfilled, (state, action) => {
        state.statusAppTicket = "idle";
        state.applicationPending = action.payload;
      })
      .addCase(approvalsApplicationPendingAsync.rejected, (state) => {
        state.statusAppTicket = "failed";
      })
      .addCase(approvalsApplicationResolvedAsync.pending, (state) => {
        state.statusAppTicket = "loading";
      })
      .addCase(approvalsApplicationResolvedAsync.fulfilled, (state, action) => {
        state.statusAppTicket = "idle";
        state.applicationResolved = action.payload;
      })
      .addCase(approvalsApplicationResolvedAsync.rejected, (state) => {
        state.statusAppTicket = "failed";
      })
      .addCase(approvalsGlobalRolePendingAsync.pending, (state) => {
        state.statusGlobalTicket = "loading";
      })
      .addCase(approvalsGlobalRolePendingAsync.fulfilled, (state, action) => {
        state.statusGlobalTicket = "idle";
        state.globalRolePending = action.payload;
      })
      .addCase(approvalsGlobalRolePendingAsync.rejected, (state) => {
        state.statusGlobalTicket = "failed";
      })     
      .addCase(approvalsGlobalRoleResolvedAsync.pending, (state) => {
        state.statusGlobalTicket = "loading";
      }) 
      .addCase(approvalsGlobalRoleResolvedAsync.fulfilled, (state, action) => {
        state.statusGlobalTicket = "idle";
        state.globalRoleResolved = action.payload;
      }) 
      .addCase(approvalsGlobalRoleResolvedAsync.rejected, (state) => {
        state.statusGlobalTicket = "failed";
      });
  },
});

export const selectApplicationPendingRequests = (state: RootState) =>
  state.approvals.applicationPending;
export const selectApplicationResolvedRequests = (state: RootState) =>
  state.approvals.applicationResolved;
export const selectGlobalRolesPendingRequests = (state: RootState) =>
  state.approvals.globalRolePending;
export const selectGlobalRolesResolvedRequests = (state: RootState) =>
  state.approvals.globalRoleResolved;

export const statusAppTicket = (state: RootState) =>
  state.approvals.statusAppTicket;

  export const statusGlobalTicket = (state: RootState) =>
  state.approvals.statusGlobalTicket;

export default approvalsSlice.reducer;
