import React, { useState } from "react";
import { Button, Link } from "@skf-internal/ui-components-react";
import { useMsal } from "@azure/msal-react";

import LogoutComponent from "./LogoutComponent";



export interface ILogoutProps {
  asLink: boolean; 
}
/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = (props: ILogoutProps) => {
  const { instance } = useMsal();
  const [logout, setLogout] = useState(false); 
  
  return (<>
   {props.asLink && <Link
  as="a"
  href="#"
  style={{padding:"10px"}}
  onClick={() => {setLogout(true)}}
  className="usermenuitem"
>
Log out
</Link>} 

    {logout && <LogoutComponent instance={instance} />}
    
    </>
  )
};
