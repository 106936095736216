import {
  Link,
  Spacer,
} from "@skf-internal/ui-components-react";
import { useNavigate } from "react-router-dom";

export interface IUserTicketApproverGroupLinkProps {
  groups: string;
}

export default function UserTicketApproverGroupLink(
  props: IUserTicketApproverGroupLinkProps
) {
  const navigate = useNavigate();

  let groups: any[] = [];
  let out: any = "";
  if (props.groups != null) {
    groups = props.groups.split(",");
    out = groups.map((group) => {
      if (group != null && group != "") {
        group = group.trim();
        return (
          <>
            <Link
              as="button"
              onClick={() => {
                gotoRoute(group);
              }}
              type="button"
            >
              {group}
            </Link>
            <Spacer feSpacing="xs" />
          </>
        );
      }
    });
  }

  const gotoRoute = (route: any) => {
    navigate(`/group-users/${route}`);
  };

  return <>{out}</>;
}
