import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableRow } from "@skf-internal/ui-components-react";
import { RootState } from "../../app/store";
import {
  fetchGlobalRoleUserCountries,
  fetchGlobalRoleUserDomains,
  fetchGlobalRoleUsersPost,
} from "./globalRoleUsersAPI";
import { IDateFilterValue } from "../common/DateFilter";
import { ICountryRecord, IDomainRecord } from "../common/RoleUsersFilterTags";

export interface IGlobalRoleUsers {
  data: IUserData[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}
export interface IGlobalRoleUserDomains {
  data: IDomainRecord[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}
export interface IGlobalRoleUserCountries {
  data: ICountryRecord[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}
export interface IUserData {
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  countryIso: string;
  countryName: string;
  company: string;
  roles: IUserRole[];
  userId: string;
  userProfileId: string;
  createdAt: string;
  lastLogginAt: string;
}
export interface IUserRole {
  roleId: number;
  roleName: string;
  isEditable: boolean;
}
export interface GlobalRoleUsersState {
  users: IGlobalRoleUsers;
  domains: IGlobalRoleUserDomains;
  countries: IGlobalRoleUserCountries; 
  status: "idle" | "loading" | "failed";
  domainstatus: "idle" | "loading" | "failed";
  countrystatus: "idle" | "loading" | "failed";
}

export interface IFetchUsers {
  roleId: string;
  adminId: string;
  level: string;
  queryPage: number;
  itemsPerPage: number;
  sortByField: string;
  order: string;
  quickFilter: string;
  excludeEmailDomain: string;
  lastLoginFilter: IDateFilterValue | undefined;
  createdDateFilter: IDateFilterValue | undefined;
  filterByDomains: string[];
  filterByCountries: string[];
}
export interface IFetchDomains {
  roleId: string;
  adminId: string;
  level: string;
  queryPage: number;
  itemsPerPage: number;
  quickFilter: string;
  excludeEmailDomain: string;
  lastLoginFilter: IDateFilterValue | undefined;
  createdDateFilter: IDateFilterValue | undefined;
}
export interface IFetchCountries {
  roleId: string;
  adminId: string;
  level: string;
  queryPage: number;
  itemsPerPage: number;
  quickFilter: string;
  excludeEmailDomain: string;
  lastLoginFilter: IDateFilterValue | undefined;
  createdDateFilter: IDateFilterValue | undefined;
}

const initialState: GlobalRoleUsersState = {
  users: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  domains: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  countries: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  status: "idle",
  domainstatus: "idle",
  countrystatus: "idle"
};

export const globalRoleUsersAsync = createAsyncThunk(
  "fetchGlobalRoleUsers",
  async (fetchUsers: IFetchUsers) => {
    const response = await fetchGlobalRoleUsersPost(fetchUsers);
    return response.data;
  }
);
export const domainsAsync = createAsyncThunk(
  "fetchGlobalRoleUserDomains",
  async (fetchUsers: IFetchDomains) => {
    const response = await fetchGlobalRoleUserDomains(fetchUsers);
    return response.data;
  }
);
export const countriesAsync = createAsyncThunk(
  "fetchGlobalRoleUserCountries",
  async (fetchCountries: IFetchCountries) => {
    const response = await fetchGlobalRoleUserCountries(fetchCountries);
    return response.data;
  }
);
export const globalRoleUsersSlice = createSlice({
  name: "globalRoles",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSearch: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(globalRoleUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(globalRoleUsersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.users = action.payload;
      })
      .addCase(globalRoleUsersAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(domainsAsync.pending, (state) => {
        state.domainstatus = "loading";
      })
      .addCase(domainsAsync.fulfilled, (state, action) => {
        state.domainstatus = "idle";
        state.domains = action.payload;
      })
      .addCase(domainsAsync.rejected, (state) => {
        state.domainstatus = "failed";
      })
      .addCase(countriesAsync.pending, (state) => {
        state.countrystatus = "loading";
      })
      .addCase(countriesAsync.fulfilled, (state, action) => {
        state.countrystatus = "idle";
        state.countries = action.payload;
      })
      .addCase(countriesAsync.rejected, (state) => {
        state.countrystatus = "failed";
      });
  },
});
export const { resetSearch } = globalRoleUsersSlice.actions;
export const selectStatus = (state: RootState) => state.globalRoleUsers.status;
export const selectGlobalRoleUsers = (state: RootState) =>
  state.globalRoleUsers.users;
export const selectGlobalRoleUserDomains = (state: RootState) =>
  state.globalRoleUsers.domains;
export default globalRoleUsersSlice.reducer;
