import { Link, Spacer } from "@skf-internal/ui-components-react";
import { useNavigate } from "react-router-dom";

export interface IApproverGroupLinkProps {
  groups: string;
}

export default function ApproverGroupLink(props: IApproverGroupLinkProps) {
  const navigate = useNavigate();
  function getFirstGroup() {
    let groups: string[] = props.groups.split(",");
    if (groups.length > 0) {
      return groups[0];
    }
    return undefined;
  }
  const gotoRoute = (route: any) => {
    navigate(`/group-users/${route}`);
  };
  let group = getFirstGroup();

  return (
    <>
      {group && (
        <Link
          as="button"
          onClick={() => {
            gotoRoute(group);
          }}
          type="button"
        >
          {group}
        </Link>
      )}
    </>
  );
}
