import {
  Button,
  Dialog,
  Link,
  Text,
  Spacer,
  Flexbox,
} from "@skf-internal/ui-components-react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  /*createGlobalRole, createGlobalRoleTicket,*/ deattachUserFromGroup,
} from "./groupUsersAPI";
import { IUser, selectStatus } from "./groupUserSlice";
import { useNavigate } from "react-router-dom";

export interface IRemoveUserProps {
  auth: any;
  group: any;
  user: IUser;
  canRemoveUsers: boolean;
}

export default function RemoveUser(props: IRemoveUserProps) {
  const { group, user, canRemoveUsers } = props;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const removeUserFromGroup = () => {
    deattachUserFromGroup({
      userProfileId: user.userProfileId,
      group: group.groupName,
    })
      .then((res) => {
        setTimeout(() => {
          setIsOpen(false);
        }, 1000);
        navigate(0);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <div>
      {canRemoveUsers && (
        <Link
          as="button"
          feIcon={{ feIcon: "closeFault", position: "right" }}
          onClick={() => setIsOpen(true)}
          title="Remove group member"
        >
          {" "}
        </Link>
      )}
      <Dialog
        {...props}
        feInterruptive
        feTitle="Remove group member"
        open={isOpen}
        cssWidth={"40rem"}
        cssHeight={"22rem"}
      >
        <div className="globalroleadminremove">
          <Text>
            You are currently removing user {user.email} from group "
            {group.groupName}".
          </Text>
          <Spacer feSpacing="md" />
          <Flexbox
            feGap="md"
            feJustifyContent="flex-end"
            className="modalButtons"
          >
            <Button feType="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => removeUserFromGroup()}>Confirm</Button>
          </Flexbox>
        </div>
      </Dialog>
    </div>
  );
}
