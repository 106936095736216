import { Link } from "@skf-internal/ui-components-react";
import { useNavigate } from "react-router-dom";

export interface IViewGlobalRoleUsersLinkProps {
  auth: any;
  roleId: number;
  roleName: string;
}

export default function ViewGlobalRoleLink(
  props: IViewGlobalRoleUsersLinkProps
) {
  const navigate = useNavigate();
  const gotoRoute = (route: any, roleName: any) => {
    navigate(route, { state: { roleName: roleName } });
  };

  return (
    <div>
      <>
        <span className="tbUsers">
          <span className="userlink">
            <Link
              as="a"
              href="/"
              onClick={() =>
                gotoRoute(`/globalroles/${props.roleId}`, props.roleName)
              }
              feIcon={{
                feIcon: "doubleChevronRight",
                position: "left",
              }}
            >
              View users
            </Link>
          </span>
        </span>
      </>
    </div>
  );
}
