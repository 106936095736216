import { useIsAuthenticated } from "@azure/msal-react";

import { useAppSelector } from "../../app/hooks";

import { selectAllowed, selectAuth } from "../sso/ssoSlice";
import {Header } from "@skf-internal/ui-components-react"
import Login from "./Login"; 
import User from "./User";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { onValue, ref , } from "firebase/database";
import CustomAlert from "./CustomAlert";      

export interface IPageLayoutProps {
  onLogin: Function;
  children: any;
}

export default function PageLayout(props: IPageLayoutProps): JSX.Element {
  const isAuthenticated = useIsAuthenticated();
  const tesseractAllowed = useAppSelector(selectAllowed);
  const [showBanner , setShowBanner] = useState(false);
  const [bannerMessage , setBannerMessage]=useState('');

  useEffect(()=> {

    try {
      onValue(ref (db , '/notification/'+process.env.REACT_APP_FIREBASE_ENV) , snapshot=> {
        const data = snapshot.val()
        if(data?.show){
        setShowBanner(data.show);
        setBannerMessage(data.message);
        } else {
          setShowBanner(false);
          setBannerMessage("");
        }
      } )
    } catch (error) {
      console.log(error);
    }
  } , [] )

  const isAuth = () => {

      return isAuthenticated && tesseractAllowed === "allowed";
  }

  const {idTokenClaims} = useAppSelector(selectAuth);
  let content; 
  if (isAuth()){
     content = props.children; 
  } else 
  {
    content = <Login onLogin={props.onLogin} />
  }

  return (
    <>
<Header
  feIntro={{
    baseUrl: '/',
    name: `Tesseract - ${process.env.REACT_APP_TESSERACT_ENV}`
  }}
/> 
<>  
 <CustomAlert message={bannerMessage} visible={showBanner} childrenPropString="warning"/>
      {isAuth() ? (<User auth={idTokenClaims}/>):'' }      
        {content}
       </>
    </>
  );
}
