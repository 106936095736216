/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const policies = {
    names: {
        signUpSignIn: "B2C_1A_SKFSSO-DEV-SIGNUP_SIGNIN_TESSERACT",
        editProfile: "B2C_1A_SKFSSO-DEV-PROFILEEDIT"
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_SSO_URL_SIGN_UP
        },
        editProfile: {
            authority: process.env.REACT_APP_SSO_URL_EDIT_PROFILE
        }
    },
    authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN
}