import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchAllCountries } from "./taxonomyAPI";



export interface ICountries {
  allCountries: ICountry[];
  
}
export interface ICountry {
  countryName: string; 
  alpha3IsoCode: string; 
}


export interface CountryState {
  countries: ICountry[];
  status: "idle" | "loading" | "failed";
}

const initialState: CountryState = {
  countries:[], 
  status: "idle",
};

export const countriesAsync = createAsyncThunk(
  "fetchAllCountries",
  async () => {
    const response = await fetchAllCountries();
    return response;
  }
);

export const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.countries = action.payload;
    
    }
  },

  
  extraReducers: (builder) => {
    builder
      .addCase(countriesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(countriesAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.countries = action.payload.data;  
      })
      .addCase(countriesAsync.rejected, (state) => {
        state.status = "failed";
      })
    
  },
});

//export const { setAuth } = taxonomySlice.actions;

export const selectStatus = (state: RootState) => state.taxonomy.status;
export const selectCountries = (state: RootState) => state.taxonomy.countries;

export default taxonomySlice.reducer;
