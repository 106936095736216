import {
  Flexbox,
  Grid,
  GridItem,
  Loader,
  Pagination,
  Select,
  Table,
  TableRow,
  Tag,
  TextProps,
} from "@skf-internal/ui-components-react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../sso/ssoSlice";
import {
  IFetchApplicationRoles,
  IFetchGlobalRoles,
  IRole,
  IRoleData,
  ISearchUser,
  listApplicationRolesByUserAsync,
  listGlobalRolesByUserAsync,
  selectUserAppRoles,
  selectUserAppRolesStatus,
  selectUserRoles,
  selectUserRolesStatus,
} from "./userSlice";
import RemoveRoleFromUser from "./RemoveRoleFromUser";
import RemoveApplicationRoleFromUser from "./RemoveApplicationRoleFromUser";

export interface IUserRolesProps {
  user: ISearchUser;
}

export default function UserRoles(props: IUserRolesProps) {
  const auth = useAppSelector(selectAuth);
  const [activeTab, setActiveTab] = useState(1);
  const globalRoles = useAppSelector(selectUserRoles);
  const applicationRoles = useAppSelector(selectUserAppRoles);
  const globalRolesStatus = useAppSelector(selectUserRolesStatus);
  const applicationRolesStatus = useAppSelector(selectUserAppRolesStatus);
  const dispatch = useAppDispatch();
  const initial: TableRow[] = [];
  const [rolesRows, setRolesRows] = useState(initial);
  const [page, setPage] = useState(1);
  let reloadToggler = false;
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const [show, setShow] = useState("all");
  let roles: [] | IRoleData[] = [];

  useEffect(() => {
    if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
      setSuperAdmin(true);
    }
  }, []);

  useEffect(() => {
    console.log("New user!");
    if (page == 1) {
      loadRoles();
    }
    setPage(1);
  }, [props.user]);
  useEffect(() => {
    loadRoles();
  }, [page, reloadToggler]);

  function loadRoles() {
    if (props?.user?.id != undefined && auth?.idTokenClaims) {
      console.log("Callin API");

      let fetchRoles: IFetchGlobalRoles = {
        userId: props.user.id,
        adminId: auth?.idTokenClaims.sub,
        page: page,
      };
      dispatch(listGlobalRolesByUserAsync(fetchRoles));
      let fetchApplicationRoles: IFetchApplicationRoles = {
        userId: props.user.id,
        adminId: auth?.idTokenClaims.sub,
        page: page,
      };
      dispatch(listApplicationRolesByUserAsync(fetchApplicationRoles));
    }
  }
  useEffect(() => {
    console.log("Creating rows");
    createRolesRows();
  }, [globalRoles]);

  useEffect(() => {
    console.log("Creating rows");
    if (globalRolesStatus == "idle" && applicationRolesStatus == "idle") {
      roles = (globalRoles.data as any[]).concat(applicationRoles.data);
      console.log(
        "Creating rows" +
          globalRoles.data.length +
          " + " +
          applicationRoles.data.length +
          "=" +
          roles.length
      );
      createRolesRows();
    }
  }, [globalRoles, applicationRoles]);

  useEffect(() => {
    console.log("Creating rows");
    if (show == "all") {
      roles = (globalRoles.data as any[]).concat(applicationRoles.data);
    } else if (show == "global") {
      roles = globalRoles.data;
    } else {
      roles = applicationRoles.data;
    }
    createRolesRows();
  }, [show]);
  const createRolesRows = () => {
    if (roles != undefined && roles.length > 0) {
      let slice: IRoleData[] = roles;

      setRolesRows(
        slice.map((item: IRoleData, index) => ({
          cells: [
            {
              children: (
                <>
                  {(item.editable || isSuperAdmin) &&
                    ((item.applicationId == null && (
                      <RemoveRoleFromUser
                        auth={auth}
                        user={props.user}
                        role={item}
                        callback={() => {
                          loadRoles();
                        }}
                      />
                    )) ||
                      (item.applicationId != null && (
                        <RemoveApplicationRoleFromUser
                          auth={auth}
                          user={props.user}
                          role={item}
                          callback={() => {
                            loadRoles();
                          }}
                        />
                      )))}
                  {!item.editable && !isSuperAdmin && (
                    <Tag feType="outlined">{item.roleName}</Tag>
                  )}
                </>
              ),
            },
            {
              children: (
                <>{item.applicationName ? item.applicationName : " "}</>
              ),
            },
            {
              children: <>{item.roleDescription ? item.roleDescription : ""}</>,
            },
          ],
        }))
      );
    } else {
      setRolesRows([]);
    }
  };

  const sortableHead: TableRow[] = [
    {
      cells: [
        { children: "Roles", scope: "col" },
        { children: "Application", scope: "col" },
        {
          children: "Description",
          scope: "col",
        },
      ],
    },
  ];

  return (
    <>
      <div>
        <Select
          className="show_userdetail"
          defaultValue="all"
          style={{ width: "250px" }}
          feItems={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Global roles",
              value: "global",
            },
            {
              label: "Application roles",
              value: "application",
            },
          ]}
          feLabel="Show:"
          multiple={false}
          onChange={setShow}
          value={show}
        />

        <div
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            padding: "0.1rem",
            textAlign: "left",
            height: "calc(100vh - 283px)",
            overflow: "auto",
          }}
        >
          {" "}
          {globalRolesStatus == "loading" && (
            <div className="userDetailLoader">
              {" "}
              <Loader />{" "}
            </div>
          )}
          {globalRolesStatus == "idle" && rolesRows.length > 0 ? (
            <>
              <Table feBody={rolesRows} feHead={sortableHead} />
              {globalRoles.total > globalRoles.page_size && (
                <Flexbox feJustifyContent="flex-end">
                  <Pagination
                    feControls={{
                      next: {
                        "aria-label": "Next page",
                      },
                      prev: {
                        "aria-label": "Previous page",
                      },
                    }}
                    feItemsPerPage={globalRoles.page_size}
                    feOnPageChange={(page: number) => {
                      setPage(page);
                    }}
                    fePage={globalRoles.page}
                    feTotal={globalRoles.total}
                  />
                </Flexbox>
              )}
            </>
          ) : (
            globalRolesStatus == "idle" && <p>No global roles found...</p>
          )}
        </div>
      </div>
    </>
  );
}
