import {
  Button,
  Link,
  Loader,
  Table,
  TableRow,
  useToast,
} from "@skf-internal/ui-components-react";
import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  IRoleItem,
  applicationRoleAsync,
  selectRoles,
  selectUserStatus,
} from "./applicationDetailsSlice";
import AttachApplicationRole from "./AttachApplicationRole";
import { useNavigate } from "react-router-dom";
import ApproverGroupLink from "./ApproverGroupLink";

export interface IRolesProps {
  auth: any;
  appId: string;
  setRole: (roleId: number) => void;
}
export default function Roles(props: IRolesProps) {
  const { auth, appId, setRole } = props;
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectRoles);
  const status = useAppSelector(selectUserStatus);
  const initial: TableRow[] = [];
  const [rows, setRows] = useState(initial);
  const { toasts, addToast, removeToast } = useToast();
  const navigate = useNavigate();
  const fetchRoles = () => {
    dispatch(applicationRoleAsync({ userId: auth.idTokenClaims.sub, appId }));
  };
  const gotoRoute = (route: any) => {
    navigate(route);
  };
  useEffect(() => {
    let roleRows = roles.map((item: IRoleItem) => ({
      cells: [
        {
          children: item.roleName,
        },
        {
          children: item.totalUsers,
        },
        {
          children: item.roleDescription,
        },
        {
          children: <ApproverGroupLink groups={item.approverGroup} />,
        },
        {
          children: (
            <AttachApplicationRole
              applicationId={appId}
              auth={auth}
              role={item.roleName}
              fetchRoles={fetchRoles}
            />
          ),
        },
        {
          children: (
            <>
              <span className="tbUsers">
                <span className="userlink">
                  <Link
                    as="button"
                    onClick={() =>
                      gotoRoute(
                        "/app/" + appId + "/roles/" + item.roleId + "/users"
                      )
                    }
                    feIcon={{
                      feIcon: "doubleChevronRight",
                      position: "left",
                    }}
                  >
                    View Users
                  </Link>
                </span>
              </span>
            </>
          ),
        },
      ],
    }));
    setRows(roleRows as TableRow[]);
  }, [roles]);

  const sortableHead: TableRow[] = [
    {
      cells: [
        {
          children: "Application roles",
          scope: "col",
          sortable: {},
          width: "250px",
        },
        {
          children: "Number of users",
          scope: "col",
          sortable: {},
          width: "80px",
        },
        { children: "Description", scope: "col", sortable: {} },
        {
          children: "Approver group",
          scope: "col",
          sortable: {},
          width: "200px",
        },
        { children: "More", scope: "col", sortable: {}, width: "140px" },
        { children: "Selected Role Users", scope: "col", width: "140px" },
      ],
    },
  ];
  useEffect(() => {
    // dispatch(applicationRoleAsync({ userId: auth.idTokenClaims.sub, appId }));
    fetchRoles();
  }, []);

  useEffect(() => {
    if (status === "failed") {
      addToast({
        children: "Something went wrong!! Pleasse try again later",
        feSeverity: "error",
      });
    }
  }, [status]);

  return (
    <div>
      {status === "loading" ? (
        <div style={{ margin: "auto" }}>
          {" "}
          <Loader />{" "}
        </div>
      ) : (
        <Table feBody={rows} feHead={sortableHead} />
      )}
    </div>
  );
}
