import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  searchUserByEmail,
  listGlobalRolesByUser,
  listGlobalRolesTicketsByUser,
  listApplicationRolesTicketsByUser,
  listApplicationRolesByUser,
  reactivateUser,
  searchUser,
} from "./userAPI";
import { IApprovalReqItem } from "../approvals/approvalsSlice";

export interface IUsersState {
  searchedUser: ISearchUser[] | [];
  getUser: ISearchUser[] | [];
  globalRoles: IRole;
  applicationRoles: IRole;
  globalRolesTicket: IApprovalReqItem[] | [];
  applicationRolesTicket: IApprovalReqItem[] | [];
  status: "idle" | "loading" | "failed";
  globalRolesstatus: "idle" | "loading" | "failed";
  applicationRolesstatus: "idle" | "loading" | "failed";
  globalRolesTicketStatus: "idle" | "loading" | "failed";
  applicationRolesTicketStatus: "idle" | "loading" | "failed";
  unmarkedForDelitionByUserStatus: "idle" | "loading" | "failed";
  unmarkedForDelitionLoading: boolean;
}

export interface IUser {
  globalGroupName: string;
  userGlobalGroups: ISearchUser[];
  globalGroupDescription: string;
}
export interface IUsers {
  data: IUser[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}
export interface ISearchUser {
  id: string;
  givenName: string;
  surName: string;
  email: string;
  userName: string;
  companyName: string;
  countryIso: string;
  lastLogonAt: string;
  createdAt: string;
  updatedAt: string;
  globalRoles: string[];
  markedForDeletion: boolean;
  city: string;
  phoneNumber: string;
  marketingNotificationAcceptance: boolean;
  streetAddress: string;
  postalCode: string;
  marketingConsent: string;
  preferredLanguage: string;
  industry: string;
  userSubType: string;
  deletedAt?: string;
}
export interface ISearchUserData {
  emails: string[];
  ids: string[];
}
export interface IFetchGlobalRoles {
  userId: string;
  adminId: string;
  page: number;
}
export interface IFetchApplicationRoles {
  userId: string;
  adminId: string;
  page: number;
}
export interface IFetchRolesTickets {
  userId: string;
  page: number;
}

const initialState: IUsersState = {
  searchedUser: [],
  getUser: [],
  globalRoles: { data: [], page: 0, page_size: 10, total: 0, last_page: 0 },
  applicationRoles: {
    data: [],
    page: 0,
    page_size: 10,
    total: 0,
    last_page: 0,
  },
  globalRolesTicket: [],
  applicationRolesTicket: [],
  status: "idle",
  globalRolesstatus: "idle",
  applicationRolesstatus: "idle",
  globalRolesTicketStatus: "idle",
  applicationRolesTicketStatus: "idle",
  unmarkedForDelitionByUserStatus: "idle",
  unmarkedForDelitionLoading: false,
};

export interface IRole {
  data: IRoleData[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}

export interface IRoleData {
  roleName: string;
  applicationName: string;
  applicationId: string;
  totalUsers: number;
  roleDescription: string;
  editable: boolean;
  roleId: number;
}

export interface markedForDeletionData {
  id: string;
}

export const searchUserByEmailAsync = createAsyncThunk(
  "searchUserByEmail",
  async (search: ISearchUserData) => {
    const response: any = await searchUserByEmail("EMAIL", search.emails);
    return response.data;
  }
);
export const searchUserAsync = createAsyncThunk(
  "searchUser",
  async (query: string) => {
    const response: any = await searchUser(query);
    return response.data;
  }
);
export const searchUserByIDAsync = createAsyncThunk(
  "searchUserByID",
  async (search: ISearchUserData) => {
    const response: any = await searchUserByEmail("ID", search.ids);
    return response.data;
  }
);

export const listGlobalRolesByUserAsync = createAsyncThunk(
  "listGlobalRolesByUser",
  async (data: IFetchGlobalRoles) => {
    const response: any = await listGlobalRolesByUser(
      data.userId,
      data.adminId,
      data.page
    );
    return response.data;
  }
);
export const listApplicationRolesByUserAsync = createAsyncThunk(
  "listApplicationRolesByUser",
  async (data: IFetchApplicationRoles) => {
    const response: any = await listApplicationRolesByUser(
      data.userId,
      data.adminId,
      data.page
    );
    return response.data;
  }
);
export const listGlobalRolesTicketsByUserAsync = createAsyncThunk(
  "listGlobalRolesTicketsByUser",
  async (data: IFetchRolesTickets) => {
    const response: any = await listGlobalRolesTicketsByUser(data.userId);
    return response;
  }
);
export const listApplicationTicketsByUserAsync = createAsyncThunk(
  "listApplicationRolesTicketsByUser",
  async (data: IFetchRolesTickets) => {
    const response: any = await listApplicationRolesTicketsByUser(data.userId);
    return response;
  }
);
export const reactivateUserAsync = createAsyncThunk(
  "reactivateUser",
  async (data: markedForDeletionData) => {
    const response: any = await reactivateUser(data.id);
    return response;
  }
);
export const usersSlice = createSlice({
  name: "users",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSearch: (state, action: PayloadAction<any>) => {
      state.searchedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUserByEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchUserByEmailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.searchedUser = action.payload.usersFound;
      })
      .addCase(searchUserByEmailAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(searchUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.searchedUser = action.payload.usersFound;
      })
      .addCase(searchUserAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(searchUserByIDAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchUserByIDAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.getUser = action.payload.usersFound;
      })
      .addCase(searchUserByIDAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(listGlobalRolesByUserAsync.pending, (state) => {
        state.globalRolesstatus = "loading";
        state.globalRoles = {
          data: [],
          page: 0,
          page_size: 10,
          total: 0,
          last_page: 0,
        };
      })
      .addCase(listGlobalRolesByUserAsync.fulfilled, (state, action) => {
        state.globalRolesstatus = "idle";
        state.globalRoles = action.payload;
      })
      .addCase(listGlobalRolesByUserAsync.rejected, (state) => {
        state.globalRolesstatus = "failed";
      })
      .addCase(listApplicationRolesByUserAsync.pending, (state) => {
        state.applicationRolesstatus = "loading";
        state.applicationRoles = {
          data: [],
          page: 0,
          page_size: 10,
          total: 0,
          last_page: 0,
        };
      })
      .addCase(listApplicationRolesByUserAsync.fulfilled, (state, action) => {
        state.applicationRolesstatus = "idle";
        state.applicationRoles = action.payload;
      })
      .addCase(listApplicationRolesByUserAsync.rejected, (state) => {
        state.applicationRolesstatus = "failed";
      })
      .addCase(listGlobalRolesTicketsByUserAsync.pending, (state) => {
        state.globalRolesTicketStatus = "loading";
        state.globalRolesTicket = [];
      })
      .addCase(listGlobalRolesTicketsByUserAsync.fulfilled, (state, action) => {
        state.globalRolesTicketStatus = "idle";
        state.globalRolesTicket = action.payload.data;
      })
      .addCase(listGlobalRolesTicketsByUserAsync.rejected, (state) => {
        state.globalRolesTicketStatus = "failed";
      })
      .addCase(listApplicationTicketsByUserAsync.pending, (state) => {
        state.applicationRolesTicketStatus = "loading";
        state.applicationRolesTicket = [];
      })
      .addCase(listApplicationTicketsByUserAsync.fulfilled, (state, action) => {
        state.applicationRolesTicketStatus = "idle";
        state.applicationRolesTicket = action.payload.data;
      })
      .addCase(listApplicationTicketsByUserAsync.rejected, (state) => {
        state.applicationRolesTicketStatus = "failed";
      })
      .addCase(reactivateUserAsync.pending, (state) => {
        state.unmarkedForDelitionByUserStatus = "loading";
        state.unmarkedForDelitionLoading = true;
      })
      .addCase(reactivateUserAsync.fulfilled, (state) => {
        state.unmarkedForDelitionByUserStatus = "idle";
        state.unmarkedForDelitionLoading = false;
      })
      .addCase(reactivateUserAsync.rejected, (state) => {
        state.unmarkedForDelitionByUserStatus = "failed";
        state.unmarkedForDelitionLoading = false;
      });
  },
});

export const { resetSearch } = usersSlice.actions;

export const selectStatus = (state: RootState) => state.users.status;
export const selectSearchedUser = (state: RootState) =>
  state.users.searchedUser;

export const selectFetchedUser = (state: RootState) => state.users.getUser;
export const selectUserRoles = (state: RootState) => state.users.globalRoles;
export const selectUserRolesStatus = (state: RootState) =>
  state.users.globalRolesstatus;
export const selectUserAppRoles = (state: RootState) =>
  state.users.applicationRoles;
export const selectUserAppRolesStatus = (state: RootState) =>
  state.users.applicationRolesstatus;
export const selectGlobalRolesTicket = (state: RootState) =>
  state.users.globalRolesTicket;
export const selectGlobalRolesTicketStatus = (state: RootState) =>
  state.users.globalRolesTicketStatus;
export const selectApplicationRolesTicket = (state: RootState) =>
  state.users.applicationRolesTicket;
export const selectApplicationRolesTicketStatus = (state: RootState) =>
  state.users.applicationRolesTicketStatus;
export const selectunmarkedForDelitionByUserStatus = (state: RootState) => {
  return state.users.unmarkedForDelitionByUserStatus;
};
export const selectunmarkedForDelitionLoadingStatus = (state: RootState) => {
  return state.users.unmarkedForDelitionLoading;
};

export default usersSlice.reducer;
