import axios from "axios";
import { fetchAccessToken } from "../common/Common";

//axios.defaults.headers.common["x-functions-key"] =
//  process.env.REACT_APP_AUTH_TOKEN || "";

export async function fetchGlobalRoles(userId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admins/${userId}/globalroles`
  );
}
export async function fetchAllGlobalRoles(
  userId: string,
  level: string,
  page: number,
  quickFilter: string
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v3/tesseract/approver/${userId}/globalroles?quickFilter=${quickFilter}&itemsPerPage=500`
  );
}
export async function fetchCollectionFromRole(roleId: number) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/rolecollection?roleId=${roleId}`
  );
}
export async function createGlobalRole(data: {
  roleName: string;
  roleDescription?: string;
}) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/roles`,
    data
  );
}

export async function createGlobalRoleTicket(payload: {
  userProfileId: string;
  globalRole: string;
  adminId: string;
  reason: string;
}) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v2/tesseract/globalroles/tickets`,
    payload
  );
}
export async function detachUserFromCollection(
  userProfileId: string,
  roleCollectionId: number
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.delete(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/rolecollection/users?roleCollectionId=${roleCollectionId}&userProfileId=${userProfileId}`
  );
}
export async function attachUserToCollection(payload: {
  userProfileId: string;
  roleCollectionId: number;
}) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/rolecollection/users`,
    payload
  );
}
