import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// import counterReducer from "../features/counter/counterSlice";
import ssoReducer from "../features/sso/ssoSlice";
import applicationReducer from "../features/applications/applicationsSlice";
import appliationDetailsReducer from "../features/app-details/applicationDetailsSlice";
import globalRoleReducer from "../features/global-roles/globalRolesSlice";

import globalGroupReducer from "../features/global-groups/globalGroupSlice";
import taxonomyReducer from "../features/global-groups/taxonomySlice";
import groupUsersReducer from "../features/group-users/groupUserSlice";
import approvalsReducer from "../features/approvals/approvalsSlice";
import usersReducers from "../features/users/userSlice";
import graphReducers from "../features/users/graphSlice";
import globalRoleUsersSlice from "../features/global-roles/globalRoleUsersSlice";
import globalRolesAdminSlice from "../features/global-roles-admin/globalRolesAdminSlice";

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    sso: ssoReducer,
    applications: applicationReducer,
    applicationDetails: appliationDetailsReducer,
    globalRoles: globalRoleReducer,
    globalGroups: globalGroupReducer,
    groupUsers: groupUsersReducer,
    approvals: approvalsReducer,
    users: usersReducers,
    taxonomy: taxonomyReducer,
    globalRoleUsers: globalRoleUsersSlice,
    graphs: graphReducers,
    globalRoleAdmin: globalRolesAdminSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
