import {
  Breadcrumbs,
  Flexbox,
  Grid,
  GridItem,
  Spacer,
  Table,
  TableRow,
} from "@skf-internal/ui-components-react";
import { IApplication } from "../applications/Applications";
import { useEffect, useState } from "react";
import {
  IAdminData,
  IFetchAppAdmins,
  applicationAdminsAsync,
  selectAdmins,
} from "./applicationDetailsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import AddAdmin from "./AddAdmin";

export interface IAdminProps {
  auth: any;
  app: IApplication;
  setRole: (roleId: number) => void;
}
export default function Admins(props: IAdminProps) {
  const { auth, app, setRole } = props;
  const admins = useAppSelector(selectAdmins);
  const initial: TableRow[] = [];
  const [adminRows, setAdminRows] = useState(initial);
  const dispatch = useAppDispatch();
  const [superAdmin, setSuperAdmin] = useState(false);

  useEffect(() => {
    if (auth?.idTokenClaims) {
      if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
        setSuperAdmin(true);
      }

      dispatch(applicationAdminsAsync({ appId: app.applicationId }));
    }
  }, []);

  useEffect(() => {
    if (admins.data.length > 0) {
      setAdminRows(
        admins.data.map((item: IAdminData) => ({
          cells: [
            {
              children: item.adminProfile.givenName,
            },
            {
              children: item.adminProfile.surName,
            },
            {
              children: item.adminProfile.email,
            },
          ],
        }))
      );
    }
  }, [admins]);
  const sortableHead: TableRow[] = [
    {
      cells: [
        { children: "First name", scope: "col", sortable: {} },
        { children: "Last name", scope: "col", sortable: {} },
        { children: "E-mail", scope: "col", sortable: {} },
      ],
    },
  ];
  return (
    <div>
      <Grid>
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              padding: "0rem 1rem",
              textAlign: "right",
            }}
          >
            {(superAdmin || admins.data.length > 0) && (
              <GridItem
                feColspan={{
                  desktop: 12,
                  mobile: 12,
                  tablet: 12,
                }}
              >
                <AddAdmin auth={auth} application={app} />
                <Spacer feSpacing="sm" />
              </GridItem>
            )}
            <GridItem
              feColspan={{
                desktop: 12,
                mobile: 12,
                tablet: 12,
              }}
            >
              <div
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  padding: "0.1rem",
                  textAlign: "left",
                }}
              >
                {admins.data.length > 0 ? (
                  <Table feBody={adminRows} feHead={sortableHead} />
                ) : (
                  <p>No admins in this Application</p>
                )}
              </div>
            </GridItem>
          </div>
        </GridItem>
      </Grid>
    </div>
  );
}
