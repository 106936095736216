import {
  IconButton,
  Time,
  Tooltip,
  useTooltip,
} from "@skf-internal/ui-components-react";
import { IUserData } from "../global-roles/globalRoleUsersSlice";
import { getTime } from "../../Utils/getTime";
import { displayOnlyTime } from "./Common";

const ShowDate = (props: any) => {
  const [hoverRef, isHovered] = useTooltip();


  return (
    <div style={{ display: "flex" , justifyContent:"space-between" ,alignItems:"center" , maxWidth:"7rem" }}>
      <Time dateTime={props.dataTimeString} feDateStyle="medium" feSize="md" />
      <IconButton
        aria-label="Information"
        as="button"
        feIcon="infoCircleOutlined"
        feSize="lg"
        ref={hoverRef}
      />
      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
       
        {displayOnlyTime(props.dataTimeString) + ""}
      </Tooltip>
    </div>
  );
};

export default ShowDate;
