import axios from "axios";
import { fetchAccessToken } from "../common/Common";

//axios.defaults.headers.common["x-functions-key"] =
//  process.env.REACT_APP_AUTH_TOKEN || "";

export async function fetchTesseractRoles(adminId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admin/${adminId}`
  );
}
