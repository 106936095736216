import countries from "i18n-iso-countries";
export  const getFlag = (countryIso : any = "")=> {
    let defaultVal = "N/A"
    try {
      if(!!countryIso){
        let returnable =  countries.alpha3ToAlpha2(countryIso)?.toLowerCase();
       return returnable;
      }
    } catch (error) {
     console.log(error); 
    }
    return defaultVal;
  }

  export const getCountryName = (countryIso : any="")=> {
    let defaultVal = countryIso;
    try {
      if(!!countryIso){
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        let returnable =  countries.getName(countryIso, "en"); 
       return returnable;
      }
    } catch (error) {
     console.log(error); 
    }
    return defaultVal;
  }