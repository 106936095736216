import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableRow } from "@skf-internal/ui-components-react";
import { RootState } from "../../app/store";
import {
  fetcApplicationRoleUsersPost,
  fetchAdmins,
  fetchApplicationRoleUserCountries,
  fetchApplicationRoleUserDomains,
  fetchGroups,
  fetchRoles,
} from "./applicationDetailsAPI";
import { IDateFilterValue } from "../common/DateFilter";
import { ICountryRecord, IDomainRecord } from "../common/RoleUsersFilterTags";
export interface IRoleUserDomains {
  data: IDomainRecord[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}
export interface IRoleUserCountries {
  data: ICountryRecord[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}
export interface IRole {
  roleName: string;
  totalUsers: number;
  roleDescription: string;
}
export interface ApplicationDetail {
  roles: IRoleItem[];
  groups: TableRow[];
  users: IUsers;
  admins: IAdmin;
  status: "idle" | "loading" | "failed";
  domains: IRoleUserDomains;
  domainstatus: "idle" | "loading" | "failed";
  countries: IRoleUserCountries; 
  countrystatus: "idle" | "loading" | "failed";
}
interface IFetchGroupsRolesUsers {
  appId: string;
  userId: string;
}
export interface IFetchAppAdmins {
  appId: string;
}
export interface IUserBasedOnRoles {
  applicationId: string;
  adminId: string;
  roleId: string;
  queryPage: number;
  itemsPerPage: number;
  sortByField: string;
  order: string;
  quickFilter: string;
  excludeEmailDomain: string;
  lastLoginFilter: IDateFilterValue | undefined;
  createdDateFilter: IDateFilterValue | undefined;
  filterByDomains: string[];
  filterByCountries: string[];
}
export interface IFetchDomains {
  roleId: string;
  applicationId: string;
  adminId: string;
  level: string;
  queryPage: number;
  itemsPerPage: number;
  quickFilter: string;
  excludeEmailDomain: string;
  lastLoginFilter: IDateFilterValue | undefined;
  createdDateFilter: IDateFilterValue | undefined;
}
export interface IFetchCountries {
  roleId: string;
  adminId: string;
  applicationId: string; 
  level: string;
  queryPage: number;
  itemsPerPage: number;
  quickFilter: string;
  excludeEmailDomain: string;
  lastLoginFilter: IDateFilterValue | undefined;
  createdDateFilter: IDateFilterValue | undefined;
}
export interface IRoleItem {
  roleName: string;
  totalUsers: number;
  roleDescription: string;
  roleId: number;
  approverGroup: string;
}
interface IGroupItem {
  groupName: string;
  totalUsers: number;
  groupDescription: string;
}
export interface IAdmin {
  data: IAdminData[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}
export interface IUsers {
  data: IUserItem[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}
export interface IAdminData {
  adminProfileId: string;
  adminProfile: IAdminDataProfile;
}
export interface IAdminDataProfile {
  id: string;
  givenName: string;
  surName: string;
  email: string;
}
export interface IUserItem {
  fName: string;
  surName: string;
  surname: string;
  userName: string;
  role: string;
  globalRoles: string[];
  applicationRoles: string[];
  lastLogin: string;
  company: string;
  id: string;
  givenName: string;
  countryIso: string;
  companyName?: string;
  email: string;
  createdAt: string;
  lastLogonAt?: string;
  lastLogon ?: string;
}

const initialState: ApplicationDetail = {
  roles: [],
  groups: [],
  users: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  admins: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  status: "idle",
  domains: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  domainstatus: "idle",
  countries: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  countrystatus: "idle",
};

export const applicationRoleAsync = createAsyncThunk(
  "fetchRoles",
  async (param: IFetchGroupsRolesUsers) => {
    const response = await fetchRoles(param.appId, param.userId);
    // The value we return becomes the `fulfilled` action payload
    return response.data.roles;
  }
);

export const applicationGroupAsync = createAsyncThunk(
  "fetchGroups",
  async (param: IFetchGroupsRolesUsers) => {
    const response = await fetchGroups(param.appId, param.userId);
    // The value we return becomes the `fulfilled` action payload
    return response.data.groups;
  }
);

export const applicationUserAsync = createAsyncThunk(
  "fetchUsers",
  async (param: IUserBasedOnRoles) => {
    const response = await fetcApplicationRoleUsersPost(param);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const applicationAdminsAsync = createAsyncThunk(
  "fetchAdmins",
  async (param: IFetchAppAdmins) => {
    const response = await fetchAdmins(param.appId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const domainsAsync = createAsyncThunk(
  "fetchGlobalRoleUserDomains",
  async (fetchUsers: IFetchDomains) => {
    const response = await fetchApplicationRoleUserDomains(fetchUsers);
    return response.data;
  }
);
export const countriesAsync = createAsyncThunk(
  "fetchGlobalRoleUserCountries",
  async (fetchCountries: IFetchCountries) => {
    const response = await fetchApplicationRoleUserCountries(fetchCountries);
    return response.data;
  }
);
export const applicationDetailsSlice = createSlice({
  name: "applicationDetails",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applicationRoleAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(applicationRoleAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.roles = action.payload;
      })
      .addCase(applicationRoleAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(applicationGroupAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(applicationGroupAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.groups = action.payload.map((item: IGroupItem) => {
          return {
            cells: [
              {
                children: item.groupName,
              },
              {
                children: item.totalUsers,
              },
              {
                children: item.groupDescription,
              },
            ],
          };
        });
      })
      .addCase(applicationGroupAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(applicationUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(applicationUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.users = action.payload;
      })
      .addCase(applicationUserAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(applicationAdminsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(applicationAdminsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.admins = action.payload;
      })
      .addCase(applicationAdminsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(domainsAsync.pending, (state) => {
        state.domainstatus = "loading";
      })
      .addCase(domainsAsync.fulfilled, (state, action) => {
        state.domainstatus = "idle";
        state.domains = action.payload;
      })
      .addCase(domainsAsync.rejected, (state) => {
        state.domainstatus = "failed";
      })
      .addCase(countriesAsync.pending, (state) => {
        state.countrystatus = "loading";
      })
      .addCase(countriesAsync.fulfilled, (state, action) => {
        state.countrystatus = "idle";
        state.countries = action.payload;
      })
      .addCase(countriesAsync.rejected, (state) => {
        state.countrystatus = "failed";
      });
  },
});

export const selectRoles = (state: RootState) => state.applicationDetails.roles;
export const selectGroups = (state: RootState) =>
  state.applicationDetails.groups;
export const selectUsers = (state: RootState) => state.applicationDetails.users;
export const selectUserStatus = (state: RootState) =>
  state.applicationDetails.status;

export const selectAdmins = (state: RootState) =>
  state.applicationDetails.admins;
export const selectAdminsStatus = (state: RootState) =>
  state.applicationDetails.status;

export const selectApplicationRoleUserDomains = (state: RootState) =>
  state.applicationDetails.domains;

export const selectApplicationRoleUserCountries = (state: RootState) =>
  state.applicationDetails.countries;


export default applicationDetailsSlice.reducer;
