import { Link } from "@skf-internal/ui-components-react";
import React from "react";
import { useState  } from "react";
import UserDetailsModal from "../users/UserDetailsModal";
import { IUserItem } from "./applicationDetailsSlice";

export interface IViewUserLinkProps {
  user: IUserItem;
  fromMenue ?: boolean
}

const ViewUserLink = React.forwardRef ( (props: IViewUserLinkProps , ref:any )=> {
  
  const [opneModal , setOpenModal]= useState(false);
  const [modalSelectUser, setModalSelectUser]= useState("");

  const handleModalClose = ()=> {
    setOpenModal(false);
    setModalSelectUser("");
  }

  return (
    <div>
      <>
        <span className="tbUsers">
          <span  className="userlink">
            <Link 
            ref={ref}
              as="a"
              href="/"
              onClick={() => {
                setOpenModal(true )
                setModalSelectUser(props.user.id);
              } }
              feIcon={{
                feIcon: "doubleChevronRight",
                position: "right",
              }}
            >
              Details
            </Link>
          </span>
        </span>
      </>
      <UserDetailsModal userId={modalSelectUser} handleModalClose={handleModalClose} isOpen ={opneModal}/>
    </div>
  );
}
)

export default ViewUserLink;