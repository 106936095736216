import axios from "axios";
import { fetchAccessToken } from "../common/Common";
//axios.defaults.headers.common["x-functions-key"] =
//  process.env.REACT_APP_AUTH_TOKEN || "";

export async function fetchGlobalGroups(
  adminId: string,
  filteredUsers: string[],
  filteredCountries: string[],
  page: number,
  itemsPerPage: number,
  sortingField: string,
  order: string,
  quickFilter: string,
  viewType: string
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  let queryString = "";
  if (page < 2) {
    page = 0;
  }

  let data = {
    users: filteredUsers,
    countries: filteredCountries,
    queryPage: page,
    itemsPerPage: itemsPerPage,
    sortField: sortingField,
    order: order,
    quickFilter: quickFilter,
    adminId: adminId,
    viewType: viewType,
  };

  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v3/tesseract/globalroles/groups`,
    data
  );
}
export async function createGlobalGroup(data: {
  GroupName: string;
  GroupDescription?: string;
}) {
  let accessToken = await fetchAccessToken();

  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/groups`,
    data
  );
}

export async function updateGlobalGroup(data: {
  GroupName: string;
  GroupDescription?: string;
}) {
  let accessToken = await fetchAccessToken();

  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.put(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/groups`,
    data
  );
}

export async function createGlobalGroupTicket(payload: {
  userProfileId: string;
  globalRole: string;
}) {
  let accessToken = await fetchAccessToken();

  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/globalroles/tickets`,
    payload
  );
}
