import {
  Breadcrumbs,
  Button,
  Link,
  Flexbox,
  Grid,
  GridItem,
  Icon,
  Pagination,
  Select,
  Spacer,
  Table,
  TableRow,
  Tag,
  Text,
  TextField,
  SelectItem,
  Divider,
  Search,
  Loader,
  useToast
} from "@skf-internal/ui-components-react";
import { useEffect, useState, useMemo, useRef, ReactComponentElement } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CSVDownload, CSVLink } from "react-csv";
import {
  checkTesseractRoles,
  isSuperAdmin,
  selectAuth,
  tesseractRoles,
} from "../sso/ssoSlice";
import {
  globalGroupsAsync,
  selectGlobalGroups,
  selectStatus,
  IGlobalGroup,
  IGlobalGroupData,
  IGlobalGroupSearch,
} from "./globalGroupSlice";
import AddGroup from "./AddGroup";
import EditGroup from "./EditGroup";

import {
  ISearchUser,
  resetSearch,
  searchUserByEmailAsync,
  selectSearchedUser,
} from "../group-users/groupUserSlice";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { countriesAsync, ICountry, selectCountries } from "./taxonomySlice";
import { getCountryName } from "../../Utils/getflag";
import { useDebounce } from "../common/useComplexUserSearch";

export interface IGlobalRolesProps {}

export default function GlobalRoles(props: IGlobalRolesProps) {
  const groups: IGlobalGroup = useAppSelector(selectGlobalGroups);
  const pageSize = 10;
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const countries = useAppSelector(selectCountries);
  const initial: TableRow[] = [];
  const [groupRows, setGroupRows] = useState(initial);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const DEBOUNCE_DELAY = 750;
  const searchUser: ISearchUser[] = useAppSelector(selectSearchedUser);
  const [selectedUsers, setSelectedUsers] = useState<ISearchUser[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [searchhint, setSearchhint] = useState("");
  const [showField, setShowField] = useState(true);
  const [show, setShow] = useState("mygroups");
  const [filterActive, setFilterActive] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [csvData , setCsvData] = useState<any>([{}]);
  const [csvLoading , setCsvLoading ] = useState(false);
  const initialRow: SelectItem<string>[] = [];
  const [contryItems, setCountryItems] = useState(initialRow);
  const navigate = useNavigate();
  const gotoRoute = (route: any) => {
    navigate(route);
  };
  const searchStatus = useAppSelector(selectStatus);
  const [sortingGroupName, setSortingGroupName] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingDescription, setSortingDescription] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const initialSort: string[] = ["", ""];
  const [sortingValues, setSortingValues] = useState(initialSort);
  const [quickFilter, setQuickFilter] = useState("");
  const placeHolderText = "Search by group name or description";
  const csvLink = useRef<any>(null)
  const [groupFilter, setGroupFilter] = useState<SelectItem<string>[]>([{}]);

  const { addToast } = useToast();


  const {isSuperAdmin, isUserManager, adminId, isGlobalRoleAdmin} = useAppSelector(tesseractRoles);
  useEffect(() => {
    updateGroupFilter();
  }, [adminId]);
  const [filterTags, setFilterTags] = useState("");
  useEffect(() => {
    setFilterTags(
      selectedUsers
        .map((user) => {
          return (
            <div>
              <Tag feType="filled" children={user + ""} />
            </div>
          );
        })
        .join("")
    );
  }, [selectedUsers]);

  useEffect(() => {
    dispatch(resetSearch([]));
    let showStore: any = localStorage.getItem("GlobalGroups_show");
    if (showStore !== undefined) {
      setShow(updateShowValue(showStore));
    }
    let selectedUsersStore = localStorage.getItem("GlobalGroups_users");
    if (selectedUsersStore != undefined) {
      setSelectedUsers(JSON.parse(selectedUsersStore));
    } else {
      setSelectedUsers([]);
    }
    let selectedCountiresStore = localStorage.getItem("GlobalGroups_countries");
    if (selectedCountiresStore != undefined) {
      setSelectedCountries(JSON.parse(selectedCountiresStore));
    } else {
      setSelectedCountries([]);
    }
    let quickFilterStore: any = localStorage.getItem(
      "GlobalGroups_quickfilter"
    );

    if (quickFilterStore != undefined) {
      setQuickFilter(quickFilterStore);
    }
    let pageStore: any = localStorage.getItem("GlobalGroups_page");

    if (pageStore != undefined) {
      setPage(Number(pageStore));
    }
    dispatch(countriesAsync());

   
    setTimeout(function () {
      setFirstLoad(true);
    }, 100);
    updateGroupFilter();
  }, []);
  const updateShowValue = (showType: string) => {
    if (
      !isSuperAdmin &&
      !isUserManager &&
      !isGlobalRoleAdmin &&
      showType !== "mygroups"
    ) {
      return "mygroups";
    }
    if (!isGlobalRoleAdmin && showType === "ownedgroups") {
      return "mygroups";
    }
    if (
      !isSuperAdmin &&
      !isUserManager &&
      showType === "allgroups"
    ) {
      return "mygroups";
    }
    return showType; 
  };

  const updateGroupFilter = () => {
    setShow(updateShowValue(show));
    let filter: SelectItem<string>[] = [];
    filter.push({ label: "My groups", value: "mygroups" });
    if (
      isGlobalRoleAdmin 
    ) {
      filter.push({ label: "Groups as global role admin", value: "ownedgroups" });
    }
    if (
      isSuperAdmin ||
      isUserManager
    ) {
      filter.push({ label: "All groups", value: "allgroups" });
    }

    setGroupFilter(filter);
  };

  useEffect(() => {
    if (firstLoad == true) {
      setPage(0);
    }
  }, [show]);
  useEffect(() => {
    if (firstLoad == true) {
      createGroupRows();
      setPage(page);
    }
  }, [groups]);

  useEffect(
    () =>
      setCountryItems(
        countries.map((country: ICountry) => {
          return { label: country.countryName, value: country.alpha3IsoCode };
        })
      ),
    [countries]
  );

  useEffect(() => {
    if (firstLoad) {
      debouncedOnChange();
    }
  }, [show, firstLoad, selectedUsers, selectedCountries, page, sortingValues]);

  const itemsPerPage = () => {
    console.log(
      "window.outerHeight " +
        window.outerHeight +
        " window.innerHeight " +
        window.innerHeight
    );
    let cellHeight = 48;
    const scalingFactor = window.devicePixelRatio;
    console.log(`Scaling factor for current window is: ${scalingFactor}`);

    let height = (window.innerHeight - 250) * scalingFactor;
    return Math.floor(height / cellHeight);
  };

  const loadGroups = async (csvDownloadFlag =false) => {
    localStorage.setItem("GlobalGroups_show", show);
    localStorage.setItem("GlobalGroups_users", JSON.stringify(selectedUsers));
    localStorage.setItem(
      "GlobalGroups_countries",
      JSON.stringify(selectedCountries)
    );
    localStorage.setItem("GlobalGroups_quickfilter", quickFilter);
    localStorage.setItem("GlobalGroups_page", page.toString());

    let filt = selectedUsers.map((item: ISearchUser) => {
      return item.id;
    });
    /*if (show === "mygroups") {
      filt.push(auth.idTokenClaims.sub);
    }*/

    if (auth?.idTokenClaims) {
      let search: IGlobalGroupSearch = {
        adminId: auth?.idTokenClaims?.sub,
        filteredUsers: filt,
        filteredCountries: selectedCountries,
        sortingField: sortingValues[0],
        order: sortingValues[1],
        quickSearch: quickFilter,
        page: page,
        itemsPerPage: itemsPerPage(),
        viewType: show,
      };
      if (searchStatus !== "loading") {
        dispatch(globalGroupsAsync(search));
      }
      if (searchStatus !== "loading" && csvDownloadFlag) {
        let csvSearch = { ...search };
        csvSearch.itemsPerPage = 500;
        try {
          setCsvLoading( true);
          const result = await dispatch(globalGroupsAsync(csvSearch));
          if (result?.meta?.requestStatus === "fulfilled") {
            const csvFormatting =  result.payload.data.map((el: any) => {
              if (el.userGlobalGroups.length > 0) {
                return el.userGlobalGroups.map((userDetails: any) => {
                  let formattedDate = userDetails?.lastLogin?.length > 0 ? new Date(userDetails?.lastLogin).toISOString().split('T')[0] : "N/A";
                  return {
                    ...el,
                    name: userDetails.userName || "N/A",
                    country: userDetails.country || "N/A",
                    email: userDetails.email || "N/A",
                    firstName: userDetails.firstName || "N/A",
                    lastName : userDetails.lastName || "N/A",
                   lastLogin :  formattedDate
                  };
                });
              } else {
                return {
                  ...el,
                  name: "N/A",
                  country: "N/A",
                  email: "N/A",
                  firstName: "N/A",
                  lastName : "N/A",
                  lastLogin: "N/A"
                };
              }
            });
            const downLoadData = await csvFormatting.flat(1); 
            const separator = "sep=,";
            const headers = [
              ["Group Name", "Description", "Email", "First Name", "Last Name", "Country", "Last Login"]
            ];
            const dataWithSeparator = [[separator], ...headers, ...downLoadData.map((item: { globalGroupName: any; globalGroupDescription: any; email: any; firstName: any; lastName: any; country: any; lastLogin: any; })  => [
              item.globalGroupName,
              item.globalGroupDescription,
              item.email,
              item.firstName,
              item.lastName,
              item.country,
              item.lastLogin
            ])];
            
            setCsvData( ()=>dataWithSeparator); 
            
        if(downLoadData.length > 0){
          addToast({children:"Excel Download is completed" ,feSeverity:"success"  })
          setTimeout( ()=> {
            csvLink?.current?.link?.click();
          } , 1000 )   
        }else{
          addToast({children:"No data to download" ,feSeverity:"warning"  })
        }
        
            
          }
        }catch (error){
        console.log("error on downloading csv file")
        console.log(error);
        addToast({children:"Something went wrong" ,feSeverity:"error"  })
        }finally{
          setCsvLoading(false)
          dispatch(globalGroupsAsync(search));
        }
      
      }
    }
    
  };
  const debouncedOnChange = useDebounce(loadGroups, 1000);
  useEffect(() => {
    if (quickFilter?.length > 2 || quickFilter?.length == 0) {
      if (
        firstLoad == true &&
        quickFilter !== localStorage.getItem("GlobalGroups_quickfilter")
      ) {
        setPage(1);
      }
      debouncedOnChange();
    }
  }, [quickFilter]);
  useEffect(() => {
    if (selectedUsers?.length > 0 || selectedCountries?.length > 0) {
      //setPage(0);
      setFilterActive("filter");
    } else {
      setFilterActive("");
      setShowField(true);
    }
  }, [selectedUsers, selectedCountries]);

  const createGroupRows = () => {
    if (groups != undefined) {
      localStorage.setItem("GlobalGroups_page", groups.page.toString());
      if (groups.data != undefined && groups.data.length > 0) {
        let slice: IGlobalGroupData[] = groups.data;
        setGroupRows(
          slice.map((item: IGlobalGroupData, index) => ({
            cells: [
              {
                children: item.globalGroupName,
                height: "48px",
              },
              {
                children: (
                  <>
                    <Flexbox
                      feAlignItems="flex-start"
                      feStretch
                      className="tdEdit"
                    >
                      {item.globalGroupDescription}
                      <EditGroup
                        auth={auth}
                        groupId={item.globalGroupName}
                        groupDescription={item.globalGroupDescription}
                      />{" "}
                    </Flexbox>
                  </>
                ),
              },
              {
                children: (
                  <>
                    <span className="tbUsers">
                      <span className="spanUsers">
                        {item.userGlobalGroups.length}
                      </span>
                      <span className="userlink">
                        <Link
                          as="a"
                          href="/"
                          onClick={() =>
                            gotoRoute(
                              `/group-users/${item.globalGroupName.replaceAll(
                                "/",
                                "$"
                              )}`
                            )
                          }
                          feIcon={{
                            feIcon: "doubleChevronRight",
                            position: "left",
                          }}
                        >
                          {" "}
                        </Link>
                      </span>
                    </span>
                  </>
                ),
              },
            ],
          }))
        );
      } else {
        setGroupRows([]);
      }
    }
  };
  const debouncedChangeHandler = useMemo(
    () =>
      debounce((query: string) => {
        dispatch(searchUserByEmailAsync({ email: query }));
      }, DEBOUNCE_DELAY),
    []
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const handleUserInputChange = (value: string) => {
    setSearchhint("");
    debouncedChangeHandler(value);
  };
  useEffect(() => {
    if (isOpen && searchUser[0]) {
      setSearchhint("");
      setPage(0);
      selectUser(searchUser[0]);
    } else {
      setSearchhint("No user found with that email");
    }
  }, [searchUser]);

  const removeUserFromSelection = (user: ISearchUser) => {
    const temp = [...selectedUsers];
    const index = temp.indexOf(user, 0);

    if (index > -1) {
      temp.splice(index, 1);
      setSelectedUsers(temp);
    }
    if (temp.length === 0) {
      setShowField(true);
    }
  };

  const selectUser = (user: ISearchUser) => {
    setSelectedUsers((selectedUsers) => [...selectedUsers, user]);

    setTimeout(() => {
      // setDisplaySearchedUser("");
      setShowField(false);
    }, 250);
  };
  function clearSort(exclude: string) {
    if (exclude !== "groupname") {
      setSortingGroupName("undefined");
    }
    if (exclude !== "description") {
      setSortingDescription("undefined");
    }
  }
  function toggleSearch(currentValue: string) {
    var newValue: "undefined" | "ascending" | "descending" = "undefined";
    switch (currentValue) {
      case "undefined":
        newValue = "ascending";
        break;
      case "ascending":
        newValue = "descending";
        break;
      case "descending":
        newValue = "undefined";
        break;
    }

    return newValue;
  }

  const sort = (field: string) => {
    var order = undefined;
    switch (field) {
      case "groupname": {
        order = toggleSearch(sortingGroupName);
        setSortingGroupName(order);
        break;
      }
      case "description": {
        order = toggleSearch(sortingDescription);
        setSortingDescription(order);
        break;
      }
    }
    clearSort(field);

    setSortingValues([field, order + ""]);
  };

  const sortableHead: TableRow[] = [
    {
      cells: [
        {
          children: "Group name",
          scope: "col",
          width: "30%",
          className: "Sort-" + sortingGroupName,
          onClick: () => {
            sort("groupname");
          },
        },
        {
          children: "Description",
          scope: "col",
          className: "Sort-" + sortingDescription,
          onClick: () => {
            sort("description");
          },
        },
        { children: "Members", scope: "col", width: "50px" },
      ],
    },
  ];
  return (
    <>
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={[
          {
            label: "Groups",
            href: "/groups",
            onClick: (e) => {
              e.stopPropagation();
            },
          },
        ]}
        feSize="sm"
        feType="interactive"
        className="breadcrumbs"
      />
      <Spacer />
      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div className="contentArea">
            {/* Search and filter settings  */}
            <Grid>
              <GridItem
                feColspan={{
                  desktop: 8,
                  mobile: 8,
                  tablet: 8,
                }}
              >
                <Flexbox
                  feFlexDirection="row"
                  feFlexWrap="nowrap"
                  feGap="xxs"
                  feJustifyContent="left"
                >
                  <div style={{ width: "47rem" }}>
                    <Search
                      className="userSearch"
                      feHideLabel
                      feLabel=""
                      feResetButton={{
                        "aria-label": "Rensa",
                        onClick: () => setQuickFilter(""),
                      }}
                      placeholder={placeHolderText}
                      onChange={(_, value) => {
                        setQuickFilter(value);
                      }}
                      onKeyDown={(event: any) => {
                        if (event.key === "Enter") {
                          handleUserInputChange(quickFilter);
                        }
                      }}
                      value={quickFilter}
                      disabled={searchStatus !== "idle"}
                    />
                  </div>
                
                </Flexbox>
              </GridItem>
              <GridItem
                feColspan={{
                  desktop: 4,
                  mobile: 4,
                  tablet: 4,
                }}
              >
                <Flexbox
                  feFlexDirection="row"
                  feFlexWrap="nowrap"
                  feGap="sm"
                  feJustifyContent="right"
                >
                  <Select
                    className="show"
                    defaultValue="mygroups"
                    feItems={groupFilter}
                    feLabel="Show:"
                    multiple={false}
                    onChange={setShow}
                    value={show}
                  />
                  <div
                    style={{
                      backgroundColor: "#f6f6f6",
                      padding: "0.6rem 0.8rem 0.4rem 0.8rem",
                      textAlign: "center",
                    }}
                    onClick={() => setIsOpen(true)}
                    className={filterActive}
                  >
                    <Icon feIcon="filter" feSize="lg" />
                  </div>
                  {isOpen && (
                    <div id="groupFilter">
                      <div className="close" onClick={() => setIsOpen(false)}>
                        <Icon feIcon="close" />
                      </div>
                      <Link
                        as="a"
                        href="/"
                        className="clearfilter"
                        onClick={() => {
                          setSelectedUsers([]);
                          setSelectedCountries([]);
                        }}
                      >
                        Clear filter
                      </Link>
                      <Divider />
                      <div className="title">Filter by</div>
                      <div>
                        <Text>Member</Text>
                        {showField && (
                          <TextField
                            feLabel=""
                            onChange={(_: any, value: any) => {
                              handleUserInputChange(value);
                            }}
                            feHint={searchhint}
                          />
                        )}
                        {selectedUsers.length > 0 && (
                          <>
                            <Spacer feSpacing="md" />
                            <div
                              id="tagfield"
                              onClick={() => setShowField(true)}
                            >
                              {" "}
                              {selectedUsers.map((user: ISearchUser) => {
                                return (
                                  <Tag
                                    feClosable={{
                                      onClick: () =>
                                        removeUserFromSelection(user),
                                    }}
                                  >
                                    {user.email}
                                  </Tag>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>

                      <div>
                        <Select<string>
                          defaultValue={undefined}
                          feItems={contryItems}
                          feLabel="Members country"
                          fePlaceholder="Select countries"
                          multiple={true}
                          feRows={7}
                          className="countrySearch"
                          onChange={setSelectedCountries}
                          value={selectedCountries}
                        />
                      </div>
                    </div>
                  )}
                  <AddGroup auth={auth} />
                </Flexbox>
              </GridItem>
            </Grid>
            {/* Search filte settings ends here */}
            <Spacer feSpacing="sm" />
            <div className="sub-search-section">
                  <Flexbox
                    feFlexDirection="row"
                    feFlexWrap="nowrap"
                    feGap="md"
                    feJustifyContent="flex-start"
                  >
                    

                    <Button
                      style={{ 
                      cursor: csvLoading ? "not-allowed" : "pointer",}}
                      feIcon={{
                        feIcon: "download",
                        position: "left",
                      }}
                      feLoading={csvLoading ? true : false}
                      onClick={(e)=> !csvLoading  &&    loadGroups(true) }
                    >
                      Download Excel
                    </Button>

                    <CSVLink
                      className="hidden"
                      filename="global-groups.csv"
                      ref={csvLink}
                      target="_blank"
                      data={csvData}
                    />
                  </Flexbox>
                  <div className="filterTags">
                    {selectedUsers.map((user) => {
                      return (
                        <div title={"Member = " + user.email}>
                          <Tag feType="filled">{"Member = " + user.email}</Tag>
                        </div>
                      );
                    })}
                    {selectedCountries.map((country) => {
                      return (
                        <div>
                          <Tag
                            feType="filled"
                            title={
                              "Member country = " + getCountryName(country)
                            }
                          >
                            {"Member country = " + getCountryName(country)}
                          </Tag>
                        </div>
                      );
                    })}
                  </div>
            </div>
            <Grid>
              <GridItem
                feColspan={{
                  desktop: 12,
                  mobile: 12,
                  tablet: 12,
                }}
              >
                <div>
                  {searchStatus === "loading" && <Loader />}
                  {searchStatus === "failed" && (
                    <p>Search failed. Please logout and logon again</p>
                  )}
                  {searchStatus === "idle" &&
                    ((groupRows.length > 0 && (
                      <>
                        <Table
                          feBody={groupRows}
                          feHead={sortableHead}
                          className="GroupTable"
                          feCompact
                        />
                        {groups.total > groups.page_size && (
                          <Flexbox feJustifyContent="flex-end">
                            <Pagination
                              feControls={{
                                next: {
                                  "aria-label": "Next page",
                                },
                                prev: {
                                  "aria-label": "Previous page",
                                },
                              }}
                              feItemsPerPage={groups.page_size}
                              feOnPageChange={(page: number) => {
                                setPage(page);
                              }}
                              fePage={groups.page}
                              feTotal={groups.total}
                            />
                          </Flexbox>
                        )}
                      </>
                    )) ||
                      (firstLoad != false && <p>No groups found</p>))}
                </div>
              </GridItem>
            </Grid>
          </div>
        </GridItem>
      </Grid>
    </>
  );
}
