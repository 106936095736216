import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  TextField as TF,
} from "@mui/material";
import { ISearchUser } from "../group-users/groupUserSlice";
import { useAdvanceUserSearch } from "./useComplexUserSearch";
import { useEffect, useState } from "react";
import { Divider, Icon, Loader } from "@skf-internal/ui-components-react";
import { getCountryName, getFlag } from "../../Utils/getflag";

export interface IUserSearchBoxProps {
  setSelectedUser: any;
}

export default function UserSearchBox(props: IUserSearchBoxProps) {
  const { setSelectedUser } = props;
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const { searchUser = [], status } = useAdvanceUserSearch(query);
  const defaultProps = {
    options: searchUser || [],
    getOptionLabel: (option: ISearchUser) => {
      return option.email;
    },
  };
  useEffect(() => {
    setSelectedUser(searchUser);
  }, [searchUser]);

  return (
    <Autocomplete
      filterOptions={(options, state) => options}
      includeInputInList
      {...defaultProps}
      loading={status === "loading"}
      ListboxProps={{
        style: {
          maxHeight: "350px",
          boxShadow: "0",
        },
      }}
      options={searchUser}
      id="auto-complete"
      autoComplete
      onInputChange={(a, b) => {
        if (email !== b) {
          setQuery(b);
          setEmail(b);
        }
      }}
      renderInput={(params) => {
        return (
          <>
            <TF
              {...params}
              InputProps={{
                ...params.InputProps,

                style: { padding: "2px", fontSize: "16px" },
                endAdornment: (
                  <>
                    {status === "loading" ? (
                      <Loader feSize="sm" style={{ marginRight: "16px" }} />
                    ) : null}
                  </>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon feIcon="search" style={{ marginLeft: "8px" }} />
                  </InputAdornment>
                ),
              }}
              placeholder="Search for user"
              fullWidth
            />
          </>
        );
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <section style={{ display: "flex" }}>
            <div title="Email" style={{ minWidth: "16rem", maxWidth: "16rem" }}>
              {" "}
              {option.email}
            </div>
            <Divider feVertical={true} />
            <Chip
              title="Name"
              sx={{ margin: "0rem 0.5rem" }}
              variant="outlined"
              label={option.givenName + " " + option.surName}
            />
            {/* <Divider feVertical={true}/> */}
            <Chip
              title="Company Name"
              sx={{ margin: "0rem 0.5rem" }}
              variant="outlined"
              label={option.companyName || "N/A"}
            />
            {/* <Divider feVertical={true}/> */}

            {getFlag(option.countryIso) === "N/A" ? (
              <Chip
                title="Country"
                variant="outlined"
                label={option.countryIso || "N/A"}
              />
            ) : (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  height="20"
                  srcSet={`https://flagcdn.com/h20/${getFlag(
                    option.countryIso
                  )}.png`}
                  src={`https://flagcdn.com/h20/${getFlag(
                    option.countryIso
                  )}.png`}
                  alt={option.countryIso}
                  title={getCountryName(option.countryIso)}
                />
              </Box>
            )}
          </section>
        </li>
      )}
    />
  );
}
