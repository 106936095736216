import {
  Button,
  Dialog,
  Link,
  Text,
  Spacer,
  Tag,
  TextAreaField,
  TextField,
  Flexbox,
} from "@skf-internal/ui-components-react";
import * as React from "react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import {  ISearchUser } from "./userSlice";
import { detachAppRoleFromUser } from "../app-details/applicationDetailsAPI";

export interface IRemoveRoleFromUserProps {
  auth: any;
  role: any;
  user: ISearchUser;
  callback: any;
}

export default function RemoveApplicationRoleFromUser(
  props: IRemoveRoleFromUserProps
) {
  const { auth, user, role } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [ticket, setTicket] = useState("");
  const [ticketError, setTicketError] = useState(false);
  const [declineHint, setDecineHint] = useState("");
  const [declineError, setDeclineError] = useState<"error" | undefined>(
    undefined
  );
  const DEBOUNCE_DELAY = 750;
  const navigate = useNavigate();
  const errorHint = "SuperAdmins must referece to ServiceNow ticket";

  const removeRoleFromUser = () => {
    let reasonText = reason;
    if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
      reasonText += " Refering SNOW Ticket: " + ticket;
    }

    var adminId = auth?.idTokenClaims?.sub;
    detachAppRoleFromUser(user.id, role.applicationId, role.roleId + "", {
      adminId: adminId,
      reason: reasonText,
    })
      .then((res) => {
        setTimeout(() => {
          setIsOpen(false);
        }, 1000);
        props.callback();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <div>
     <Tag feType="outlined"
         feIcon= "edit"
         feClosable={{
         title: "Remove role from user", 
          onClick: () => {
            setIsOpen(true)
          },
        }}
      
      >{role.roleName}</Tag>
      <Dialog
        {...props}
        feInterruptive
        feTitle="Remove Application role from user"
        open={isOpen}
      >
        <Text>
          Remove role <strong> {role.roleName} </strong>
          <br />
          from user <b>{user.userName}</b>
        </Text>
        <Spacer feSpacing="md" />
        <TextAreaField
          feLabel="Reason or removal (for internal use)"
          onChange={(_, value) => {
            setReason(value);
          }}
        />
        <Spacer feSpacing="md"></Spacer>
        {auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin") && (
          <>
            <TextField
              feLabel="Reference"
              feRequiredText={errorHint}
              feHint={declineHint}
              feSeverity={declineError}
              onChange={(_, value) => {
                setTicket(value);
              }}
            />
            <Spacer feSpacing="md"></Spacer>
          </>
        )}

        <Flexbox feGap="md" feJustifyContent="flex-end">
          <Button feType="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => removeRoleFromUser()}>Remove</Button>
        </Flexbox>
      </Dialog>
    </div>
  );
}
