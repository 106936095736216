import { useState, useEffect, useMemo, useRef } from "react";
import {
  ISearchUser,
  searchUserAsync,
  searchUserByEmailAsync,
  selectSearchedUser,
  selectStatus,
} from "../users/userSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { debounce } from "lodash";

// Custom hook för att söka användare
export const useComplexUserSearch = (query = "") => {
  const DEBOUNCE_DELAY = 900;
  const searchUser: ISearchUser[] = useAppSelector(selectSearchedUser);
  const status = useAppSelector(selectStatus);
  const dispatch = useAppDispatch();
  const fetchUsers = debounce(async () => {
    //console.log("fetchUsers calling with query= " + query);
    try {
      dispatch(searchUserAsync(query));
    } catch (err) {
      console.error(err);
    }
  }, DEBOUNCE_DELAY);

  useEffect(() => {
    if (query?.length > 1) {
      fetchUsers();
      //console.log("fetchUsers add " + query);
    }
  }, [query]);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      fetchUsers.cancel();
    };
  }, [query]);

  return { searchUser, status };
};

export default useComplexUserSearch;

export const useAdvanceUserSearch = (query = "") => {
  const searchUser: ISearchUser[] = useAppSelector(selectSearchedUser);
  const status = useAppSelector(selectStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const identifier = setTimeout(() => {
      try {
        if (query.length > 2) {
          dispatch(searchUserAsync(query));
        }
      } catch (err) {
        console.error(err);
      }
    }, 750);

    return () => {
      clearInterval(identifier);
    };
  }, [query]);

  return { searchUser, status };
};

export const useDebounce = (callback: () => void, timeout: number) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, timeout);
  }, []);

  return debouncedCallback;
};
