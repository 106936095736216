

import {ReactComponent as SVG} from '../../Assets/user_empty_state.svg.svg' ;

type   PlaceholderProps =  {
    svgText : any
}
const PlaceholderUserSearch = ( {svgText = '--'} : PlaceholderProps) => {
    // console.log(props);
  return (
    <div style={{ padding : '0.5rem' , display:'flex', flexDirection:'column' , textAlign : "center"}}> 
    <div style ={{height : '13rem' }}>
       <SVG />
    </div>
     <h5 style={{color : '#273342'}}> {svgText}</h5>
    </div>
  )
}

export default PlaceholderUserSearch