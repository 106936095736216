import axios from "axios";
import { fetchAccessToken } from "../common/Common";


export async function getOathMethods(userId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_UIC_ENDPOINT}/api/v1/user/${userId}`
  );
}
export async function deleteOathToken(userId: string, tokenId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.delete(
    `${process.env.REACT_APP_UIC_ENDPOINT}/api/v1/user/${userId}/oathMethod/${tokenId}`
  );
}
