import {
  Breadcrumbs,
  Flexbox,
  Grid,
  GridItem,
  Loader,
  Pagination,
  Search,
  Spacer,
  Switch,
  Table,
  TableCell,
  TableRow,
  Tag,
} from "@skf-internal/ui-components-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../sso/ssoSlice";

import {
  countriesAsync,
  domainsAsync,
  globalRoleUsersAsync,
  IFetchDomains,
  IFetchUsers,
    selectStatus,
} from "./globalRoleUsersSlice";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import GlobalRolesUserFilter from "./GlobalRoleUserFilter";
import { useDebounce } from "../common/useComplexUserSearch";
import { IDateFilterValue } from "../common/DateFilter";

import RolesUserFilterTags, {
  ICountryRecord,
  IDomainRecord,
  IRolesUserFilter,
} from "../common/RoleUsersFilterTags";
import {  createUsersRows } from "./roleUserFunctions";
import CustomTable from "../common/CustomTable";

export interface IGlobalRoleUsersProps {}

export default function GlobalRoleUsers(props: any) {
  const [hasMore, setHasMore] = useState(false);
  const [loading , setLoading] = useState(true);
  const [initialLoadingComplete , setInitialLoadingComplete] =useState(false);
  const location = useLocation();
  const { roleId } = useParams();
 //const users = useAppSelector(selectGlobalRoleUsers);
 //const domains = useAppSelector(selectGlobalRoleUserDomains);
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const [show, setShow] = useState("myroles");
  const [page, setPage] = useState(1);
  const [startInfiniteScroll , setStartInfiniteCall] =useState(false);
  
  const initial: TableRow[] = [];
  const [userRows, setUserRows] = useState(initial);
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const searchStatus = useAppSelector(selectStatus);
  const observer = useRef<any>();
 
  const [stateUsers , setStateUsers] = useState<any>([]);
  const [stateUsersTotal , setStateUsersTotal] =useState<any>(0);

  const lastItemRef = useCallback((node: any) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
     
      if (entries[0].isIntersecting && hasMore && initialLoadingComplete ) {
       
         setPage(prevPage => prevPage + 1);
         setStartInfiniteCall(true)
      }
    });
    if (node) observer.current.observe(node);
  }, [ loading , hasMore , initialLoadingComplete] );



  const [sortingFirstName, setSortingFirstName] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingLastName, setSortingLastName] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingEmail, setSortingEmail] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCompany, setSortingCompany] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCountry, setSortingCountry] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingLastLogin, setSortingLastLogin] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const [sortingCreationDate, setSortingCreationDate] = useState<
    "undefined" | "ascending" | "descending"
  >("undefined");
  const initialSort: string[] = ["", ""];
  const [sortingValues, setSortingValues] = useState(initialSort);
  const [quickFilter, setQuickFilter] = useState("");

  const placeHolderText =
    "Search by first name, last name, company, country and/or e-mail";
  const DEBOUNCE_DELAY = 900;

  const [includeInternalUser, setIncludeInternalUser] = useState(false);
  const [initInProgress, setInitInProgress] = useState(true);
  const [lastLoginFilter, setLastLoginFilter] = useState<IDateFilterValue>();
  const [filteredDomains, setFilteredDomains] = useState<string[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<string[]>([]);
  const [createdDateFilter, setCreatedDateFilter] = useState<IDateFilterValue>();
  const [availableEmailDomains, setAvailableEmailDomains] = useState<IDomainRecord[]>([]);
  const [availableCountries, setAvailableCountries] = useState<ICountryRecord[]>([]);
  const [globalRoleUserFilters, setGlobalRolesUserFilter] =
    useState<IRolesUserFilter>({
      includeInternalUser: includeInternalUser,
      creatonDateFilter: {} as IDateFilterValue,
      lastLoginDateFilter: {} as IDateFilterValue,
      filteredDomains: [],
      filteredCountries: [], 
      availableDomains: availableEmailDomains,
      availableCountries: availableCountries,
    } as IRolesUserFilter);

 

  useEffect(() =>  {
    if (auth?.idTokenClaims?.roles) {
      if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
        setSuperAdmin(true);
      }
    }

    if (auth?.idTokenClaims) {
    }
  
    loadAvailableDomains();
    loadAvailableCountries();
  }, []);

  //fetch for infinite scroll
  useEffect (()=> {
   
    if(startInfiniteScroll && hasMore && initialLoadingComplete ){
       loadUsers(  true , false, sortingValues,page) ;
    }
  } , [ startInfiniteScroll , page ] )

  const loadUsers = useCallback( async (resetEnable = true , initialCall=false, sortingValues=["",""], pageParam=1) => {
   
    if (searchStatus === "loading") {
     // alert('is it on loading ??')
       return;
    }
   
    if (auth?.idTokenClaims && roleId != null) {
      let fetchRoles: IFetchUsers = {
        roleId: roleId,
        adminId: auth.idTokenClaims.sub,
        level: show,
        queryPage: page,
        itemsPerPage: 30,
       // itemsPerPage:  itemsPerPage() > 5 ? itemsPerPage()  : 5,
        sortByField: sortingValues[0],
        order: sortingValues[1] == "descending" ? "desc" : "asc",
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: globalRoleUserFilters.lastLoginDateFilter,
        createdDateFilter: globalRoleUserFilters.creatonDateFilter,
        filterByDomains: globalRoleUserFilters.filteredDomains,
        filterByCountries: globalRoleUserFilters.filteredCountries,
      };

      setLoading (true);
      setStartInfiniteCall(false);
      const response  = await dispatch(globalRoleUsersAsync(fetchRoles)).unwrap();
     
     // console.log('fetch calling intialCall')
      //const response = await dispatch(globalRoleUsersAsync(fetchRoles)).unwrap();
      
       setStateUsersTotal(response.total);
       if(response.page < response.last_page) {
        setHasMore(true);
       }else{
        setHasMore(false);
        // setStartInfiniteCall(false)
       }
       if(startInfiniteScroll && hasMore ){
         setStateUsers( (prevState: any)=> [...prevState , ...response?.data] )
       } else {
       
        setStateUsers( response.data)
       }
    if (initialCall) {
        setInitialLoadingComplete(true);
    } 


    setLoading (false);
   
    }
   
  } , [page ,  hasMore , startInfiniteScroll , filteredDomains , createdDateFilter ,lastLoginFilter , includeInternalUser , quickFilter ]);

  const loadAvailableDomains = async () => {
    if (roleId != null) {
      let fetchDomains: IFetchDomains = {
        roleId: roleId,
        adminId: auth.idTokenClaims.sub,
        level: show,
        queryPage: 1,
        itemsPerPage: 10000,
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: lastLoginFilter,
        createdDateFilter: createdDateFilter,
      };
     let domainResponse = await dispatch(domainsAsync(fetchDomains)).unwrap();
   
     setAvailableEmailDomains(domainResponse?.data);
     //setGlobalRolesUserFilter({...globalRoleUserFilters , includeInternalUser : includeInternalUser , availableDomains : domainResponse?.data, availableCountries : availableCountries});
     
    }
  };
  const loadAvailableCountries = async () => {
    if (roleId != null) {
      let fetchCountries: IFetchDomains = {
        roleId: roleId,
        adminId: auth.idTokenClaims.sub,
        level: show,
        queryPage: 1,
        itemsPerPage: 10000,
        quickFilter: quickFilter,
        excludeEmailDomain: includeInternalUser ? "" : "skf.com",
        lastLoginFilter: lastLoginFilter,
        createdDateFilter: createdDateFilter,
      };
     let countryResponse = await dispatch(countriesAsync(fetchCountries)).unwrap();
   
     setAvailableCountries(countryResponse?.data);
    // setGlobalRolesUserFilter({...globalRoleUserFilters , includeInternalUser : includeInternalUser, availableDomains : availableEmailDomains  , availableCountries : countryResponse?.data});
     
    }
  };

  const filterUsers = () => {
    if (!initInProgress) {
     
      try {
       
        debouncedOnChange();
      } catch (err) {
        console.error(err);
      }
    }
  };
  const applyFilter = () => {
    setPage(1);
    setLastLoginFilter(globalRoleUserFilters.lastLoginDateFilter);
    setCreatedDateFilter(globalRoleUserFilters.creatonDateFilter);
    setFilteredDomains(globalRoleUserFilters.filteredDomains);
    setFilteredCountries(globalRoleUserFilters.filteredCountries);
    setIncludeInternalUser(globalRoleUserFilters.includeInternalUser);
    debouncedOnChange();
  };
  const debouncedOnChange = useDebounce(() =>  loadUsers(false, false, sortingValues,page), 500);
  const debouncedOnFilterChange = useDebounce(filterUsers, DEBOUNCE_DELAY);
  //for onChange on the search and other filters
  useEffect(() => {
       
      if ( (  quickFilter?.length > 2 || quickFilter?.length == 0 )) {
          // debouncedOnChange()
          if(initialLoadingComplete){
            setPage(1);
            setGlobalRolesUserFilter({...globalRoleUserFilters , includeInternalUser : includeInternalUser , availableDomains : availableEmailDomains, availableCountries: availableCountries});
          }
      initialLoadingComplete ?  debouncedOnChange() : loadUsers(  true , true, sortingValues,1) ;
      }
    
    
  }, [quickFilter , includeInternalUser, sortingValues]);
  useEffect(() => {
    setGlobalRolesUserFilter({...globalRoleUserFilters , includeInternalUser : includeInternalUser , availableDomains : availableEmailDomains, availableCountries: availableCountries});

  },[availableEmailDomains, availableCountries]); 
  const roleName = location.state.roleName;

  const reloadCallback = useCallback(
    () => { 
            globalRoleUserFilters.includeInternalUser = includeInternalUser; 
            debouncedOnChange(),
            setPage(1);
     },
    
    [sortingValues, quickFilter, includeInternalUser, page , debouncedOnChange , setPage ]
  );

  const itemsPerPage = () => {
    let cellHeight = 48;

    let height = (window.innerHeight - 325);
    return Math.floor(height / cellHeight);
  };

  const handleUserInputChange = (value: string) => {
    setQuickFilter(value);
    setPage(1);
  };




  const navigate = useNavigate();

  const gotoRoute = (route: string) => {
    navigate(route);
  };
  // -------------------Need to add in common js --------------------------
 
  // ----------------------------------------------------------------------

  //Table definition
  const sortableFields = [
    {"field": "firstName", "label":"First name", "order": sortingFirstName, "setOrderCallback": setSortingFirstName, "width": "150"}, 
    {"field": "lastName",  "label":"Last name","order": sortingLastName,  "setOrderCallback": setSortingLastName, "width": "150"}, 
    {"field": "email",     "label":"Email","order": sortingEmail, "setOrderCallback": setSortingEmail, "width": "150"}, 
    {"field": "country",   "label":"Country","order": sortingCountry, "setOrderCallback": setSortingCountry, "width": "90"}, 
    {"field": "company",   "label":"Company","order": sortingCompany, "setOrderCallback": setSortingCompany, "width": "150"},
    {"field": null , "label":"Roles", "width": "400", className:"TagCell"}, 
    {"field": "lastLogin", "label":"Last login","order": sortingLastLogin, "setOrderCallback": setSortingLastLogin, "width": "150"}, 
    {"field": "creationDate", "label":"Creation date", "order": sortingCreationDate, "setOrderCallback": setSortingCreationDate, "width": "150"}, 
    {"field": null , "label":"More", "width": "400", className:"TagCell"}, 
  ]
  
  //End table definition
  //Create table data
  useEffect(() => {
   // No APi is calling only forming the table row
   setUserRows(createUsersRows(stateUsers, auth, Number(roleId), lastItemRef, reloadCallback, isSuperAdmin));
  }, [stateUsers ]);
 
  return (
    <>
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={[
          {
            label: "Global roles",
            href: "/globalroles",
            onClick: (e, route) => gotoRoute(route),
          },
          {
            label: roleName,
            href: "/globalroles/" + roleId,
            onClick: (e) => {
              e.stopPropagation();
            },
          },
        ]}
        feSize="sm"
        feType="interactive"
        className="breadcrumbs"
      />{" "}
      <Spacer />
      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div className="contentArea">
            <Flexbox feGap="md" feJustifyContent="space-between">
              <div style={{ width: "60rem", height: "50px" }}>
                <Search
                  className="userSearch"
                  feLabel=""
                  feResetButton={{
                    "aria-label": "Rensa",
                    onClick: () => setQuickFilter(""),
                  }}
                  placeholder={placeHolderText}
                  onChange={(_, value) => {
                    setQuickFilter(value);
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === "Enter") {
                      handleUserInputChange(quickFilter);
                    }
                  }}
                  value={quickFilter}
                  disabled={searchStatus !== "idle"}
                />
                <div className="filterTag">
                  <RolesUserFilterTags
                    setIncludeInternalUser={setIncludeInternalUser}
                    filterData={globalRoleUserFilters}
                  />
                </div>
              </div>
              <GlobalRolesUserFilter
                filterData={globalRoleUserFilters}
                updateOnApply={applyFilter}
                setIncludeInternal={setIncludeInternalUser}
              />
            </Flexbox>

            <Spacer feSpacing="xxs" />
            <GridItem
              feColspan={{
                desktop: 12,
                mobile: 12,
                tablet: 12,
              }}
            >
              <div
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  padding: "0.1rem",
                  textAlign: "left",
                  height: "calc(100vh - 15.9rem)"
                }}
              >
                {/* {searchStatus === "loading" && <Loader />} */}
                {searchStatus === "failed" && (
                  <p>Search failed. Please logout and logon again</p>
                )}

                     <CustomTable rawData={stateUsers} dataRows = {userRows} headerCells={sortableFields} setSortingValuesCallback={setSortingValues}/>
             
                 {loading &&  <div style={{display:'flex' , justifyContent: 'center', position:'absolute', left:'50%', top:'50%'}}>   <Loader  />   </div>}
                
              </div>
              <Flexbox  feAlignItems="center" feJustifyContent="flex-end">
              <span> {stateUsers.length } / {stateUsersTotal} </span>
              </Flexbox>
            </GridItem>
            
          </div>
        </GridItem>
      </Grid>
    </>
  );
}
