import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";

import PageLayout from "./features/common/PageLayout";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  ITesseractRoles,
  checkTesseractRoles,
  resetTesseractAuth,
  selectAllowed,
  selectAuth,
  setAuth,
  setBlockNonSKFUser,
  setTesseractRole,
  setTesseractRoleStatus,
  tesseractRoles
} from "./features/sso/ssoSlice";
import { Applications } from "./features/applications/Applications";

import { Route, Routes, useRoutes } from "react-router-dom";
import ApplicationDetails from "./features/app-details/ApplicationDetails";
import { Grid, GridItem } from "@skf-internal/ui-components-react";
import Sidebar from "./features/common/Sidebar";
import GlobalRoles from "./features/global-roles/GlobalRoles";
import GlobalGroups from "./features/global-groups/GlobalGroups";
import GroupUsers from "./features/group-users/GroupUsers";
import Users from "./features/users/Users";
import UserDetails from "./features/users/UserDetails";
import { useLocation } from "react-router-dom";

import GlobalRoleUsers from "./features/global-roles/GlobalRoleUsers";
import AppRoleUsers from "./features/app-details/AppRoleUsers";
import GlobalRoleAdmin from "./features/global-roles-admin/GlobalRoleAdmin";
import Requests from "./features/approvals/Requests";
import LogoutComponent from "./features/sso/LogoutComponent";
function App() {
  if (process.env.REACT_APP_TESSERACT_ENV != "Production") {
    document.title = "Tesseract" + " - " + process.env.REACT_APP_TESSERACT_ENV;
  } else {
    document.title = "Tesseract";
  }
  const { instance, accounts } = useMsal();

  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const tesseractAllowed = useAppSelector(selectAllowed);
  const tessRoles = useAppSelector(tesseractRoles);
  const bodyRef = useRef(null);

  const loginAttributes = () => {
    let result = {popupWindowAttributes: {
      popupPosition: {
        top: window.screenTop + 50,
        left: window.screenLeft + (window.outerWidth - 768) / 2,
      },
      popupSize: {
        height: "772px",
        width: "768px",
      },
     
    }, 
    extraQueryParameters:{}}; 
    if (process.env.REACT_APP_RESTRICT_LOGIN === "true" ||process.env.REACT_APP_TESSERACT_ENV === "Production" 
      || process.env.REACT_APP_TESSERACT_ENV === "QA") {
        result.extraQueryParameters = {"domain_hint": "skfgroup.onmicrosoft.com"}; 
      }

    return result; 
  }

  const handleLogin = (instance: {
    loginPopup: (popupWindowAttributes: any) => Promise<any>;
  }) => {
    

     instance
      .loginPopup(loginAttributes())
      .then((resp) => {
        storeAuth(resp);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  function storeAuth(auth: any) {
    dispatch(setAuth(auth));
   }

   useEffect(() =>{
    if (auth?.idTokenClaims != null && tesseractAllowed !== "blocked_notskf"){

      let tessRolesSession = sessionStorage.getItem("tesseractRoles"); 
      
      if (tessRolesSession){
        let tessRolesObject: ITesseractRoles = JSON.parse(tessRolesSession); 
        if (tessRolesObject.adminId == auth.idTokenClaims?.sub){
          dispatch(setTesseractRole(tessRolesObject)); 
          return;
        }
      }

      dispatch(checkTesseractRoles(auth.idTokenClaims?.sub));
    }
   }, [auth])

   useEffect(() =>{
    if (tessRoles?.adminId !== ""){
      sessionStorage.removeItem("tesseractRoles"); 
      if (tessRoles?.isAllowedToUserTesseract){
        sessionStorage.setItem("tesseractRoles", JSON.stringify(tessRoles)); 
      }
    }
   }, [tessRoles])
  useEffect(() => {
    dispatch(setBlockNonSKFUser(process.env.REACT_APP_RESTRICT_LOGIN === "true" ||process.env.REACT_APP_TESSERACT_ENV === "Production" 
      || process.env.REACT_APP_TESSERACT_ENV === "QA" ));
    
    chkActiveSession();
   }, []);
/*
  instance.handleRedirectPromise().then((resp) => {
    if (auth == undefined || auth.length == 0) {
      if (resp) {
        storeAuth(resp);
        console.log(auth);
      }
    }
  });*/
  
  const chkActiveSession = async () => {
    try {

      if (accounts.length > 0) {
        // Try to do a silent autnehtication
        const silentRequest:any = {
          account: accounts[0],
        };
        const response = await instance.acquireTokenSilent(silentRequest);
        storeAuth(response);
      } else {
        dispatch(setTesseractRoleStatus("idle"));  
      }
    } catch (error) {
      <LogoutComponent instance={instance} />
      console.error("Error when acquireTokenSilent:", error);
    }
  };

  const location = useLocation();
  let x = window.location.href;
  if (
    location.pathname.indexOf("/globalgroups") == -1 &&
    location.pathname.indexOf("/group-users") == -1
  ) {
    localStorage.removeItem("GlobalGroups_quickfilter");
    localStorage.removeItem("GlobalGroups_page");
  }

  return (
    <PageLayout onLogin={handleLogin}>
      <Grid className="body" ref={bodyRef}>
        <React.Fragment key=".0">
          <GridItem
            feColspan={{
              desktop: 1,
              mobile: 1,
              tablet: 1,
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(255,255,255)",
                padding: "1rem",
                textAlign: "center",
                minHeight: "38rem",
                height: "100%",
              }}
            >
              <Sidebar bodyRef={bodyRef} />
            </div>
          </GridItem>
          <GridItem
            feColspan={{
              desktop: 11,
              mobile: 11,
              tablet: 11,
            }}
          >
            <AuthenticatedTemplate>
              <Routes>
                {/* <Route  path ="/" element ={<> <p> Home Page </p></>} /> */}
                <Route path="/app" element={<Applications />} />
                <Route path="/app/:appId" element={<ApplicationDetails />} />
                <Route
                  path="/app/:appId/roles/:roleId/users"
                  element={<AppRoleUsers />}
                />
                <Route path="/globalroles" element={<GlobalRoles />} />
                <Route
                  path="/globalroles/:roleId"
                  element={<GlobalRoleUsers />}
                />
                <Route path="/globalgroups" element={<GlobalGroups />} />
                <Route path="/group-users/:groupId" element={<GroupUsers />} />
                <Route path="/requests" element={<Requests />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/:userId" element={<UserDetails />} />
                <Route
                  path="/global-role-admin"
                  element={<GlobalRoleAdmin />}
                />
              </Routes>
            </AuthenticatedTemplate>
          </GridItem>
        </React.Fragment>
      </Grid>
    </PageLayout>
  );
}

export default App;
