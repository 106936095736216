import axios from "axios";
import { fetchAccessToken } from "../common/Common";

//axios.defaults.headers.common["x-functions-key"] =
//  process.env.REACT_APP_AUTH_TOKEN || "";

/*
function fetchAccessToken() {
   return new Promise((resolve) => {
   msalInstance.acquireTokenSilent(silentRequest)
             .then((response) => { resolve(response.accessToken)})
      });
  }
*/

export async function searchUserByEmail(type: string, data: string[]) {
  const payload = {
    type: type,
    data: data,
  };

  let accessToken = await fetchAccessToken();

  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/skfsso/findusers`,
    payload
  );
}
export async function searchUser(query: string) {
  const payload = {
    query: query,
    includeMarkedForDeletion: true,
  };

  let accessToken = await fetchAccessToken();

  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/skfsso/searchusers`,
    payload
  );
}
export async function listGlobalRolesByUser(
  userId: string,
  adminId: string,
  page: number
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  if (page == 1) {
    return axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admin/${adminId}/users/${userId}/globalroles/?itemsPerPage=100`
    );
  } else {
    return axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admin/${adminId}/users/${userId}/globalroles/?page=${page}`
    );
  }
}

export async function listApplicationRolesByUser(
  userId: string,
  adminId: string,
  page: number
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  if (page == 1) {
    return axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admin/${adminId}/users/${userId}/applicationroles/`
    );
  } else {
    return axios.get(
      `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/admin/${adminId}/users/${userId}/applicationroless/?page=${page}`
    );
  }
}

export async function listGlobalRolesTicketsByUser(userId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/globalroles/tickets/${userId}`
  );
}

export async function listApplicationRolesTicketsByUser(userId: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/requests/${userId}`
  );
}

export async function detachRoleFromUser(
  userId: string,
  roleId: number,
  payload: any
) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_ENDPOINT}/api/v1/tesseract/users/${userId}/roles/${roleId}`,
    payload
  );
}

//when the user is marked for deleted we need to use this api to make it unmarked for delition
export async function reactivateUser(id: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.post(
    `${process.env.REACT_APP_UPW_ENDPOINT}/api/v1/skfsso/users/${id}/reactivate`
  );
}

export async function changeEmail(id: string, userName: string) {
  let accessToken = await fetchAccessToken();
  axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  return axios.patch(
    `${process.env.REACT_APP_ENDPOINT_FUNCTIONAPP}/api/v1/user/${id}`,
    { userName }
  );
}
