import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchGlobalGroups, createGlobalGroup } from "./globalGroupsAPI";

export interface ICreateGlobalGroup {
  data: IGlobalGroupData;
}

export interface IGlobalGroupData {
  globalGroupId: number;
  globalGroupName: string;
  userGlobalGroups: ISearchUser[];
  globalGroupDescription: string;
}
export interface IGlobalGroup {
  data: IGlobalGroupData[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}

export interface IGlobalGroupSearch {
  adminId: string;
  filteredUsers: string[];
  filteredCountries: string[];
  page: number;
  itemsPerPage: number;
  sortingField: string;
  order: string;
  quickSearch: string;
  viewType: string;
}

export interface GlobalGroupState {
  groups: IGlobalGroup;
  createdGroup: IGlobalGroup;
  status: "idle" | "loading" | "failed";
}

export interface ISearchUser {
  id: string;
  givenName: string;
  surName: string;
  userName: string;
  companyName: string;
  city: string;
}

const initialState: GlobalGroupState = {
  groups: { data: [], page: 0, page_size: 0, total: 0, last_page: 0 },
  createdGroup: {
    data: [
      {
        globalGroupId: 0,
        globalGroupName: "",
        globalGroupDescription: "",
        userGlobalGroups: [],
      },
    ],
    page: 0,
    page_size: 0,
    total: 0,
    last_page: 0,
  },
  status: "idle",
};

export const globalGroupsAsync = createAsyncThunk(
  "fetchGlobalGroups",
  async (search: IGlobalGroupSearch) => {
    const response = await fetchGlobalGroups(
      search.adminId,
      search.filteredUsers,
      search.filteredCountries,
      search.page,
      search.itemsPerPage,
      search.sortingField,
      search.order,
      search.quickSearch,
      search.viewType
    );
    return response?.data;
  }
);

export const createGroupAsync = createAsyncThunk(
  "createGlobalGroup",
  async (createObj: ICreateGlobalGroup) => {
    let group: IGlobalGroupData = createObj.data;
    const response: any = await createGlobalGroup({
      GroupName: group.globalGroupName,
      GroupDescription: group.globalGroupDescription,
    });
    return response.data;
  }
);

export const globalGroupsSlice = createSlice({
  name: "globalGroups",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.groups = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(globalGroupsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(globalGroupsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.groups = action.payload;
      })
      .addCase(globalGroupsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createGroupAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createGroupAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.createdGroup = action.payload;
      })
      .addCase(createGroupAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setAuth } = globalGroupsSlice.actions;

export const selectStatus = (state: RootState) => state.globalGroups.status;
export const selectGlobalGroups = (state: RootState) =>
  state.globalGroups.groups;
export const createdGroup = (state: RootState) =>
  state.globalGroups.createdGroup;
export default globalGroupsSlice.reducer;
