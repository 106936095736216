import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchGroupUsers, searchUserByEmail } from "./groupUsersAPI";

export interface GroupUsersState {
  group: IGroup;
  searchedUser: ISearchUser[] | [];
  status: "idle" | "loading" | "failed";
}
//(userprofile id, username, firstname, lastname and email
export interface IUser {
  userProfileId: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  lastLogon: string;
}
export interface IGroup {
  groupId: number;
  groupName: string;
  groupDescription: string;
  allowedToEdit: boolean;
  users: IUser[];
}

export interface IGlobalGroup {
  globalGroupName: string;
  userGlobalGroups: IUser[];
  globalGroupDescription: string;
}
export interface ISearchUser {
  id: string;
  givenName: string;
  surName: string;
  userName: string;
  companyName: string;
  city: string;
  email: string;
}

export interface IFetchGroupUser {
  groupId: string;
  adminId: string;
}
export interface ISearchUserByEmail {
  email: string;
}
const initialState: GroupUsersState = {
  group: {
    groupId: 0,
    groupName: "",
    groupDescription: "",
    users: [],
    allowedToEdit: false,
  },
  searchedUser: [],
  status: "idle",
};
var inGroupId: string;

export const groupUsersAsync = createAsyncThunk(
  "fetchGroupUsers",
  async (fetch: IFetchGroupUser) => {
    inGroupId = fetch.groupId;
    const response = await fetchGroupUsers(fetch.groupId, fetch.adminId);
    return response.data;
  }
);

export const searchUserByEmailAsync = createAsyncThunk(
  "searchUserByEmail",
  async (search: ISearchUserByEmail) => {
    const response: any = await searchUserByEmail(search.email);
    return response.data;
  }
);

export const groupUsersSlice = createSlice({
  name: "groupUsers",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSearch: (state, action: PayloadAction<any>) => {
      state.searchedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(groupUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(groupUsersAsync.fulfilled, (state, action) => {
        state.status = "idle";

        state.group = action.payload;
      })
      .addCase(groupUsersAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(searchUserByEmailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchUserByEmailAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.searchedUser = action.payload.usersFound;
      })
      .addCase(searchUserByEmailAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { resetSearch } = groupUsersSlice.actions;

export const selectStatus = (state: RootState) => state.groupUsers.status;
export const selectGroup = (state: RootState) => state.groupUsers.group;

export const selectSearchedUser = (state: RootState) =>
  state.groupUsers.searchedUser;

export default groupUsersSlice.reducer;
