import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  DialogTitle,
} from "@mui/material";
import UserDetails from "./UserDetails";
import { Icon, Button } from "@skf-internal/ui-components-react";

type ModalProps = {
  isOpen: boolean;
  handleModalClose: () => void;
  userId: string | "";
};
const UserDetailsModal = (props: ModalProps) => {
  return (
    <div>
      <>
        <Dialog
          onClose={props.handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={props.isOpen}
          fullScreen={true}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            User Profile
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={props.handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "red",
            }}
          >
            <Icon feSize="lg" feIcon="close" />
          </IconButton>
          <DialogContent dividers>
            <UserDetails userId={props.userId} />
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleModalClose} feSize="sm">
              Go Back
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};

export default UserDetailsModal;
