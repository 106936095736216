import {
  Button,
  Loader,
  Tabs,
  Tag,
  useToast,
} from "@skf-internal/ui-components-react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../sso/ssoSlice";
import {
  approvalsApplicationPendingAsync,
  approvalsApplicationResolvedAsync,
  approvalsGlobalRoleResolvedAsync,
  IApprovalReqItem,
  selectApplicationPendingRequests,
  selectApplicationResolvedRequests,
  selectGlobalRolesResolvedRequests,
  statusAppTicket,
  statusGlobalTicket,
} from "./approvalsSlice";

import { displayDate, textToColor } from "../common/Common";
import ReasonPopup from "../common/ReasonPopup";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

export interface IResolvedRequestProps {}
export interface IRequestRow {
  requestId: string;
  email: string;
  firstName: string;
  lastName: string;
}
export interface IPayload {
  requestId: string;
  approverUserId: string;
  status: "Approved" | "Denied";
  reason: string;
}

export default function ResolvedRequests(props: IResolvedRequestProps) {
  const auth = useAppSelector(selectAuth);
  const loadingstatusApp = useAppSelector(statusAppTicket);
  const loadingstatusGlobal = useAppSelector(statusGlobalTicket);

  const applicationApprovalsResolved = useAppSelector(
    selectApplicationResolvedRequests
  );
  const globalRolesResolved = useAppSelector(selectGlobalRolesResolvedRequests);

  const dispatch = useAppDispatch();
  const [resolved, setResolved] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { toasts, addToast, removeToast } = useToast();
  const [tickets, setTickets] = useState<IApprovalReqItem[]>([]);
  let  resolvedAppTickets: IApprovalReqItem[] = [];
  let resolvedGlobalTickets: IApprovalReqItem[]= [];

  useEffect(() => {
    loadTickets();
  }, []);

  function loadTickets() {
    setTickets([]);
    console.log("Reload table");
    dispatch(
      approvalsApplicationResolvedAsync({
        userId: auth.idTokenClaims.sub,
      })
    );
    dispatch(
      approvalsGlobalRoleResolvedAsync({
        userId: auth.idTokenClaims.sub,
      })
    );
  }
  

  useEffect(() => {
    if (loadingstatusGlobal === "idle" && loadingstatusApp === "idle") {
      const resolved = globalRolesResolved.filter(
        (req: IApprovalReqItem) => {
          if (req.status === "Approved" || req.status === "Denied") {
            return req;
          }
        }
      );
      resolvedGlobalTickets = resolved;
      const appResolved = applicationApprovalsResolved.filter(
        (req: IApprovalReqItem) => {
          if (req.status === "Approved" || req.status === "Denied") {
            return req;
          }
        }
      );  
      resolvedAppTickets = appResolved;
      concatLists();
    }
  }, [globalRolesResolved, applicationApprovalsResolved]);

  function concatLists() {
   
    let list = resolvedGlobalTickets.concat(resolvedAppTickets);
        
    list.sort(
      (a: IApprovalReqItem, b: IApprovalReqItem) =>
        new Date(a.requestedDate).getTime() -
        new Date(b.requestedDate).getTime()
    );
    setTickets(list);
  }
  function displayResolvedBy(item: IApprovalReqItem) {
      return item.approverUsername??item.approverUserName; 
  }
  
  const columnsResolved: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Full name",
      description: "Full name of requester",
      sortable: true,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
      renderCell: (params) => {
        return (
          <>
            <span
              title={`${params.row.firstName || ""} ${
                params.row.lastName || ""
              }`}
            >{`${params.row.firstName || ""} ${
              params.row.lastName || ""
            }`}</span>
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 250,
      renderCell: (params) => {
        return (
          <>
            <span title={`${params.row.email || ""}`}>{`${
              params.row.email || ""
            } `}</span>
          </>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 80,
      renderCell: (params) => {
        let color = textToColor(params.row.requestedRole);
        return (
          <>
            <span title={params.row.countryName}>{params.row.country}</span>
          </>
        );
      },
    },
    {
      field: "company",
      headerName: "Company",
      width: 180,
      renderCell: (params) => {
        return (
          <>
            <span title={params.row.company || "Company"}>
              {params.row.company}
            </span>
          </>
        );
      },
    }, 
    {
      field: "application",
      headerName: "Application name",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <span title={`${params.row.applicationName || ""}`}>{`${
              params.row.applicationName || ""
            } `}</span>
          </>
        );
      },
    },
    {
      field: "requestedRole",
      headerName: "Requested role",
      width: 230,
      renderCell: (params) => {
        return (
          <>
            <Tag feType="outlined" title={params.row.requestedRole}>
              {params.row.requestedRole}
            </Tag>
          </>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        let color: "blue" | "green" | "orange" | "purple" | "red" | "yellow";
        let reason = "";
        color = "green";
        if (params.row.status != "Pending") {
          reason = params.row.reason;
          if (reason == undefined) {
            reason = "";
          }
        }

        if (params.row.status == "Denied") {
          color = "red";
        }
        if (params.row.status == "Removed") {
          color = "purple";
        }
        return (
          <>
            <Tag feType="filled" feColor={color} title={params.row.status}>
              {params.row.status}
            </Tag>
            {reason != "" && (
              <>
                <ReasonPopup reason={reason} />
              </>
            )}
          </>
        );
      },
    },
    {
      field: "resolvedDate",
      headerName: "Resolved date",
      width: 195,
      renderCell: (params) => {
        const formatted = displayDate(params.row.resolvedDate);
        return (
          <>
            <span title={formatted}>{formatted}</span>
          </>
        );
      },
    },

    {
      field: "approverUserName",
      headerName: "Approver",
      width: 250,
      renderCell: (params) => {
        return (
          <>
            <span title={params.row.approverUserName}>
              {displayResolvedBy(params.row)}
            </span>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div
        style={{ height: "calc(100vh - 275px)", width: "100%" }}
        className="datagrid"
      >
        <DataGrid
          rows={tickets}
          columns={columnsResolved}
          sx={{
            display:
              loadingstatusApp === "idle" && loadingstatusGlobal === "idle"
                ? "flex"
                : "none",
            fontFamily: "SKFSans, Arial, sans-serif",
            fontSize: "12px",
          }}
          autoPageSize={true}
          getRowId={(row) => row.requestId}
          initialState={{
            sorting: {
              sortModel: [{ field: "resolvedDate", sort: "desc" }],
            },
          }}
          paginationMode="client"
        />
        <div className="loader">
          <Loader
            hidden={
              loadingstatusApp === "idle" && loadingstatusGlobal === "idle"
            }
          />
        </div>
      </div>
    </div>
  );
}
