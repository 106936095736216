import { Switch, Tag } from "@skf-internal/ui-components-react";
import DateFilterTag from "./DateFilterTag";
import { IDateFilterValue } from "./DateFilter";

export interface IRolesUserFilterTagProps {
  filterData: IRolesUserFilter;
  setIncludeInternalUser: any;
}
export interface IRolesUserFilter {
  includeInternalUser: boolean;
  lastLoginDateFilter: IDateFilterValue;
  creatonDateFilter: IDateFilterValue;
  filteredDomains: string[];
  filteredCountries: string[];
  availableDomains: IDomainRecord[];
  availableCountries: ICountryRecord[];
}

export interface IDomainRecord {
  domain: string;
  count: number;
}
export interface ICountryRecord {
  countryName: string;
  countryIso: string;
  count: number;
}


export default function RolesUserFilterTags(props: IRolesUserFilterTagProps) {
  const { filterData, setIncludeInternalUser } = props;

  const maxNbrOfDomainTags = () => {
    let max = 4;

    if (filterData.lastLoginDateFilter?.type?.length > 0) {
      max--;
    }

    if (filterData.creatonDateFilter?.type?.length > 0) {
      max--;
    }

    return max;
  };

  const domainFilters = () => {
    let domains = filterData.filteredDomains
      .map((domains) => {
        return "@" + domains;
      })
      .join(",\n ");

    return (
      <>
        {filterData.filteredDomains != null &&
          filterData.filteredDomains.map((domain, i) => {
            if (i < maxNbrOfDomainTags()) {
              return <Tag feType="outlined">{"@" + domain}</Tag>;
            }
          })}
        {filterData.filteredDomains != null &&
          filterData.filteredDomains.length > maxNbrOfDomainTags() && (
            <span
              className="additionalfiltertags"
              aria-label={"All selected domains:\n\n" + domains}
            >
              <span className="tagcounter">
                +{filterData.filteredDomains.length - maxNbrOfDomainTags()}
              </span>
            </span>
          )}
      </>
    );
  };
  const countryFilters = () => {
    let countries = filterData.filteredCountries
      .map((country) => {
        return country;
      })
      .join(",\n ");

    return (
      <>
        {filterData.filteredCountries != null &&
          filterData.filteredCountries.map((country, i) => {
            if (i < maxNbrOfDomainTags()) {
              let countryObject = filterData.availableCountries.find(p => p.countryIso == country)

              return <Tag feType="outlined">{countryObject?.countryName??"Country not set"}</Tag>;
            }
          })}
        {filterData.filteredCountries != null &&
          filterData.filteredCountries.length > maxNbrOfDomainTags() && (
            <span
              className="additionalfiltertags"
              aria-label={"All selected countries:\n\n" + countries}
            >
              <span className="tagcounter">
                +{filterData.filteredCountries.length - maxNbrOfDomainTags()}
              </span>
            </span>
          )}
      </>
    );
  };
  return (
    <>
      <Switch
        feSize="sm"
        feLabel="Show @skf.com users"
        onChange={(value: any) =>
          setIncludeInternalUser(!filterData.includeInternalUser)
        }
        checked={filterData.includeInternalUser}
      />
      <DateFilterTag
        title={"Last login"}
        filterValue={filterData.lastLoginDateFilter}
      />
      <DateFilterTag
        title={"Created at"}
        filterValue={filterData.creatonDateFilter}
      />
      {domainFilters()}
      {countryFilters()}
    </>
  );
}
