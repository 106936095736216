import {
  Breadcrumbs,
  BreadcrumbsItem,
  Grid,
  GridItem,
  Spacer,
  Tabs,
} from "@skf-internal/ui-components-react";
import React from "react";
import { useState } from "react";
import PendingRequests from "./PendingRequests";
import ResolvedRequests from "./ResolvedRequests";

export default function Requests() {
  const [activeTab, setActiveTab] = useState(0);
  const onTabClick = (_: any, index: React.SetStateAction<number>) => {
    setActiveTab(index);
  };

  return (
    <>
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={[
          {
            label: "Requests",
          },
        ]}
        feSize="sm"
        feType="static"
        className="breadcrumbs"
      />
      <Spacer />
      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div className="contentArea">
            <Tabs
              feType="expanded"
              feSelected={activeTab}
              onClick={onTabClick}
              feItems={[
                {
                  children: (
                    <div>
                      <Grid>
                        <React.Fragment key=".0">
                          <GridItem
                            feColspan={{
                              desktop: 10,
                              mobile: 10,
                              tablet: 10,
                            }}
                          ></GridItem>
                          <GridItem
                            feColspan={{
                              desktop: 2,
                              mobile: 2,
                              tablet: 2,
                            }}
                          ></GridItem>
                          <GridItem
                            feColspan={{
                              desktop: 12,
                              mobile: 12,
                              tablet: 12,
                            }}
                          >
                            {activeTab === 0 && <PendingRequests />}
                          </GridItem>
                        </React.Fragment>
                      </Grid>
                    </div>
                  ),
                  id: "pending",
                  label: "Pending",
                },
                {
                  children: (
                    <div>
                      <Grid>
                        <React.Fragment key=".0">
                          <GridItem
                            feColspan={{
                              desktop: 10,
                              mobile: 10,
                              tablet: 10,
                            }}
                          ></GridItem>
                          <GridItem
                            feColspan={{
                              desktop: 2,
                              mobile: 2,
                              tablet: 2,
                            }}
                          ></GridItem>
                          <GridItem
                            feColspan={{
                              desktop: 12,
                              mobile: 12,
                              tablet: 12,
                            }}
                          >
                            {activeTab === 1 && <ResolvedRequests />}
                          </GridItem>
                        </React.Fragment>
                      </Grid>
                    </div>
                  ),
                  id: "resolved",
                  label: "Resolved",
                },
              ]}
            />
          </div>
        </GridItem>
      </Grid>
    </>
  );
}
