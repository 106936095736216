import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchCollectionsForAdmin } from "./globalRolesAdminAPI";
import { ISearchUser } from "../global-roles/globalRolesSlice";

export interface IGlobalRoleAdmin {
  data: IGlobalRoleData[];
  page: number;
  page_size: number;
  total: number;
  last_page: number;
}

export interface IGlobalRoleData {
  roleId: number;
  roleName: string;
  roleDisplayName: string;
  totalUsers: number;
  roleDescription: string;
  isAdmin: boolean;
}
export interface IGlobalRoleCollections {
  roleCollections: IGlobalRoleCollection[];
}
export interface IGlobalRoleCollection {
  roleCollectionId: number;
  roleCollectionName: string;
  roles: IGlobalRoleData[];
  userProfiles: ISearchUser[];
}

export interface GlobalRoleState {
  roleCollections: IGlobalRoleCollection[];
  status: "idle" | "loading" | "failed";
}

export interface IFetchRoleCollection {
  adminId: string;
}
const initialState: GlobalRoleState = {
  roleCollections: [],
  status: "idle",
};

export const globalRoleCollectionAsync = createAsyncThunk(
  "fetchGlobalRoleCollection",
  async (collection: IFetchRoleCollection) => {
    const response = await fetchCollectionsForAdmin(collection.adminId);
    return response.data;
  }
);
export const globalRolesAdminSlice = createSlice({
  name: "globalRolesAdmin",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {},
    resetSearch: (state, action: PayloadAction<any>) => {},
  },
  extraReducers: (builder) => {
    builder

      .addCase(globalRoleCollectionAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(globalRoleCollectionAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.roleCollections = action.payload.roleCollections;
      })
      .addCase(globalRoleCollectionAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setAuth, resetSearch } = globalRolesAdminSlice.actions;

export const selectStatus = (state: RootState) => state.globalRoleAdmin.status;
export const selectRoleCollections = (state: RootState) =>
  state.globalRoleAdmin.roleCollections;

export default globalRolesAdminSlice.reducer;
