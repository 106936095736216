import {
  Button,
  Flexbox,
  Icon,
  IconButton,
  Link,
  Spacer,
} from "@skf-internal/ui-components-react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

export interface ISidebarProps {
  bodyRef: any;
}

export default function Sidebar(props: ISidebarProps) {
  const { bodyRef } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const gotoRoute = (route: any) => {
    navigate(route);
  };
  const [open, setOpen] = useState(true);
  const [toggelClass, setToggleClass] = useState("");
  const toggleMenu = () => {
    if (open) {
      bodyRef.current.classList.add("bodySmall");
      setToggleClass("sidebarMenuToggleSmall");
    } else {
      bodyRef.current.classList.remove("bodySmall");
      setToggleClass("");
    }
    setOpen(!open);
  };

  const path = location.pathname;
  return (
    <>
      {open && (
        <div>
          <div className="sidebarMenuToggle" title="Minimize menu">
            <IconButton
              aria-label="Settings"
              as="button"
              feIcon="doubleChevronLeft"
              onClick={toggleMenu}
            />
          </div>
          <Spacer feSpacing="xxl" />
          <Flexbox feFlexDirection="column" feGap="sm">
            <Link
              as="button"
              onClick={(_, route) => gotoRoute(route)}
              route="/app"
              className={`appMenuItem SidebarMenuItem${
                path == "/app" || path.startsWith("/app-") ? "Selected" : ""
              }`}
            >
              Apps overview
            </Link>
            <Link
              as="button"
              feIcon={{ feIcon: "hierarchy", position: "left" }}
              onClick={(_, route) => gotoRoute(route)}
              route="/globalroles"
              className={`SidebarMenuItem${
                path.startsWith("/globalroles") ? "Selected" : ""
              }`}
            >
              Global roles
            </Link>
            <Link
              as="button"
              feIcon={{ feIcon: "routes", position: "left" }}
              onClick={(_, route) => gotoRoute(route)}
              route="/globalgroups"
              className={`SidebarMenuItem${
                path == "/globalgroups" || path.startsWith("/group-users")
                  ? "Selected"
                  : ""
              }`}
            >
              Groups
            </Link>
            <Link
              as="button"
              feIcon={{ feIcon: "user", position: "left" }}
              onClick={(_, route) => gotoRoute(route)}
              route="/users"
              className={`SidebarMenuItem${
                path.startsWith("/users") ? "Selected" : ""
              }`}
            >
              Users
            </Link>
            <Link
              as="button"
              feIcon={{ feIcon: "reply", position: "left" }}
              onClick={(_, route) => gotoRoute(route)}
              route="/requests"
              className={`SidebarMenuItem${
                path == "/requests" ? "Selected" : ""
              }`}
            >
              Requests
            </Link>
          </Flexbox>
        </div>
      )}

      {!open && (
        <div>
          <div
            className="sidebarMenuToggle sidebarMenuToggleSmall"
            title="Maximize menu"
          >
            <IconButton
              aria-label="ToggelSidebar"
              as="button"
              feIcon="doubleChevronRight"
              onClick={toggleMenu}
            />
          </div>
          <Spacer feSpacing="xxl" />
          <Flexbox feFlexDirection="column" feGap="sm">
            <span
              aria-label="Application"
              title="Apps overview"
              onClick={() => gotoRoute("/app")}
              className={`appMenuItemSmall SidebarMenuItemSmall${
                path == "/app" || path.startsWith("/app-") ? "Selected" : ""
              }`}
            />
            <span
              aria-label="Global roles"
              title="Global roles"
              onClick={() => gotoRoute("/globalroles")}
              className={`SidebarMenuItemSmall${
                path.startsWith("/globalroles") ? "Selected" : ""
              }`}
            >
              <Icon feIcon="hierarchy" />
            </span>

            <span
              aria-label="Global groups"
              title="Global groups"
              onClick={() => gotoRoute("/globalgroups")}
              className={`SidebarMenuItemSmall${
                path == "/globalgroups" || path.startsWith("/group-users")
                  ? "Selected"
                  : ""
              }`}
            >
              <Icon feIcon="routes" />
            </span>
            <span
              aria-label="Users"
              title="Users"
              onClick={() => gotoRoute("/users")}
              className={`SidebarMenuItemSmall${
                path.startsWith("/users") ? "Selected" : ""
              }`}
            >
              <Icon feIcon="user" />
            </span>

            <span
              aria-label="Requests"
              title="Requests"
              onClick={() => gotoRoute("/requests")}
              className={`SidebarMenuItemSmall${
                path == "/requests" ? "Selected" : ""
              }`}
            >
              <Icon feIcon="reply" />
            </span>
          </Flexbox>
        </div>
      )}
    </>
  );
}
