import { loginRequest, silentRequest } from "../../authConfig";
import msalInstance from "../..";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import LogoutComponent from "../sso/LogoutComponent";

export function textToColor(value: any) {
  var hash = 0;
  var char;
  var i;

  if (value == null || value.length == 0) return "blue";

  for (i = 0; i < value.length; i++) {
    char = value.charCodeAt(i) / 2;
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  var c = hash % 6;
  if (c < 0) {
    c = c * -1;
  }

  let color: "blue" | "green" | "orange" | "purple" | "red" | "yellow";
  color = "blue";

  switch (c) {
    case 0: {
      color = "blue";
      break;
    }
    case 1: {
      color = "green";
      break;
    }
    case 2: {
      color = "orange";
      break;
    }
    case 3: {
      color = "purple";
      break;
    }
    case 4: {
      color = "red";
      break;
    }

    case 5: {
      color = "yellow";
      break;
    }
  }

  return color;
}

export function fetchAccessToken() {
  try {
    return new Promise((resolve) => {
      msalInstance.acquireTokenSilent(silentRequest).then((response) => {
        resolve(response.accessToken);
      });
    });
  } catch (error) {
    console.log("Silence token error where detected.");
    console.error(error);
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenPopup(loginRequest)
        .then((resp) => {
          return resp.accessToken;
        })
        .catch((err) => {
          console.error(err);
         <LogoutComponent instance={msalInstance} />
        });
    } else {
      console.error(error);
      <LogoutComponent instance={msalInstance} />
    }
  }
  return null;
}

function lang() {
  if (navigator.language != undefined) {
    return window.navigator.language;
  }
  return "sv-SE";
}

export const displayDate = (value: any) => {
  let langStr: string = lang();
  if (value == null) {
    return "";
  }
  value = value + "Z";
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const event = new Date(value);
  const formatted = event.toLocaleString(langStr, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  });
  return formatted;
};

export const displayOnlyDate = (value: any) => {
  let langStr: string = lang();
  if (value == null) {
    return "";
  }
  value = value + "Z";
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const event = new Date(value);
  const formatted = event.toLocaleString(langStr, {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  });
  return formatted;
};


export const displayOnlyTime = (value: any) => {
  let langStr: string = lang();
  if (value == null) {
    return "";
  }
  value = value + "Z";
  const event = new Date(value);
  const formatted = event.toLocaleString(langStr, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  });
  return formatted;
};