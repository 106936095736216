import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchTesseractRoles } from "./ssoAPI";

export interface SsoState {
  auth: any;
  isSuperAdmin: boolean;
  tesseractRoles: ITesseractRoles;
  tesseractRolesStatus: "notinitiated"| "idle" | "loading" | "failed";
  tesseractAllowed: "loggedout"| "allowed" | "blocked_notskf" | "blocked_norole" | "checkongoing"; 
  blockNonSKFUser: boolean; 
}

export interface ITesseractRoles {
  adminId: string;
  isAllowedToUserTesseract: boolean;
  isSuperAdmin: boolean;
  isUserManager: boolean;
  isApplicationAdmin: boolean;
  isApplicationApprover: boolean;
  isGlobalRoleAdmin: boolean;
  isGlobalRoleApprover: boolean;
}
const initialState: SsoState = {
  auth: [],
  isSuperAdmin: false,
  tesseractRolesStatus: "notinitiated",
  tesseractRoles: {
    adminId: "",
    isAllowedToUserTesseract: false,
    isSuperAdmin: false,
    isUserManager: false,
    isApplicationAdmin: false,
    isApplicationApprover: false,
    isGlobalRoleAdmin: false,
    isGlobalRoleApprover: false,
  },
  tesseractAllowed: "loggedout", 
  blockNonSKFUser: true
};
export const checkTesseractRoles = createAsyncThunk(
  "fetchTesseractRoles",
  async (userId: string) => {
    console.log("Tesseract  Roles Fetching ");
    const response = await fetchTesseractRoles(userId);
    return response.data;
  }
);
export const ssoSlice = createSlice({
  name: "sso",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.auth = action.payload;
      if(state.blockNonSKFUser && ((!state.auth.idTokenClaims?.idp?.toLowerCase().endsWith("skf.com") ||
      !(state.auth.idTokenClaims?.acct == 0))) ) {
        state.tesseractAllowed = "blocked_notskf"; 
      }
    },
    resetTesseractAuth: (state, action: PayloadAction<any>) => {
      state.tesseractAllowed = action.payload??"loggedout"; 
    },
    setBlockNonSKFUser: (state, action: PayloadAction<any>) => {
      state.blockNonSKFUser = action.payload??"true"; 
    },
    setTesseractRoleStatus: (state, action: PayloadAction<any>) => {
      state.tesseractRolesStatus = action.payload;
    },
    setTesseractRole: (state, action: PayloadAction<any>) => {
        console.log("Tesseract  Roles idle, from session store ");
        state.tesseractRoles = action.payload;
        state.tesseractRolesStatus = "idle";
        state.isSuperAdmin = state.tesseractRoles.isSuperAdmin;
        if (!state.tesseractRoles.isAllowedToUserTesseract){
          state.tesseractAllowed = "blocked_norole"; 
        } else {
          state.tesseractAllowed = "allowed"; 
        }

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkTesseractRoles.fulfilled, (state, action) => {
        console.log("Tesseract  Roles Idle ");
        state.tesseractRoles = action.payload;
        state.tesseractRolesStatus = "idle";
        state.isSuperAdmin = state.tesseractRoles.isSuperAdmin;
        if (!state.tesseractRoles.isAllowedToUserTesseract){
          state.tesseractAllowed = "blocked_norole"; 
        } else {
          state.tesseractAllowed = "allowed"; 
        }

      })
      .addCase(checkTesseractRoles.pending, (state) => {
        console.log("Tesseract  Roles Loading ");
        
        state.tesseractRolesStatus = "loading";
        state.tesseractAllowed = "checkongoing"
      })
      .addCase(checkTesseractRoles.rejected, (state) => {
        console.log("Tesseract  Roles Failed ");
        state.tesseractRolesStatus = "failed";
      });
  },
});

export const { setAuth, resetTesseractAuth , setTesseractRoleStatus, setBlockNonSKFUser, setTesseractRole} = ssoSlice.actions;

export const selectAuth = (state: RootState) => state.sso.auth;
export const selectAllowed = (state: RootState) => state.sso.tesseractAllowed;
export const isSuperAdmin = (state: RootState) => state.sso.isSuperAdmin;
export const tesseractRoles = (state: RootState) => state.sso.tesseractRoles;
export const tesseractRolesStatus = (state: RootState) =>
  state.sso.tesseractRolesStatus;

export default ssoSlice.reducer;
