import {
  Button,
  Dialog,
  Link,
  Text,
  Spacer,
  Flexbox,
} from "@skf-internal/ui-components-react";
import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { IUser, selectStatus } from "../group-users/groupUserSlice";

import { useNavigate } from "react-router-dom";
import { detachUserFromCollection } from "./globalRolesAdminAPI";
import { ISearchUser } from "../global-roles/globalRolesSlice";
import { IGlobalRoleCollection } from "./globalRolesAdminSlice";

export interface IRemoveAdminProps {
  collection: IGlobalRoleCollection | undefined;
  user: ISearchUser;
  callback: any;
}

export default function RemoveAdmin(props: IRemoveAdminProps) {
  const { collection, user, callback } = props;
  const status = useAppSelector(selectStatus);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const DEBOUNCE_DELAY = 750;

  const removeUserAsAdmin = () => {
    if (collection == null) {
      return;
    }
    detachUserFromCollection(user.id, collection.roleCollectionId)
      .then((res) => {
        setTimeout(() => {
          setIsOpen(false);
        }, 1000);
        callback();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <div>
      <Link
        as="button"
        feIcon={{ feIcon: "closeFault", position: "right" }}
        onClick={() => setIsOpen(true)}
        title="Remove user as admin"
      >
        {" "}
      </Link>
      <Dialog
        {...props}
        feInterruptive
        feTitle="Remove user"
        open={isOpen}
        cssWidth={"40rem"}
        cssHeight={"22rem"}
      >
        <div className="globalroleadminremove">
          <Text>
            You are currently removing user {user.email} from global role admin
            group "{collection?.roleCollectionName}".
          </Text>
          <Spacer feSpacing="md" />
          <Flexbox
            feGap="md"
            feJustifyContent="flex-end"
            className="modalButtons"
          >
            <Button feType="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => removeUserAsAdmin()}>Confirm</Button>
          </Flexbox>
        </div>
      </Dialog>
    </div>
  );
}
