import {
  Breadcrumbs,
  Grid,
  GridItem,
  Select,
  Spacer,
  Tabs,
} from "@skf-internal/ui-components-react";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import GlobalRoleAdminRoles from "./GlobalRoleAdminRoles";
import GlobalRoleAdminAdmins from "./GlobalRoleAdminAdmins";
import {
  IFetchRoleCollection,
  IGlobalRoleCollection,
  IGlobalRoleData,
  globalRoleCollectionAsync,
  selectRoleCollections,
} from "./globalRolesAdminSlice";
import { ISearchUser } from "../global-roles/globalRolesSlice";
import { selectAuth } from "../sso/ssoSlice";

export interface IGlobalRoleAdminProps {}
export default function GlobalRoleAdmin(props: IGlobalRoleAdminProps) {
  const dispatch = useAppDispatch();
  const roleCollections = useAppSelector(selectRoleCollections);
  const auth = useAppSelector(selectAuth);

  const [radioButtons, setRadioButtons] = useState<any[]>([]);

  const [collection, setCollection] = useState<IGlobalRoleCollection>();
  const [collectionName, setCollectionName] = useState("");
  const [collectionId, setCollectionId] = useState(-1);
  const [collectionRoles, setCollectionRoles] = useState<IGlobalRoleData[]>([]);
  const [collectionAdmins, setCollectionAdmins] = useState<ISearchUser[]>([]);

  useEffect(() => {
    if (auth?.uniqueId != null) {
      load(auth?.uniqueId);
    }
  }, [auth]);

  const load = (adminId: string) => {
    let fetchRoleCollection: IFetchRoleCollection = {
      adminId: adminId,
    };
    dispatch(globalRoleCollectionAsync(fetchRoleCollection));
  };

  useEffect(() => {
    let rows = roleCollections.map((roleCollection) => {
      return {
        label: roleCollection.roleCollectionName,
        value: roleCollection.roleCollectionId,
      };
    });

    setRadioButtons(rows);
    if (collectionId > 0) {
      setSelectedCollection(collectionId);
    }
  }, [roleCollections]);

  const setSelectedCollection = (value: any) => {
    let roleCollection = roleCollections.filter(
      (p) => p.roleCollectionId == value
    );
    setCollection(roleCollection[0]);
    setCollectionName(roleCollection[0].roleCollectionName);
    setCollectionId(roleCollection[0].roleCollectionId);
    setCollectionRoles(roleCollection[0].roles);
    setCollectionAdmins(roleCollection[0].userProfiles);
  };

  return (
    <>
      <Spacer feSpacing="md" />
      <Breadcrumbs
        feItems={[
          {
            label: "Global role administration",
          },
        ]}
        feSize="sm"
        feType="static"
        className="breadcrumbs"
      />
      <Spacer feSpacing="md" />
      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div className="contentArea">
            <div className="rolecollections">
              <Select
                feItems={radioButtons}
                feLabel=""
                fePlaceholder="Select Global Roles administration group"
                onChange={setSelectedCollection}
                multiple={false}
              />
            </div>
            <Tabs
              feItems={[
                {
                  children: <GlobalRoleAdminRoles roles={collectionRoles} />,
                  label: "Global roles",
                },
                {
                  children: (
                    <GlobalRoleAdminAdmins
                      users={collectionAdmins}
                      collection={collection}
                      reloadCallback={() => {
                        load(auth?.uniqueId);
                      }}
                    />
                  ),

                  label: "Global role admin",
                },
              ]}
              feType="expanded"
              onClick={function Ga() {}}
            />
          </div>
        </GridItem>
      </Grid>
    </>
  );
}
