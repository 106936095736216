import { useEffect, useState } from "react";
import { IGlobalRoleData, ISearchUser } from "../global-roles/globalRolesSlice";
import { Table, TableRow } from "@skf-internal/ui-components-react";
import { IUserData } from "../global-roles/globalRoleUsersSlice";
import RemoveUser from "./RemoveAdmin";
import AddAdmin from "./AddAdmin";
import RemoveAdmin from "./RemoveAdmin";
import { IGlobalRoleCollection } from "./globalRolesAdminSlice";

export interface IGlobalRoleAdminAdminsProps {
  users: ISearchUser[];
  collection: IGlobalRoleCollection | undefined;
  reloadCallback: any;
}
export default function GlobalRoleAdminAdmins(
  props: IGlobalRoleAdminAdminsProps
) {
  const { users, collection, reloadCallback } = props;
  const [userRows, setUserRows] = useState<TableRow[]>([]);

  const sortableHead: TableRow[] = [
    {
      cells: [
        { children: "First name", scope: "col", sortable: {}, width: "450px" },
        { children: "Last name", scope: "col", sortable: {} },
        { children: "E-mail", scope: "col", sortable: {} },
        { children: "More", scope: "col", sortable: {}, width: "40px" },
      ],
    },
  ];
  const createUsersRows = () => {
    if (users?.length > 0) {
      let slice: ISearchUser[] = users;

      setUserRows(
        slice.map((item: ISearchUser, index) => ({
          cells: [
            {
              children: <span title={item.givenName}>{item.givenName}</span>,
              height: "48px",
            },
            {
              children: <span title={item.surName}>{item.surName}</span>,
            },
            {
              children: <span title={item.email}>{item.email}</span>,
            },
            {
              children: users.length > 1 && (
                <RemoveAdmin
                  collection={collection}
                  user={item}
                  callback={reloadCallback}
                />
              ),
            },
          ],
        }))
      );
    } else {
      setUserRows([]);
    }
  };

  useEffect(() => {
    createUsersRows();
  }, [users]);

  return (
    <>
      <div className="roleTitle">&nbsp;</div>
      <AddAdmin collection={collection} reloadCallback={reloadCallback} />
      <Table feBody={userRows} feHead={sortableHead} feCompact />
    </>
  );
}
