import {
  Flexbox,
  Icon,
  Link,
  Spacer,
  Divider,
} from "@skf-internal/ui-components-react";
import { useEffect, useState, useRef, ForwardRefExoticComponent } from "react";
import { SignOutButton } from "../sso/SignOutButton";
import { useNavigate } from "react-router-dom";
import ViewUserLink from "../global-roles/ViewUserLink";
import { isSuperAdmin } from "../sso/ssoSlice";
import { useAppSelector } from "../../app/hooks";

export interface IUserProps {
  auth: any;
}

export default function User(props: IUserProps) {
  let auth = props.auth;
  const [showUser, setShowUser] = useState(false);
  const profileRef = useRef<any>(null);

  const handleUserClick = () => {
    setShowUser(!showUser);
  };

  const showSuperAdmin = useAppSelector(isSuperAdmin);
  const myProfileLink: string = auth?.sub;
  return (
    <>
      <span id="userinfo">
        {showSuperAdmin && (
          <span
            style={{ cursor: "pointer" }}
            title="Super Admin"
            className="superadmin"
          >
            <img
              src="/images/Superadmin.svg"
              height="16"
              width="16"
              title="Super admin"
            />
          </span>
        )}
        <span className="loggedin-user" title={auth?.email}>
          <Link
            as="button"
            feIcon={{
              feIcon: "chevronDown",
              position: "right",
            }}
            feColor="secondary"
            type="button"
            onClick={handleUserClick}
          >
            {auth?.name}
          </Link>
        </span>

        {showUser && (
          <span id="userDropDown">
            <Link
              as="a"
              href="#"
              onClick={() => {
                profileRef.current?.click();
              }}
              className="usermenuitem"
            >
              Profile
            </Link>{" "}
            <div style={{ display: "none" }}>
              {" "}
              <ViewUserLink
                ref={profileRef}
                user={{ id: myProfileLink }}
              />{" "}
            </div>
            <Divider feSpacing="xs" />
            <SignOutButton asLink={true} />
          </span>
        )}
      </span>
    </>
  );
}
