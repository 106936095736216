import { Alert } from "@skf-internal/ui-components-react";


type CustomAlertProp = {
    childrenPropString : "info" | "error" | "info" | "warning",
    visible : boolean ,
    message : string
}
const CustomAlert = ({ childrenPropString = "info" , visible , message="" } : CustomAlertProp) => {
 
  return (
  visible ? <div style={{margin : "0.5rem 0rem"}}>
        <Alert 
  feIcon="warning"
  feSeverity={childrenPropString}
>
  {message}
</Alert>
    </div> : null
  );
};

export default CustomAlert;
