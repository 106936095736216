import {
  Button,
  Dialog,
  Text,
  Spacer,
  TextAreaField,
  TextField,
  Flexbox,
  Tag,
} from "@skf-internal/ui-components-react";
import { useState, useEffect, useMemo } from "react";
import { ISearchUser } from "./userSlice";
import { detachRoleFromUser } from "./userAPI";

export interface IRemoveRoleFromUserProps {
  auth: any;
  role: any;
  user: ISearchUser;
  callback: any;
}

export default function RemoveRoleFromUser(props: IRemoveRoleFromUserProps) {
  const { auth, user, role } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [ticket, setTicket] = useState("");
  const [declineHint, setDecineHint] = useState("");
  const [declineError, setDeclineError] = useState<"error" | undefined>(
    undefined
  );
  const errorHint = "SuperAdmins must referece to ServiceNow ticket";

  const removeRoleFromUser = () => {
    let reasonText = reason;
    if (auth?.idTokenClaims?.roles?.includes("TESSERACT_SuperAdmin")) {
      reasonText += " Refering SNOW Ticket: " + ticket;
    }

    detachRoleFromUser(user.id, role.roleId, {
      adminId: auth?.idTokenClaims?.sub,
      reason: reasonText,
    })
      .then((res) => {
        setTimeout(() => {
          setIsOpen(false);
        }, 1000);
        props.callback();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <div>
      <Tag
        feType="outlined"
        feIcon="edit"
        feClosable={{
          title: "Remove role from user",
          onClick: () => {
            setIsOpen(true);
          },
        }}
      >
        {role.roleName}
      </Tag>

      <Dialog
        feInterruptive
        feTitle="Remove role from user"
        open={isOpen}
        cssWidth={"35rem"}
        cssHeight={"23.3rem"}
      >
        <Text>
          You are currently removing the role <b>{role.roleName}</b> from user{" "}
          <b>{user.userName}</b> which will restrict access to certain features.
        </Text>
        <Spacer feSpacing="md" />
        <TextAreaField
          style={{
            height: "60px",
            width: "470px",
            minWidth: "470px",
            maxWidth: "470px",
          }}
          feLabel="Reason (for internal use)"
          onChange={(_, value) => {
            setReason(value);
          }}
        />
        <Spacer feSpacing="md"></Spacer>

        <Spacer feSpacing="xs"></Spacer>
        <Flexbox feGap="md" feJustifyContent="flex-end" feAlignItems="flex-end">
          <Button
            feType="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => removeRoleFromUser()}>Confirm</Button>
        </Flexbox>
      </Dialog>
    </div>
  );
}
