import { Configuration, PopupRequest } from "@azure/msal-browser";
import { policies } from "./policies";

const authDomain: any = policies.authorityDomain;
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "",
    authority: policies.authorities.signUpSignIn.authority, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    knownAuthorities: [authDomain],
    redirectUri: process.env.REACT_APP_REDIRECT_URL, //"https://localhost:3000/blank.html"
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["openid", process.env.REACT_APP_SSO_SCOPE||"" ] //[ "openid", "https://skfgroupb2cdev.onmicrosoft.com/upr/api.read"], //
};           


export const silentRequest = {
  scopes: [
    "openid",
    "offline_access",
    // "https://skfgroupb2cdev.onmicrosoft.com/upr/api.read", // ---> Uncomment this for dev
    // "https://skfgroupb2ctest.onmicrosoft.com/upr/api.read", // ---> Uncomment this for test
    // "https://skfgroupb2ctest.onmicrosoft.com/upr/.default",
    process.env.REACT_APP_SSO_SCOPE+'',
  ],
  loginHint: process.env.REACT_APP_AUTHORITY_DOMAIN, //uncomment ths for test
};

