import {
  Button,
  Dialog,
  Flexbox,
  Loader,
  Text,
  Spacer,
  Tag,
  TextField,
  useToast,
} from "@skf-internal/ui-components-react";
import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  ISearchUser,
  searchUserByEmailAsync,
  selectSearchedUser,
  selectStatus,
} from "../group-users/groupUserSlice";
import { IApplication } from "../applications/Applications";
import { addAdminToApplication } from "./applicationDetailsAPI";
import useUserSearch from "./useUserSearch";

export interface IAddAdminProps {
  auth: any;
  application: IApplication;
}

export default function AddAdmin(props: IAddAdminProps) {
  const { auth, application } = props;
  //const status = useAppSelector(selectStatus);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isDebounced, setIsDebounced] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);
  const [showField, setShowField] = useState(true);

  const DEBOUNCE_DELAY = 750;
  //const searchUser: ISearchUser[] = useAppSelector(selectSearchedUser);
  const [displaySearchedUser, setDisplaySearchedUser] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  const [selectedUsers, setSelectedUsers] = useState<ISearchUser[]>([]);
  const [searchhint, setSearchhint] = useState("");
  const [foundUser, setFoundUser] = useState<ISearchUser>(Object);
  const { toasts, addToast, removeToast } = useToast();
  const [query, setQuery] = useState("");
  const { searchUser, status } = useUserSearch(query);

  const selectUser = (user: ISearchUser) => {
    setSelectedUsers((selectedUsers) => [...selectedUsers, user]);
    setDisable(false);

    setTimeout(() => {
      setDisplaySearchedUser("");
      setEmail("");
      setInfoMessage("");
      setShowField(false);
    }, 250);
  };
  const removeUserFromSelection = (user: ISearchUser) => {
    const temp = [...selectedUsers];
    const index = temp.indexOf(user, 0);

    if (index > -1) {
      temp.splice(index, 1);
      setSelectedUsers(temp);
    }
    if (temp.length == 0) {
      setDisable(true);
      setShowField(true);
    }
  };

  const create = () => {
    if (selectedUsers.length > 0) {
      let result: string = "";
      selectedUsers.map((user: ISearchUser) => {
        addAdminToApplication(application.applicationId, user.id)
          .then((res) => {
            setInfoMessage("User(s) successfully created!");
            addToast({
              children: "User added as admin",
              feSeverity: "success",
            });
          })
          .catch((error) => {
            if (error.response) {
              setInfoMessage(error.response.data);
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              addToast({
                children: "Something went wrong " + error.response.data,
                feSeverity: "error",
              });
            }
          });
      });
      setTimeout(() => {
        setIsOpen(false);
        setInfoMessage("");
        navigate(0);
      }, 1000);
    } else {
      setInfoMessage("select user first");
      console.log("select user first");
    }
  };

  /* const debouncedChangeHandler = useMemo(
    () =>
      debounce((query: string) => {
        dispatch(searchUserByEmailAsync({ email: query }));
      }, DEBOUNCE_DELAY),
    []
  );

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);
*/
  useEffect(() => {
    setDisplaySearchedUser("");
    setEmail("");
    setSelectedUsers([]);
    setInfoMessage("");
    setDisable(true);
    setShowField(true);
  }, [isOpen]);

  useEffect(() => {
    if (searchUser[0]) {
      setSearchhint("");
      const user = `${searchUser[0].givenName} ${searchUser[0].surName}, ${searchUser[0].companyName}`;
      /*setDisplaySearchedUser(user);
          setFoundUser(searchUser[0]);
          setInfoMessage("");*/
      selectUser(searchUser[0]);
    } else {
      setSearchhint("No user found with that email");
    }
  }, [searchUser]);
  const handleUserInputChange = (e: any) => {
    e.preventDefault();
    setSearchhint("");
    setEmail(e.target.value);
    setQuery(e.target.value);

    //handleSearch(value);
    //setIsDebounced(true);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    // Uppdatera söktermen när användaren skickar formuläret
    // Hooken kommer att automatiskt hämta användare baserat på den nya söktermen
    // Om du vill kan du också lägga till validering och hantering av fel här
    setQuery(e.target.value);
  };

  const isButtonDisabled = () => {
    let res: boolean | undefined;
    res = false;

    if (disable) {
      res = true;
    }

    return res;
  };

  /* const data: SelectItem[] = roles.map((r) => {
        return {
          label: `${r.cells[0].children}`,
          value: `${r.cells[0].children}`,
        };
      });
    */
  return (
    <div>
      <Button onClick={() => setIsOpen(true)}>Add user</Button>
      <Dialog {...props} feInterruptive feTitle="Add user" open={isOpen}>
        <Text>
          Add user as Admin for <b>{application.applicationName}</b>
        </Text>
        <Spacer />
        <Text>Search user by email.</Text>
        <Spacer />
        {infoMessage !== "" && (
          <>
            <h6>{infoMessage}</h6>
            <Spacer />
          </>
        )}
        <Text>Email</Text>
        {showField && (
          <TextField
            feLabel=""
            onChange={handleUserInputChange}
            feHint={searchhint}
            value={email}
          />
        )}

        <>
          {status === "loading" ? (
            <Loader />
          ) : (
            <Text>
              {/* {JSON.stringify(serachUser)} */}
              {displaySearchedUser !== "" && (
                <Text onClick={() => selectUser(foundUser)}>
                  {displaySearchedUser}
                </Text>
              )}
            </Text>
          )}
        </>

        {!showField && (
          <>
            <Spacer feSpacing="xs" />
            <div id="tagfield">
              {" "}
              {selectedUsers.map((user: ISearchUser) => {
                return (
                  <Tag
                    feClosable={{
                      onClick: () => removeUserFromSelection(user),
                    }}
                    feType="outlined"
                    key={user.id}
                  >
                    {user.email}
                  </Tag>
                );
              })}
            </div>
          </>
        )}
        <Spacer />
        <Flexbox feGap="md" feJustifyContent="flex-end">
          <Button feType="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => create()}>Add</Button>
        </Flexbox>
      </Dialog>
    </div>
  );
}
