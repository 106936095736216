import {
  Breadcrumbs,
  Button,
  Link,
  Flexbox,
  Grid,
  GridItem,
  Icon,
  Spacer,
  Table,
  TableRow,
  Loader,
} from "@skf-internal/ui-components-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAuth } from "../sso/ssoSlice";
import {
  groupUsersAsync,
  selectGroup,
  IUser,
  selectStatus,
} from "./groupUserSlice";
import { selectGlobalGroups } from "../global-groups/globalGroupSlice";
import AddUsers from "./AddUsers";
import RemoveUser from "./RemoveUser";
import { displayDate } from "../common/Common";
export interface RouteParams {
  groupId: string | "";
}
export interface IGroupUserProps {}

export default function GroupUser(props: IGroupUserProps) {
  const { groupId } = useParams();
  const groupName = groupId?.replaceAll("$", "/");
  const navigate = useNavigate();
  const group = useAppSelector(selectGroup);
  const dispatch = useAppDispatch();
  const auth = useAppSelector(selectAuth);
  const initial: TableRow[] = [];
  const initialStr: string = "Loading";
  const [groupRows, setGroupRows] = useState(initial);
  const [userRows, setUserRows] = useState(initial);
  const [canEditUsers, setCanEditUsers] = useState(false);
  let canRemoveUsers = false;
  const [isSuperAdmin, setSuperAdmin] = useState(false);
  const searchStatus = useAppSelector(selectStatus);

  useEffect(() => {
    if (group) {
      dispatch(
        groupUsersAsync({
          groupId: groupName ?? "",
          adminId: auth?.idTokenClaims?.sub,
        })
      );
    }
    //groupDropdown();
  }, []);

  useEffect(() => {
    setCanEditUsers(group.allowedToEdit);

    let users = group.users;
    if (users.length > 0) {
      let x = users.find((u) => u.userProfileId == auth?.idTokenClaims?.sub);
      canRemoveUsers = group.allowedToEdit;
      if (x != undefined) {
        canRemoveUsers = true;
        setCanEditUsers(true);
      }

      if (canRemoveUsers && users.length < 2) {
        canRemoveUsers = false;
      }

      setUserRows(
        users.map((item: IUser) => ({
          cells: [
            {
              children: item.firstName,
              height: "48px",
            },
            {
              children: item.lastName,
            },
            {
              children: item.email,
            },
            {
              children: displayDate(item.lastLogon),
            },

            {
              children: (
                <RemoveUser
                  auth={auth}
                  group={group}
                  user={item}
                  canRemoveUsers={canRemoveUsers}
                />
              ),
            },
          ],
        }))
      );
    }
    //console.log("rows: " + userRows);
  }, [group]);

  const sortableHead: TableRow[] = [
    {
      cells: [
        { children: "First name", scope: "col", sortable: {} },
        { children: "Last name", scope: "col", sortable: {} },
        { children: "E-mail", scope: "col", sortable: {} },
        { children: "Last login", scope: "col", sortable: {} },
        { children: "More", scope: "col", width: "50px" },
      ],
    },
  ];

  const [showChild, setshowChild] = useState(false);

  const handleChange = () => {
    setshowChild(!showChild);
  };

  return (
    <>
      <Spacer feSpacing="md" />

      <Breadcrumbs
        feItems={[
          {
            label: "Groups",
            href: "/globalgroups",
            onClick: (e: any, route: string) => navigate(route),
          },
          {
            label: `${group.groupName!}`,
            href: "/group-users/" + group.groupName!,
            onClick: (e: any) => {
              e.stopPropagation();
            },
          },
        ]}
        feSize="sm"
        feType="interactive"
        className="breadcrumbs"
      />
      <Spacer />
      <Grid className="gridHead">
        <GridItem
          feColspan={{
            desktop: 12,
            mobile: 12,
            tablet: 12,
          }}
        >
          <div className="contentArea">
            <Grid>
              <GridItem
                feColspan={{
                  desktop: 6,
                  mobile: 6,
                  tablet: 6,
                }}
              >
                <Flexbox
                  feFlexDirection="row"
                  feFlexWrap="nowrap"
                  feGap="xxs"
                  feJustifyContent="left"
                ></Flexbox>
              </GridItem>
              <GridItem
                feColspan={{
                  desktop: 6,
                  mobile: 6,
                  tablet: 6,
                }}
              >
                <Flexbox
                  feFlexDirection="row"
                  feFlexWrap="nowrap"
                  feGap="xxs"
                  feJustifyContent="right"
                >
                  <AddUsers
                    auth={auth}
                    groupId={group.groupName}
                    canAddUser={canEditUsers}
                  />
                </Flexbox>
              </GridItem>
            </Grid>
            <Spacer />
            <GridItem
              feColspan={{
                desktop: 12,
                mobile: 12,
                tablet: 12,
              }}
            >
              <div
                style={{
                  backgroundColor: "rgb(255, 255, 255)",
                  padding: "0",
                  textAlign: "left",
                }}
              >
                {searchStatus === "loading" && <Loader />}
                {searchStatus === "failed" && (
                  <p>Search failed. Please logout and logon again</p>
                )}
                {searchStatus === "idle" &&
                  (group?.users?.length > 0 ? (
                    <Table feBody={userRows} feHead={sortableHead} feCompact />
                  ) : (
                    <p>Group has no users.</p>
                  ))}
              </div>
            </GridItem>
          </div>
        </GridItem>
      </Grid>
    </>
  );
}
