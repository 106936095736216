import { useState, useEffect, useMemo } from "react";
import {
  ISearchUser,
  searchUserByEmailAsync,
  selectSearchedUser,
  selectStatus,
} from "../group-users/groupUserSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { debounce } from "lodash";

// Custom hook för att söka användare
export const useUserSearch = (query = "") => {
  const DEBOUNCE_DELAY = 750;
  const searchUser: ISearchUser[] = useAppSelector(selectSearchedUser);
  const status = useAppSelector(selectStatus);
  const dispatch = useAppDispatch();
  const fetchUsers = debounce(async () => {
    //console.log("fetchUsers calling with query= " + query);
    try {
      dispatch(searchUserByEmailAsync({ email: query }));
    } catch (err) {
      console.error(err);
    }
  }, DEBOUNCE_DELAY);

  useEffect(() => {
    if (query?.length > 1) {
      fetchUsers();
      //console.log("fetchUsers add " + query);
    }
  }, [query]);

  // Stop the invocation of the debounced function after unmounting
  useEffect(() => {
    return () => {
      fetchUsers.cancel();
    };
  }, [query]);

  return { searchUser, status };
};

export default useUserSearch;
