import {
  Button,
  Dialog,
  Flexbox,
  Loader,
  Spacer,
  Text,
} from "@skf-internal/ui-components-react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ISearchUser,
  searchUserByIDAsync,
  selectunmarkedForDelitionByUserStatus,
  selectunmarkedForDelitionLoadingStatus,
  reactivateUserAsync,
} from "./userSlice";
import { useEffect, useState } from "react";
export interface IUserReactivationProps {
  user: ISearchUser;
  callback: any;
}

export default function UserReactivation(props: IUserReactivationProps) {
  const { user, callback } = props;
  const MFD_STATUS = useAppSelector(selectunmarkedForDelitionByUserStatus);
  const MFD_LOADING = useAppSelector(selectunmarkedForDelitionLoadingStatus);
  const dispatch = useAppDispatch();
  const [callingAPI, setCallingAPI] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleUnmarkedForDeliton = () => {
    dispatch(reactivateUserAsync({ id: user.id }));
    setCallingAPI(true);

    // setUserMarkedForDeleted((prevstate)=> !prevstate );
  };

  useEffect(() => {
    if (MFD_STATUS != "loading" && callingAPI) {
      setCallingAPI(false);
      callback();
    }
  }, [MFD_STATUS]);

  return (
    <>
      {!callingAPI && (
        <Button
          disabled={MFD_LOADING}
          onClick={() => setIsOpen(true)}
          feSize="sm"
        >
          Reactivate the user
        </Button>
      )}
      {callingAPI && <Loader feSize="sm" />}
      <Dialog
        feInterruptive
        feTitle="Confirmation"
        open={isOpen}
        style={{ zIndex: 10000 }}
      >
        <>
          <Text>You are about to reactivate the user. Is this correct?</Text>
          <Spacer />
          <Text>
            Click on&nbsp;
            <strong>Confirm</strong> to proceed.
          </Text>
          <Spacer></Spacer>
          <Flexbox feGap="md" feJustifyContent="flex-end">
            <Button
              feType="secondary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleUnmarkedForDeliton()}
              disabled={callingAPI}
            >
              Confirm
            </Button>
          </Flexbox>
        </>
      </Dialog>
    </>
  );
}
